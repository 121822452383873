import { getIdToken } from "../../storage.services";

const { REACT_APP_BACKEND_URI, REACT_APP_SIGNUP_API_URI } = process.env;

const createApi = url => async opts =>
  fetch(`${REACT_APP_BACKEND_URI}${url}`, {
    ...opts
  })
    .then(res => [null, res])
    .catch(err => [err, null]);

const createAuthApi = url => async opts =>
  createApi(url)({
    ...opts,
    headers: {
      Authorization: `Bearer ${getIdToken()}`
    }
  });

const createSignupApi = url => opts =>
  fetch(`${REACT_APP_SIGNUP_API_URI}${url}`, {
    ...opts
  });

const createAuthSignupApi = url => async opts => {
  return createSignupApi(url)({
    ...opts,
    headers: {
      Authorization: `Bearer ${getIdToken()}`
    }
  });
};

export { createApi, createAuthApi, createSignupApi, createAuthSignupApi };
