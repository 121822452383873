export default {
  CRT_PRSNS_400:
    "Something went wrong while we were processing your request. Please check that your input is valid and try again.",
  RTRV_PRSN_404: "No existing information about this person.",
  RTRV_ORG_404: "No existing information about this organization.",
  CORRUPTED_PAYLOAD:
    "Something went wrong while we were processing your request. Please check that your input is valid and try again.",
  UNKNOWN: "Unknown error occurred while processing operation.",
  UNAUTHORIZED: "You are not authorized to perform this operation.",
  FORBIDDEN: "You are forbidden to perform this operation.",
  SERVER_ERROR:
    "The server is currently experiencing problems as of the moment. Try again or contact adminstrator.",
  INVITE_USER_409: "An account already exists with that email."
};
