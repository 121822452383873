/* eslint-disable react/jsx-curly-brace-presence */
import React from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, ScreenClassRender, Hidden } from "react-grid-system";
import { connect } from "react-redux";
import TagManager from "react-gtm-module";
import { getLogLevelIconProps, getLogLevelBg } from "utils/logs";
import { formatLogLevels } from "utils/general/api";
import { filterLogs, fetchLogs } from "redux/modules/logs/actions";

import { FiltersContainer, SearchContainer } from "./styledElements";

const LEVELS = {
  default: "default",
  warning: "warning",
  error: "error"
};

class Filters extends React.PureComponent {
  state = {
    searchQuery: "",
    iconFilters: {
      default: false,
      warning: true,
      error: true
    }
  };

  inputRef = React.createRef();

  componentDidMount = () => {
    this.inputRef.current.addEventListener("keypress", this.watchInputKeyPress);
  };

  componentWillUnmount = () => {
    this.inputRef.current.removeEventListener(
      "keypress",
      this.watchInputKeyPress
    );
  };

  watchInputKeyPress = (event = {}) => {
    const { keyCode, code } = event;

    if (keyCode === 13 || code === "Enter") {
      this.applyFilter();
    }
  };

  setIconFilter = logLevel => () => {
    const { iconFilters } = this.state;

    const filter = iconFilters[logLevel];
    const newIconFilters = {
      ...iconFilters,
      [logLevel]: !filter
    };

    this.setState({
      iconFilters: newIconFilters
    });
  };

  applyFilter = () => {
    this.setState(
      {
        searchQuery: this.inputRef.current.value
      },
      () => {
        const { iconFilters, searchQuery } = this.state;
        const { onFilterLogs, onFetchLogs } = this.props;

        TagManager.dataLayer({
          dataLayer: {
            event: `searchQuery_${searchQuery}`,
            searchQuery,
            logLevels: formatLogLevels(iconFilters)
          },
          dataLayerName: "reactAppDataLayer"
        });

        onFilterLogs({
          logLevels: iconFilters,
          searchQuery
        });
        onFetchLogs();
      }
    );
  };

  render() {
    const { iconFilters } = this.state;

    const defaultProps = getLogLevelIconProps(LEVELS.default);
    const warnProps = getLogLevelIconProps(LEVELS.warning);
    const errProps = getLogLevelIconProps(LEVELS.error);

    return (
      <ScreenClassRender
        render={screen => (
          <FiltersContainer screen={screen}>
            <Row nogutter>
              <FontAwesomeIcon
                onClick={this.setIconFilter(LEVELS.default)}
                icon={defaultProps.icon}
                color={
                  iconFilters[LEVELS.default] ? defaultProps.color : "#c1c1c1"
                }
                style={{
                  backgroundColor: iconFilters[LEVELS.default]
                    ? getLogLevelBg(LEVELS.default)
                    : "#b7b7b738"
                }}
              />
              <FontAwesomeIcon
                onClick={this.setIconFilter(LEVELS.warning)}
                icon={warnProps.icon}
                color={
                  iconFilters[LEVELS.warning] ? warnProps.color : "#c1c1c1"
                }
                style={{
                  backgroundColor: iconFilters[LEVELS.warning]
                    ? getLogLevelBg(LEVELS.warning)
                    : "#b7b7b738"
                }}
              />
              <FontAwesomeIcon
                onClick={this.setIconFilter(LEVELS.error)}
                icon={errProps.icon}
                color={iconFilters[LEVELS.error] ? errProps.color : "#c1c1c1"}
                style={{
                  backgroundColor: iconFilters[LEVELS.error]
                    ? getLogLevelBg(LEVELS.error)
                    : "#b7b7b738"
                }}
              />
              <button type="button" onClick={this.applyFilter}>
                <Hidden xs sm>
                  {`Apply `}
                </Hidden>
                <>Filter</>
              </button>
            </Row>
            <SearchContainer screen={screen}>
              <input ref={this.inputRef} />
              <FontAwesomeIcon icon="search" onClick={this.applyFilter} />
            </SearchContainer>
          </FiltersContainer>
        )}
      />
    );
  }
}

Filters.propTypes = {
  onFilterLogs: PropTypes.func.isRequired,
  onFetchLogs: PropTypes.func.isRequired
};

export default connect(
  null,
  {
    onFilterLogs: filterLogs,
    onFetchLogs: fetchLogs
  }
)(Filters);
