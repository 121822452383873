export default {
  mainFg: "#313131",
  mainBg: "#F2F2F2",
  primary: "#FF903F",
  primary_light: "#FFB986",
  primary_dark: "#945425",
  white: "#FFFFFF",
  red: "#DD0130",
  brandYellow: "#FFF047",
  brandOrange: "#FF903F",
  brandRed: "#FFB347",
  transparent: "rgba(0,0,0,0)"
};
