/* eslint-disable react/jsx-one-expression-per-line */
import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { Row, Col } from "react-grid-system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ROLES from "constants/roles";
import { getOrgUsers } from "redux/modules/account/actions";
import { Loader } from "components";
import InviteFormModal from "../InviteFormModal";
import { CardContainer } from "../../styledElements";
import {
  SettingContainer,
  SettingTitle,
  SettingDescription
  // Button
} from "../Settings/styledElements";
import CardTitle from "../../../CardTitle";

import {
  CardHeader,
  CardHeaderTitle,
  CardActionContainer,
  UsersList,
  RowContainer,
  HeaderRow,
  // ActionContainer,
  Button as InviteButton
} from "./styledElements";

export class Team extends React.Component {
  state = {
    isInviteModalOpen: false
  };

  componentDidMount() {
    const { onGetOrgUsers } = this.props;
    onGetOrgUsers();
  }

  openInviteModal = () => {
    this.setState({
      isInviteModalOpen: true
    });
  };

  closeInviteModal = () => {
    this.setState({
      isInviteModalOpen: false
    });
  };

  render() {
    const { isInviteModalOpen } = this.state;
    const { users, isLoading } = this.props;

    return (
      <CardContainer id="team">
        <SettingContainer>
          <CardHeader nowrap>
            <CardHeaderTitle sm={9} xs={6}>
              <CardTitle>Team</CardTitle>
            </CardHeaderTitle>
            <CardActionContainer sm={3} xs={6}>
              <InviteButton onClick={this.openInviteModal}>
                <Row nowrap justify="center">
                  <Col lg={4} md={4} sm={4} xs={4}>
                    Invite
                  </Col>
                  <Col lg={4} md={4} sm={4} xs={4}>
                    <FontAwesomeIcon icon="plus" />
                  </Col>
                </Row>
              </InviteButton>
            </CardActionContainer>
          </CardHeader>
          <SettingTitle id="manageusers">Manage Users</SettingTitle>
          <SettingDescription>
            Manage and view the users in your team
          </SettingDescription>
          <HeaderRow nowrap>
            <Col lg={3} md={3} sm={3} xs={3}>
              NAME
            </Col>
            <Col lg={5} md={5} sm={4} xs={4}>
              EMAIL
            </Col>
            <Col lg={3} md={3} sm={3} xs={2}>
              ROLE
            </Col>
            {/* <Col lg={1} md={1} sm={2} xs={3} /> */}
          </HeaderRow>
          <UsersList>
            {users.map(user => (
              <RowContainer nowrap>
                <Col lg={3} md={3} sm={3} xs={3}>
                  {user.name}
                </Col>
                <Col lg={5} md={5} sm={4} xs={4}>
                  {user.email}
                </Col>
                <Col lg={3} md={3} sm={3} xs={2}>
                  {ROLES[user.role]}
                </Col>
                {/* <Col lg={1} md={1} sm={2} xs={3}>
                  <ActionContainer>
                    <Button className="danger">
                      <FontAwesomeIcon icon="trash" />
                    </Button>
                  </ActionContainer>
                </Col> */}
              </RowContainer>
            ))}
            {users.length === 0 && (
              <RowContainer>
                <Col md={12}>No users found.</Col>
              </RowContainer>
            )}
            <Loader
              loading={isLoading}
              containerStyle={{
                borderRadius: 8
              }}
            />
          </UsersList>
        </SettingContainer>
        <InviteFormModal
          isOpen={isInviteModalOpen}
          onRequestClose={this.closeInviteModal}
        />
      </CardContainer>
    );
  }
}

Team.propTypes = {
  users: PropTypes.instanceOf(Array),
  onGetOrgUsers: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

Team.defaultProps = {
  users: [],
  isLoading: false
};

export default connect(
  state => ({
    users: state.account.orgUsers,
    isLoading: state.account.isLoadingOrgUsers
  }),
  {
    onGetOrgUsers: getOrgUsers
  }
)(Team);
