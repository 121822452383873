import React, { useEffect, useCallback } from "react";
import PropTypes from "prop-types";

import { STAT_ITEMS, COUNT_ITEMS } from "constants/stats";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Row, Col, ScreenClassRender } from "react-grid-system";
import { Loader } from "components";
import { connect } from "react-redux";
import { fetchStats } from "redux/modules/stats/actions";
import {
  OverviewCardContainer,
  OverviewCard,
  StatsContainer,
  StatItem,
  InfoCard,
  InfoItem
  // OrganizationContainer
  // RecentTotal
} from "./styledElements";
// import { StatAreaChart } from "./components";

import CardTitle from "../CardTitle";

const StatsOverview = ({ onFetchStats, isFetching, data }) => {
  const { overview, count } = data;
  const onFetchStatsCb = useCallback(onFetchStats);

  useEffect(() => {
    onFetchStatsCb();
  }, [onFetchStatsCb]);

  return (
    <ScreenClassRender
      render={screen => (
        <StatsContainer>
          <Container>
            <Row>
              <OverviewCardContainer screen={screen}>
                <OverviewCard>
                  <CardTitle>Stats Overview</CardTitle>
                  <Row>
                    {STAT_ITEMS.map(item => (
                      <Col key={item.id} xl={3} lg={3} md={6} sm={12} xs={12}>
                        <StatItem screen={screen} loading={isFetching ? 1 : 0}>
                          {/* <StatAreaChart color={item.color} id={item.id} /> */}
                          <div className={`value ${item.key}`}>
                            {isFetching ||
                            typeof overview[item.key] === "undefined"
                              ? `∞`
                              : overview[item.key]}
                          </div>
                          <div className="label">{item.label}</div>
                          <Loader loading={isFetching} />
                        </StatItem>
                      </Col>
                    ))}
                  </Row>
                </OverviewCard>
              </OverviewCardContainer>
              <Col xl={4}>
                <InfoCard>
                  <CardTitle>By Device</CardTitle>
                  {COUNT_ITEMS.map(item => (
                    <InfoItem key={item.key}>
                      <div className="label">{item.label}</div>
                      <div className="value">
                        {isFetching || typeof count[item.key] === "undefined"
                          ? `∞`
                          : count[item.key]}
                      </div>
                    </InfoItem>
                  ))}
                  {/* To-Do: Prepare an organization user count endpoint API */}
                  {/* <OrganizationContainer>
                    <InfoItem key={recent.key}>
                      <div className="label">User Count</div>
                      <div className="value">{organizationUserCount}</div>
                    </InfoItem>
                  </OrganizationContainer> */}
                  {/* 
                  <RecentTotal>
                    <div className="label">Total</div>
                    <div className="value">
                      {Object.values(recents).reduce((prev, next) => {
                        prev += next;
                        return prev;
                      }, 0)}
                    </div>
                  </RecentTotal>
                  */}
                  <FontAwesomeIcon className="fire-icon" icon="fire" />
                  <Loader
                    loading={isFetching}
                    containerStyle={{
                      borderRadius: 28
                    }}
                  />
                </InfoCard>
              </Col>
            </Row>
          </Container>
        </StatsContainer>
      )}
    />
  );
};

StatsOverview.propTypes = {
  onFetchStats: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  isFetching: PropTypes.bool
};

StatsOverview.defaultProps = {
  isFetching: false
};

export default connect(
  state => ({
    isFetching: state.stats.isFetching,
    data: state.stats.data
  }),
  {
    onFetchStats: fetchStats
  }
)(StatsOverview);
