import React from "react";
import PropTypes from "prop-types";
import COLORS from "constants/colors";

import { toast } from "react-toastify";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { requestResetPassword } from "services/data.services";

import * as validator from "utils/validate";

import { Button, FormComponent, NavLink } from "components";
import {
  FormContainer,
  Subline,
  ActionsContainer,
  // ForgotPassword,
  Title,
  Description
} from "./styledElements";

const validate = values => {
  const errors = {};
  const { email } = values;

  errors.email = validator.required(email) || validator.email(email);

  return errors;
};

class ForgotPasswordForm extends React.PureComponent {
  state = {
    isRequestingReset: false
  };

  toggleRequestingReset = () => {
    const { isRequestingReset } = this.state;

    this.setState({
      isRequestingReset: !isRequestingReset
    });
  };

  submitForm = async values => {
    const { email } = values;

    this.toggleRequestingReset();

    await requestResetPassword({ email });

    this.toggleRequestingReset();
    toast.success(
      `If an account with a matching email was found in our database, an email was sent to ${email}.`
    );
  };

  render() {
    const { isRequestingReset } = this.state;
    const { valid, handleSubmit } = this.props;

    return (
      <>
        <FormContainer>
          <Title>Forgot your password?</Title>
          <Description>
            <div className="faded">
              We&apos;ll send you an email with a link to reset your password.
            </div>
          </Description>
          <form
            onSubmit={handleSubmit(this.submitForm)}
            className="forgotPassword"
          >
            <Field
              name="email"
              type="email"
              label="Email"
              component={FormComponent}
              layout={{ sm: 12 }}
            />
            <ActionsContainer>
              <Button
                type="submit"
                disabled={!valid || isRequestingReset}
                loading={isRequestingReset}
                theme={{
                  background: COLORS.brandOrange,
                  color: COLORS.white
                }}
              >
                Reset Password
              </Button>
            </ActionsContainer>
            <Subline>
              <span>Don&apos;t have an account yet?</span>
              <NavLink to="/account/signup">Sign up now.</NavLink>
            </Subline>
          </form>
        </FormContainer>
      </>
    );
  }
}

ForgotPasswordForm.propTypes = {
  valid: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired
};

ForgotPasswordForm.defaultProps = {
  valid: false
};

const selector = formValueSelector("forgotPassword");

export default reduxForm({
  form: "forgotPassword",
  validate,
  onSubmit: () => {}
})(
  connect(state => ({
    email: selector(state, "email")
  }))(withRouter(ForgotPasswordForm))
);
