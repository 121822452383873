/* eslint react/jsx-props-no-spreading : off */
import React from "react";
import { isValidElementType } from "react-is";

import { Route, Redirect } from "react-router-dom";
import { isLoggedin } from "services/data.services";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (isLoggedin()) {
        return <Component {...props} />;
      }

      return (
        /* eslint-disable-next-line */
        <Redirect to={{ pathname: "/account/login", from: props.location }} />
      );
    }}
  />
);

PrivateRoute.propTypes = {
  /* eslint consistent-return : off */
  component: (props, propName) => {
    if (props[propName] && !isValidElementType(props[propName])) {
      return new Error(
        `Invalid prop 'component' supplied to 'Route': the prop is not a valid React component`
      );
    }
  }
};

PrivateRoute.defaultProps = {
  component: null
};

export default PrivateRoute;
