import React from "react";
import PropTypes from "prop-types";
import MarkdownRenderer from "react-markdown";
import SyntaxHighlighter from "react-syntax-highlighter";
import COLORS from "constants/colors";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Title } from "../../styledElements";
import { IconsContainer } from "../CreateProject/styledElements";
import { SectionTitle } from "../Integration/styledElements";
import {
  Link,
  InstructionsContainer,
  DashboardButton,
  LinkContainer
} from "./styledElements";

const sampleCode = "log('Error Message', new Error('Error Message')))";

const Testing = ({ goToDashboard }) => (
  <Container>
    <IconsContainer>
      <FontAwesomeIcon icon="charging-station" color={COLORS.red} size="xs" />
    </IconsContainer>
    <Title>Test it out!</Title>
    <MarkdownRenderer source="To be able to verify that your integration is working, call `log()` function in your application:" />
    <SyntaxHighlighter language="javascript">{sampleCode}</SyntaxHighlighter>
    <MarkdownRenderer source="There should be a log that appears on your dashboard with a default log type and this is your first log." />
    <SectionTitle>Next Steps</SectionTitle>
    <InstructionsContainer>
      <div>
        Read our documentation to learn more about Kalayo Logger and
        Kalayo&apos;s dashboard API.
      </div>
      <div>If you encounter any problems, feel free to contact us.</div>
    </InstructionsContainer>
    <LinkContainer>
      <DashboardButton onClick={goToDashboard}>Dashboard</DashboardButton>
      <Link href="/docs">
        <span>TAKE ME TO THE DOCS</span>
        <FontAwesomeIcon icon="chevron-right" />
      </Link>
    </LinkContainer>
  </Container>
);

Testing.propTypes = {
  goToDashboard: PropTypes.func.isRequired
};

export default Testing;
