import React from "react";
import PropTypes from "prop-types";
import COLORS from "constants/colors";

import { toast } from "react-toastify";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { login, isLoggedin, AuthGetUserInfo } from "services/data.services";
import {
  storeAuthTokens,
  clearAuthTokens,
  getOnboardingStatus
} from "services/storage.services";
import {
  setUser,
  getBillingInfo,
  getSubscription
} from "redux/modules/account/actions";

import * as validate from "utils/validate";

import { Button, FormComponent, NavLink } from "components";
import {
  LoginFormContainer,
  Subline,
  ActionsContainer,
  ForgotPassword,
  Title,
  Description
} from "./styledElements";

const validateLoginForm = values => {
  const errors = {};

  errors.email =
    validate.required(values.email) || validate.email(values.email);

  errors.password =
    validate.required(values.password) ||
    validate.minPasswordLength(values.password);

  return errors;
};

class LoginForm extends React.PureComponent {
  state = {
    isLoggingIn: false
  };

  componentDidMount = () => {
    const { history } = this.props;

    if (isLoggedin()) {
      if (getOnboardingStatus() !== "DONE") {
        // Redirect the user to the onboarding
        history.push("/onboarding");
      } else {
        // Redirect the user to the dashboard
        history.push("/dashboard");
      }
    }
  };

  componentDidUpdate = () => {
    const { history } = this.props;

    if (isLoggedin()) {
      if (getOnboardingStatus() !== "DONE") {
        // Redirect the user to the onboarding
        history.push("/onboarding");
      } else {
        // Redirect the user to the dashboard
        history.push("/dashboard");
      }
    }
  };

  toggleLoggingIn = () => {
    const { isLoggingIn } = this.state;

    this.setState({
      isLoggingIn: !isLoggingIn
    });
  };

  submitForm = async values => {
    const { email, password } = values;
    const { setUserDetails, onGetBillingInfo, onGetSubscription } = this.props;

    this.toggleLoggingIn();

    const [error, response] = await login({ email, password });

    if (error) {
      this.toggleLoggingIn();
      toast.error("Invalid email address or password.");

      return;
    }

    const { accessToken, idToken } = response;

    storeAuthTokens({ idToken });
    AuthGetUserInfo(accessToken, async (userError, user) => {
      if (!userError) {
        if (process.env.NODE_ENV !== "development") {
          const { email_verified: verified } = user;

          if (!verified) {
            toast.error(
              "Your email address is not yet verified. Please check your email."
            );
            // Logout the user if unverified
            clearAuthTokens();

            return;
          }
        }
        // Set the user's details in the redux store
        setUserDetails({
          user: {
            id: user.sub,
            organization:
              user["https://kalayo.io/claims"] &&
              user["https://kalayo.io/claims"].organization,
            profile: {
              name: user.name,
              picture: user.picture,
              email: user.email
            }
          }
        });

        // Load billing information
        onGetBillingInfo();

        // Load subscription info
        onGetSubscription();

        // Toggle logging in
        this.toggleLoggingIn();
      } else {
        // if there was a user error, logout
        clearAuthTokens();
      }
    });
  };

  validateForm = values => {
    const errors = validateLoginForm(values);

    if (!errors.email || !errors.password) this.submitForm(values);
  };

  render() {
    const { isLoggingIn } = this.state;
    const { valid, handleSubmit } = this.props;

    return (
      <>
        <LoginFormContainer>
          <Title>Login</Title>
          <Description>
            <div className="faded">
              Welcome back! Login to access your Kalayo dashboard.
            </div>
            <ForgotPassword>
              <span className="faded">Did you </span>
              <NavLink to="/account/forgot">forget your password?</NavLink>
            </ForgotPassword>
          </Description>
          <form onSubmit={handleSubmit(this.validateForm)} className="login">
            <Field
              name="email"
              type="email"
              label="Email"
              validate={[validate.required, validate.email]}
              component={FormComponent}
              layout={{ sm: 12 }}
            />
            <Field
              name="password"
              type="password"
              label="Password"
              validate={[validate.required]}
              component={FormComponent}
              layout={{ sm: 12 }}
            />
            <ActionsContainer>
              <Button
                type="submit"
                disabled={!valid || isLoggingIn}
                loading={isLoggingIn}
                theme={{
                  background: COLORS.brandOrange,
                  color: COLORS.white
                }}
                loadingText="Signing in..."
              >
                Sign In
              </Button>
            </ActionsContainer>
            <Subline>
              <span>Don&apos;t have an account yet?</span>
              <NavLink to="/account/signup">Sign up now.</NavLink>
            </Subline>
          </form>
        </LoginFormContainer>
      </>
    );
  }
}

LoginForm.propTypes = {
  valid: PropTypes.bool,
  history: PropTypes.instanceOf(Object),
  handleSubmit: PropTypes.func.isRequired,
  setUserDetails: PropTypes.func.isRequired,
  onGetBillingInfo: PropTypes.func.isRequired,
  onGetSubscription: PropTypes.func.isRequired
};

LoginForm.defaultProps = {
  valid: false,
  history: {}
};

const selector = formValueSelector("login");

export default reduxForm({
  form: "login",
  validate: validateLoginForm,
  onSubmit: () => {}
})(
  connect(
    state => ({
      id: state.account.id,
      email: selector(state, "email"),
      password: selector(state, "password")
    }),
    {
      setUserDetails: setUser,
      onGetBillingInfo: getBillingInfo,
      onGetSubscription: getSubscription
    }
  )(withRouter(LoginForm))
);
