/* eslint import/prefer-default-export : off */
import { createAuthApi } from "../createApi";

export const fetchStats = async () =>
  createAuthApi("/v1/logs-summary")({
    method: "GET"
  });

// export const fetchStats = async () =>
//   new Promise((resolve, reject) => {
//     setTimeout(() => {
//       reject(new Error("Oops!"));
//     }, 3000);
//   });
