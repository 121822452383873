import { createActions } from "redux-actions";

const {
  fetchLogs,
  fetchLogsSuccess,
  fetchLogsFailed,
  loadLogs,
  fetchMoreLogs,
  fetchMoreLogsSuccess,
  fetchMoreLogsFailed,
  loadMoreLogs,
  filterLogs,
  // filterLogsSuccess,
  // filterLogsFailed,
  setHasMoreData
} = createActions({
  FETCH_LOGS: () => null,
  FETCH_LOGS_SUCCESS: () => null,
  FETCH_LOGS_FAILED: () => null,
  LOAD_LOGS: data => ({
    data
  }),
  FETCH_MORE_LOGS: ({ offset, limit } = {}) => ({ offset, limit }),
  FETCH_MORE_LOGS_SUCCESS: () => null,
  FETCH_MORE_LOGS_FAILED: () => null,
  LOAD_MORE_LOGS: data => ({
    data
  }),
  FILTER_LOGS: ({
    deviceType,
    deviceVersion,
    os,
    osVersion,
    logLevels,
    searchQuery
  } = {}) => ({
    deviceType,
    deviceVersion,
    os,
    osVersion,
    logLevels,
    searchQuery
  }),
  // FILTER_LOGS_SUCCESS: () => null,
  // FILTER_LOGS_FAILED: () => null,
  SET_HAS_MORE_DATA: hasMoreData => ({
    hasMoreData
  })
});

export {
  fetchLogs,
  fetchLogsSuccess,
  fetchLogsFailed,
  loadLogs,
  fetchMoreLogs,
  fetchMoreLogsSuccess,
  fetchMoreLogsFailed,
  loadMoreLogs,
  filterLogs,
  // filterLogsSuccess,
  // filterLogsFailed,
  setHasMoreData
};
