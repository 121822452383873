import React from "react";
import PropTypes from "prop-types";
import COLORS from "constants/colors";

import { connect } from "react-redux";

import { Field, reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";

import { createAccount } from "redux/modules/signup/actions";
import { FormComponent, Button, NavLink } from "components";
import { isLoggedin } from "services/data.services";
import { getOnboardingStatus } from "services/storage.services";

import {
  LoginFormContainer as SignupFormContainer,
  Subline,
  ActionsContainer,
  Title,
  Description
} from "../../../Login/components/styledElements";

import fields from "./fields";

export class SignupForm extends React.Component {
  componentDidMount = () => {
    const { history } = this.props;

    if (isLoggedin()) {
      if (getOnboardingStatus() !== "DONE") {
        // Redirect the user to the onboarding page
        history.push("/onboarding");
      } else {
        // Redirect the user to the dashboard
        history.push("/dashboard");
      }
    }
  };

  componentDidUpdate = () => {
    const { history } = this.props;

    if (isLoggedin()) {
      if (getOnboardingStatus() !== "DONE") {
        // Redirect the user to the onboarding page
        history.push("/onboarding");
      } else {
        // Redirect the user to the dashboard
        history.push("/dashboard");
      }
    }
  };

  submitForm = values => {
    const { onCreateAccount, history } = this.props;
    // Format the values before calling the API
    const { email, name, company, password } = values;

    onCreateAccount({
      email,
      name,
      company,
      password,
      history
    });
  };

  validateForm = values => {
    this.submitForm(values);
  };

  render() {
    const { value, handleSubmit, pristine, submitting, isLoading } = this.props;

    return (
      <SignupFormContainer>
        <Title>Sign Up</Title>
        <Description>
          <div className="faded">Get started catching bugs.</div>
        </Description>
        <form onSubmit={handleSubmit(this.validateForm)}>
          {fields.map(field => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <Field {...field} component={FormComponent} value={value} />
          ))}
          <ActionsContainer>
            <Button
              type="submit"
              loading={isLoading}
              disabled={isLoading || pristine || submitting}
              theme={{
                background: COLORS.brandOrange,
                color: COLORS.white
              }}
            >
              Register
            </Button>
          </ActionsContainer>
          <Subline>
            <span>Already have an account?</span>
            <NavLink to="/account/login"> Login now!</NavLink>
          </Subline>
        </form>
      </SignupFormContainer>
    );
  }
}

SignupForm.defaultProps = {
  isLoading: false,
  onCreateAccount: Function.prototype,
  value: "",
  error: "",
  pristine: false,
  submitting: false,
  history: {}
};

SignupForm.propTypes = {
  value: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  isLoading: PropTypes.bool,
  onCreateAccount: PropTypes.func,
  history: PropTypes.instanceOf(Object)
};

const mapStateToProps = state => ({
  isLoading: state.signup.isSigningUp
});

const mapDispatchToProps = {
  onCreateAccount: createAccount
};

const ReduxSignupForm = reduxForm({
  form: "signupForm",
  onSubmit: () => {}
})(SignupForm);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ReduxSignupForm));
