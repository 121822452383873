/* eslint import/prefer-default-export : off */

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCaretDown,
  faClipboard,
  faClipboardCheck,
  faTachometerAlt,
  faInbox,
  faUser,
  faFileContract,
  faFire,
  faInfoCircle,
  faExclamationTriangle,
  faBug,
  faSearch,
  faFan,
  faDotCircle,
  faChevronRight,
  faChevronLeft,
  faTabletAlt,
  faMobileAlt,
  faDesktop,
  faCogs,
  faWrench,
  faCheckSquare,
  faTimes,
  faLaptopCode,
  faChargingStation,
  faCheck,
  faAlignRight,
  faCircleNotch,
  faChartArea,
  faCode,
  faEgg,
  faDiceD6,
  faPen,
  faDiceD20,
  faTrash,
  faPlus
} from "@fortawesome/free-solid-svg-icons";
import {
  faReact,
  faNodeJs,
  faAngular,
  faVuejs,
  faGoogle
} from "@fortawesome/free-brands-svg-icons";

export const loadIcons = () => {
  library.add(
    faCaretDown,
    faClipboard,
    faClipboardCheck,
    faTachometerAlt,
    faInbox,
    faUser,
    faFileContract,
    faFire,
    faInfoCircle,
    faExclamationTriangle,
    faBug,
    faSearch,
    faFan,
    faDotCircle,
    faChevronRight,
    faChevronLeft,
    faTabletAlt,
    faMobileAlt,
    faDesktop,
    faCogs,
    faWrench,
    faCheckSquare,
    faReact,
    faNodeJs,
    faAngular,
    faVuejs,
    faTimes,
    faLaptopCode,
    faChargingStation,
    faCheck,
    faAlignRight,
    faCircleNotch,
    faChartArea,
    faCode,
    faEgg,
    faDiceD6,
    faPen,
    faDiceD20,
    faGoogle,
    faTrash,
    faPlus
  );
};
