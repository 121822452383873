import {
  required,
  minPasswordLength,
  email
} from "utils/validate";

export default [
  {
    label: "Email",
    name: "email",
    validate: [required, email],
    type: "email",
    layout: { md: 12 }
  },
  {
    label: "Name",
    name: "name",
    validate: [required],
    type: "text",
    layout: { md: 12 }
  },
  {
    label: "Company Name",
    name: "company",
    validate: [required],
    type: "text",
    layout: {
      md: 12
    }
  },
  {
    label: "Password",
    name: "password",
    validate: [required, minPasswordLength],
    type: "password",
    layout: {
      md: 12
    }
  }
];
