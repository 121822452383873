import styled from "styled-components";
import { NavLink } from "react-router-dom";

import COLORS from "constants/colors";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1.5%;
  margin: 0;
  background: ${COLORS.mainFg};
  font-size: 0.9rem;
  color: white;

  div {
    flex: 1;
    display: flex;
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;

    div {
      margin: 10px;
    }
  }
`;

export const Left = styled.div`
  justify-content: flex-start;
`;

export const Center = styled.div`
  justify-content: center;
`;

export const Right = styled.div`
  justify-content: flex-end;
`;

export const FooterLinks = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: space-between;

  li {
  }

  li::after {
    margin: 0 10px;
    content: "•";
  }

  li:last-of-type::after {
    content: "";
    margin: 0;
  }
`;

export const TeamLink = styled.a`
  font-family: 'Enhanced';
  letter-spacing: .3rem;
  text-decoration: none;
  color: ${COLORS.brandOrange};
`;

export const Link = styled(NavLink)`
  text-decoration: none;
  color: white;
  position: relative;

  &::after {
    z-index: -1;
    content: "";
    opacity: 0;
    transition: all 0.1s ease-in;
    position: absolute;
    background: ${COLORS.brandOrange};
    height: 1px;
    width: 102%;
    top: 50%;
    left: -1%;
  }

  &:hover::after {
    opacity: 1;
    top: 100%;
  }
`;
