import styled from "styled-components";

import { CardContainer } from "components";

export const HistoryContainer = styled.div`
  padding: 32px 0;
`;

export const LogsContainer = styled(CardContainer)`
  padding: 0;
  overflow: hidden;
`;

export const Content = styled.div`
  padding: 30px;
  position: relative;

  ${props =>
    props.bordered &&
    `
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  `}
`;

export const BreakdownTitle = styled.h2`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 8px;
`;

export const BreakdownDescription = styled.div`
  font-size: 14px;
  margin-bottom: 32px;
  opacity: 0.7;
`;
