import React from "react";

import { Hidden } from "react-grid-system";
import { SignupForm } from "./components";

import { LoginContainer, FormContainer, Navbar } from "../Login/styledElements";
import { Logo } from "../LandingPage/components/HeaderSection/styledElements";

const SignupPage = () => {
  return (
    <LoginContainer>
      <Hidden sm xs md>
        <Navbar>
          <Logo to="/">kalayo</Logo>
        </Navbar>
      </Hidden>
      <FormContainer>
        <SignupForm />
      </FormContainer>
    </LoginContainer>
  );
};

export default SignupPage;
