/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import { Col } from "react-grid-system";
import Header from "./Header";
import FormItem from "./FormItem";

const FormComponent = props =>
  // eslint-disable-next-line react/destructuring-assignment
  props.header ? (
    <Col sm={24}>
      <Header {...props} />
    </Col>
  ) : (
    <FormItem {...props} />
  );

FormComponent.propTypes = {
  header: PropTypes.bool
};

FormComponent.defaultProps = {
  header: false
};

export default FormComponent;
