import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";
import { Container, Row } from "react-grid-system";

import COLORS from "constants/colors";

export const NavigationContainer = styled(Container).attrs(props => ({
  fluid: !!props.sticky
}))`
  padding: 0 15px !important;
  transition: padding 275ms ease-out;

  ${props =>
    props.sticky &&
    `
    position: sticky !important;
    top: 0;
    z-index: 1;
    padding: 0 !important;
    transition: padding 275ms;
  `}
`;

export const Content = styled.div`
  padding: 20px 24px;
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 0px 3px 25px 0px rgba(0, 0, 0, 0.075);
  transition: border-radius 275ms;

  ${props =>
    props.sticky &&
    `
  border-radius: 0;
  transition: border-radius 275ms;
  `}
`;

export const LinksContainer = styled(Row).attrs({
  align: "center"
})`
  margin: 0 !important;

  .nav-link {
    ${props =>
      ["xs"].includes(props.screen) &&
      `
      margin: 0 !important;
    `}
  }
`;

const linkStyle = css`
  margin: 0 16px;
  cursor: pointer;
  font-size: 14px;
  color: inherit;
  transition: color 275ms ease-out;
  text-decoration: none;
  display: inline-flex;
  align-items: center;

  svg {
    margin-right: 8px;
    padding: 8px;
    border-radius: 1.125em;
    background-color: rgba(0, 0, 0, 0.06);
    width: 1.125em !important;
    height: 1.125em !important;
  }

  &:hover,
  &.active {
    color: ${COLORS.brandOrange};
    transition: color 275ms ease-in;
  }
`;

export const TabLink = styled(NavLink).attrs({
  className: "nav-link"
})`
  ${linkStyle};
`;

export const ExternalLink = styled.a.attrs({
  className: "nav-link",
  target: "_blank",
  rel: "noopener noreferrer"
})`
  ${linkStyle};
`;
