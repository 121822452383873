import COLORS from "constants/colors";

export default {
  color: COLORS.mainFg,
  cardBackground: COLORS.white,
  background: COLORS.mainBg,
  activeStep: COLORS.brandOrange,
  doneStep: COLORS.transparent,
  nextStep: COLORS.transparent,
  next: COLORS.brandOrange,
  finish: COLORS.brandYellow,
  rightBackground: COLORS.mainBg
};
