import decode from "jwt-decode";
import { handleActions, combineActions } from "redux-actions";

import * as storage from "services/storage.services";
import {
  setUser,
  logoutUser,
  getSubscription,
  getSubscriptionFailed,
  getSubscriptionSuccess,
  getBillingInfo,
  getBillingInfoFailed,
  getBillingInfoSuccess,
  setBillingInfo,
  setBillingInfoFailed,
  setBillingInfoSuccess,
  subscribe,
  subscribeFailed,
  subscribeSuccess,
  setPricing,
  cancelSubscription,
  cancelSubscriptionFailed,
  cancelSubscriptionSuccess,
  inviteByEmail,
  inviteByEmailFailed,
  inviteByEmailSuccess,
  getOrgUsers,
  getOrgUsersFailed,
  getOrgUsersSuccess
} from "./actions";

const decodedUser = storage.getIdToken() && decode(storage.getIdToken());
const storedBilling = storage.getBilling();
const storedSubscription = storage.getSubscription();

const INITIAL_STATE = {
  id: decodedUser ? decodedUser.sub : null,
  organization: decodedUser
    ? decodedUser["https://kalayo.io/claims"].organization
    : null,
  orgUsers: [],
  profile: {
    name: decodedUser ? decodedUser.name : null,
    picture: decodedUser ? decodedUser.picture : null,
    email: decodedUser ? decodedUser.email : null
  },
  activeSub: storedSubscription || null,
  pricing: null,
  billing: storedBilling || null,
  card: null,
  isSubscribing: false,
  isFetchingOrgSub: false,
  isFetchingBilling: false,
  isSendingBilling: false,
  isCancellingBilling: false,
  isLoadingOrgUsers: false
};

export default handleActions(
  {
    [getSubscription]: state => ({
      ...state,
      isFetchingOrgSub: true
    }),
    [getSubscriptionFailed]: state => ({
      ...state,
      isFetchingOrgSub: false
    }),
    [getSubscriptionSuccess]: (state, { payload: { activeSub } }) => ({
      ...state,
      isFetchingOrgSub: false,
      activeSub
    }),
    [getBillingInfo]: state => ({
      ...state,
      isFetchingBilling: true
    }),
    [getBillingInfoFailed]: state => ({
      ...state,
      isFetchingBilling: false
    }),
    [getBillingInfoSuccess]: (state, { payload: { billing, card } }) => ({
      ...state,
      billing,
      card,
      isFetchingBilling: false
    }),
    [subscribe]: state => ({
      ...state,
      isSubscribing: true
    }),
    [subscribeFailed]: state => ({
      ...state,
      isSubscribing: false
    }),
    [subscribeSuccess]: state => ({
      ...state,
      isSubscribing: false
    }),
    [setPricing]: (state, { payload: { pricing } }) => ({
      ...state,
      pricing
    }),
    [setUser]: (state, { payload: { user } }) => ({
      ...state,
      ...user
    }),
    [setBillingInfo]: state => ({
      ...state,
      isSendingBilling: true
    }),
    [setBillingInfoFailed]: state => ({
      ...state,
      isSendingBilling: false
    }),
    [setBillingInfoSuccess]: (state, { payload: { billing } }) => ({
      ...state,
      billing: {
        ...billing
      },
      isSendingBilling: false
    }),
    [logoutUser]: () => ({
      ...INITIAL_STATE
    }),
    [cancelSubscription]: state => ({
      ...state,
      isCancellingBilling: true
    }),
    [cancelSubscriptionFailed]: state => ({
      ...state,
      isCancellingBilling: false
    }),
    [cancelSubscriptionSuccess]: state => ({
      ...state,
      pricing: null,
      card: null,
      activeSub: null,
      isCancellingBilling: false
    }),
    [inviteByEmail]: state => ({
      ...state,
      isInvitingByEmail: true
    }),
    [combineActions(inviteByEmailFailed, inviteByEmailSuccess)]: state => ({
      ...state,
      isInvitingByEmail: false
    }),
    [getOrgUsers]: state => ({
      ...state,
      isLoadingOrgUsers: true
    }),
    [getOrgUsersFailed]: state => ({
      ...state,
      isLoadingOrgUsers: false
    }),
    [getOrgUsersSuccess]: (state, { payload: { users } }) => ({
      ...state,
      orgUsers: users,
      isLoadingOrgUsers: false
    })
  },
  INITIAL_STATE
);
