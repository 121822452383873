import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  HeaderSectionContainer,
  NavbarContainer,
  Logo,
  NavbarRight,
  NavbarList,
  Content,
  Headline,
  ButtonContainer,
  Link,
  HashLink,
  NavbarLeft
} from "pages/LandingPage/components/HeaderSection/styledElements";
import { Button } from "components";
import COLORS from "constants/colors";

const NotFound = () => (
  <HeaderSectionContainer>
    <FontAwesomeIcon
      icon="fire"
      color={COLORS.brandOrange}
      style={{
        position: "absolute",
        bottom: 0,
        right: 70,
        fontSize: 700,
        opacity: 0.075
      }}
    />
    <NavbarContainer>
      <NavbarLeft>
        <Logo to="/">kalayo</Logo>
        <NavbarList>
          <li>
            <HashLink to="/#product">
              <span>Product</span>
            </HashLink>
          </li>
          <li>
            <HashLink to="/#logging">
              <span>Developers</span>
            </HashLink>
          </li>
          <li>
            <HashLink to="/#pricing">
              <span>Pricing</span>
            </HashLink>
          </li>
        </NavbarList>
      </NavbarLeft>
      <NavbarRight>
        <NavbarList>
          <li>
            <Link to="/account/signup">
              <Button
                theme={{
                  background: COLORS.brandOrange,
                  color: COLORS.white
                }}
              >
                <span>Sign up now!</span>
                <FontAwesomeIcon icon="pen" />
              </Button>
            </Link>
          </li>
          <li>
            <Link to="/account/login">
              <span>Login</span>
            </Link>
          </li>
        </NavbarList>
      </NavbarRight>
    </NavbarContainer>
    <Content>
      <Headline>Woops! This page doesn&apos;t exist.</Headline>
      <ButtonContainer>
        <Link to="/">
          <Button
            theme={{
              background: COLORS.brandOrange,
              color: COLORS.white
            }}
          >
            Go to the main page
          </Button>
        </Link>
      </ButtonContainer>
    </Content>
  </HeaderSectionContainer>
);

export default NotFound;
