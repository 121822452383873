import React from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { CardElement, injectStripe } from "react-stripe-elements";

import { setBillingInfo, subscribe } from "redux/modules/account/actions";

import { Input, Label } from "components";
import { Button } from "../Settings/styledElements";
import { BillingFormContainer as FormContainer } from "../BillingInfoForm/styledElements";

const SubscriptionForm = ({
  toggleShowForm,
  handleSubmit,
  isSubscribing,
  isSendingBilling,
  onSubscribe,
  name,
  email,
  pricing,
  promoCode,
  stripe
}) => {
  const onSubmit = async ({ paymentMethod, ...values }) => {
    onSubscribe({
      pricing,
      promoCode,
      billing: {
        ...values,
        contactName: name,
        email,
        paymentMethod
      }
    });

    toggleShowForm();
  };

  const validateForm = async values => {
    const { entityName } = values;

    const response = await stripe.createPaymentMethod("card", CardElement, {
      billing_details: {
        email,
        name: entityName
      }
    });

    if (response.error) {
      toast.error(response.error.message);
      return;
    }

    onSubmit({
      ...values,
      paymentMethod: response.paymentMethod
    });
  };

  return (
    <FormContainer>
      <form onSubmit={handleSubmit(validateForm)} className="subscription">
        <Label>Select Subscription</Label>
        <Field
          name="pricing"
          label="Select Subscription"
          component="select"
          value={pricing}
        >
          {[
            { value: "monthly", label: "Monthly" },
            { value: "annual", label: "Annual" }
          ].map(option => (
            <option value={option.value}>{option.label}</option>
          ))}
        </Field>
        <Label>Card Details</Label>
        <CardElement />
        <Field
          name="entityName"
          type="text"
          label="Name"
          component={Input}
          layout={{ sm: 12 }}
        />
        <Field
          name="businessAddress"
          type="text"
          label="Business Address"
          component={Input}
          layout={{ sm: 12 }}
        />
        <Field
          name="promoCode"
          type="text"
          label="Promo Code"
          component={Input}
          layout={{ sm: 12 }}
        />
        <Button
          loading={isSendingBilling || isSubscribing}
          disabled={isSendingBilling || isSubscribing}
          type="submit"
        >
          Submit
        </Button>
      </form>
    </FormContainer>
  );
};

SubscriptionForm.propTypes = {
  toggleShowForm: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSendingBilling: PropTypes.bool,
  isSubscribing: PropTypes.bool,
  onSubscribe: PropTypes.func.isRequired,
  stripe: PropTypes.instanceOf(Object).isRequired,
  name: PropTypes.string,
  email: PropTypes.string,
  pricing: PropTypes.string,
  promoCode: PropTypes.string
};

SubscriptionForm.defaultProps = {
  isSendingBilling: false,
  isSubscribing: false,
  pricing: "monthly",
  name: undefined,
  email: undefined,
  promoCode: undefined
};

const selector = formValueSelector("subscription");

export default connect(
  state => ({
    contactName: state.account.billing
      ? state.account.billing.contactName
      : null,
    entityName: selector(state, "entityName"),
    businessAddress: selector(state, "businessAddress"),
    promoCode: selector(state, "promoCode"),
    pricing: selector(state, "pricing"),
    name: state.account.profile.name,
    email: state.account.profile.email,
    isSendingBilling: state.account.isSendingBilling,
    isSubscribing: state.account.isSubscribing,
    initialValues: {
      entityName: state.account.billing
        ? state.account.billing.entityName
        : null,
      businessType: state.account.billing
        ? state.account.billing.businessType
        : null,
      businessAddress: state.account.billing
        ? state.account.billing.businessAddress
        : null
    }
  }),
  {
    onSetBillingInfo: setBillingInfo,
    onSubscribe: subscribe
  }
)(
  reduxForm({
    form: "subscription",
    onSubmit: () => {}
  })(injectStripe(SubscriptionForm))
);
