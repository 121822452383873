import auth0 from "auth0-js";
import decode from "jwt-decode";

import { getIdToken } from "../../storage.services";

const config = {
  DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN,
  CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID,
  REALM: process.env.REACT_APP_AUTH0_DB
};

export const Auth = new auth0.WebAuth({
  domain: config.DOMAIN,
  clientID: config.CLIENT_ID
});

export const AuthLogin = (username, password, callback) =>
  Auth.client.login(
    {
      realm: config.REALM,
      username,
      password,
      scope: "openid"
    },
    callback
  );

export const AuthGetUserInfo = (accessToken, callback) =>
  Auth.client.userInfo(accessToken, callback);

const getTokenExpirationDate = idToken => {
  const token = decode(idToken);
  if (!token.exp) return null;

  const date = new Date(0);
  date.setUTCSeconds(token.exp);

  return date;
};

const isTokenExpired = token => {
  const expirationDate = getTokenExpirationDate(token);
  return expirationDate.getTime() < new Date().getTime();
};

export const isLoggedin = () => {
  const idToken = getIdToken();

  try {
    return !!idToken && !isTokenExpired(idToken);
  } catch {
    return false;
  }
};
