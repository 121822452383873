import styled from "styled-components";
import COLORS from "constants/colors";

export const LandingPageContainer = styled.div`
  margin: 0 !important;
  padding: 0 !important;
  background-color: white;
  min-height: 100vh;
  width: 100%;
`;

export const Headline = styled.h2`
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 3rem;
  line-height: 3.2rem;
  margin: 0;
  max-width: 800px;
  margin-bottom: 1.5rem;
  color: ${COLORS.mainFg};
`;

export const Subline = styled.div`
  font-size: 1rem;
  line-height: 2rem;
  opacity: 0.5;
  max-width: 800px;
`;
