import styled from "styled-components";

export default styled.h2.attrs({
  className: "card-title"
})`
  font-size: 22px;
  font-weight: normal;
  margin-bottom: 24px;
  font-weight: 500;
`;
