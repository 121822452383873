import React from "react";
// import ReactGA from "react-ga";

import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import { PrivateRoute } from "./components";

// import components here
import {
  Dashboard,
  LandingPage,
  SignupPage,
  Login,
  Documentation,
  Onboarding,
  Privacy,
  NotFound,
  ForgotPassword,
  ResetPassword
} from "../pages";

const history = createBrowserHistory();

const Routes = () => (
  <Router history={history}>
    <Switch>
      <Route exact path="/" component={LandingPage} />
      <Route exact path="/account/signup" component={SignupPage} />
      <Route exact path="/account/login" component={Login} />
      <Route exact path="/account/forgot" component={ForgotPassword} />
      <Route
        exact
        path="/account/reset-password/:token"
        component={ResetPassword}
      />
      <Route path="/docs" component={Documentation} />
      <Route path="/privacy" component={Privacy} />
      <PrivateRoute exact path="/onboarding" component={Onboarding} />
      <PrivateRoute path="/dashboard" component={Dashboard} />
      {/* 404 Page Not Found */}
      <Route path="*" component={NotFound} />
    </Switch>
  </Router>
);

export default Routes;
