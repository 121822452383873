/* eslint-disable react/jsx-one-expression-per-line */
import React from "react";
import PropTypes from "prop-types";
import MarkdownRenderer from "react-markdown";
import SyntaxHighlighter from "react-syntax-highlighter";

import { getIdToken } from "services/storage.services";

import COLORS from "constants/colors";
import { FRAMEWORKS_VALUES } from "constants/onboarding";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Title } from "../../styledElements";
import { IconsContainer } from "../CreateProject/styledElements";
import { SectionTitle, CautionText } from "./styledElements";

const Integration = ({ frameworks }) => {
  const isReact = frameworks.includes(FRAMEWORKS_VALUES.REACT);
  const isReactNative = frameworks.includes(FRAMEWORKS_VALUES.REACT_NATIVE);
  const isJs = frameworks.includes(FRAMEWORKS_VALUES.JS);

  const npmPackage =
    isReact || isReactNative ? "react-kalayo-logger" : "kalayo-logger";

  const initialKalayo = `import Kalayo from "${npmPackage}";

new Kalayo(
  "${process.env.REACT_APP_BACKEND_URI}/v1/logs",
  {
    'Authorization': 
    ${getIdToken()}
  }
);
`;

  const sampleJSCode = `import { log } from "${npmPackage}";

const buggyFunction = () => {
  console.log(unknownVariable)
}

try {
  buggyFunction();
} catch (err) {
  log(err.message, err.stacktrace)
}
`;

  const sampleReactLog = `import { log } from "${npmPackage}";

const buggyFunction = () => {
  console.log(unknownVariable)
}

try {
  buggyFunction();
} catch (err) {
  log(err.message, err.stacktrace)
}
`;

  const sampleReactCode = `import { ErrorBoundary } from "${npmPackage}";

const App = () => (
  <ErrorBoundary>
    <div>Bounded App</div>
  </ErrorBoundary>
)
`;

  const sampleReactHoc = `import { withKalayo } from "${npmPackage}";

class Example extends Component {
  render() {
    return <div>Bounded App</div>;
  }
}

export default withKalayo(Example);
`;

  return (
    <Container>
      <IconsContainer>
        <FontAwesomeIcon icon="laptop-code" color={COLORS.primary} size="xs" />
      </IconsContainer>
      <Title>Add Kalayo to your project.</Title>
      <SectionTitle>Installation</SectionTitle>
      <MarkdownRenderer source={`Install \`${npmPackage}\` from NPM.`} />
      <SyntaxHighlighter language="bash">
        {`yarn add ${npmPackage}`}
      </SyntaxHighlighter>
      or
      <SyntaxHighlighter language="bash">
        {`npm install ${npmPackage}`}
      </SyntaxHighlighter>
      <SectionTitle>Set up Kalayo</SectionTitle>
      <MarkdownRenderer source="Initialize Kalayo Logger with the dashboard's API URL and your API token." />
      {(frameworks.includes(FRAMEWORKS_VALUES.REACT) ||
        frameworks.includes(FRAMEWORKS_VALUES.REACT_NATIVE)) && (
        <MarkdownRenderer source="In `App.js`, where we initialize our app:" />
      )}
      <SyntaxHighlighter language="javascript">
        {initialKalayo}
      </SyntaxHighlighter>
      <span>
        <CautionText>Note:</CautionText> The examples in this page use your
        personal API key. Do not share this example without first removing the
        API key.
      </span>
      <SectionTitle>Usage</SectionTitle>
      {isJs && (
        <>
          <MarkdownRenderer source="Start using Kalayo by calling the `log()` function to monitor errors." />
          <SyntaxHighlighter language="javascript">
            {sampleJSCode}
          </SyntaxHighlighter>
        </>
      )}
      {(isReact || isReactNative) && (
        <>
          <MarkdownRenderer
            source={`Start using Kalayo in your React app.
          
You can use the provided \`ErrorBoundary\` component or wrap your existing components with the HOC \`withKalayo\`:`}
          />
          <SyntaxHighlighter language="jsx">
            {sampleReactCode}
          </SyntaxHighlighter>
          <SyntaxHighlighter language="jsx">{sampleReactHoc}</SyntaxHighlighter>
          <MarkdownRenderer
            source={`### Custom Logs
            
For custom logs, you can use the \`log\` function:`}
          />
          <SyntaxHighlighter language="jsx">{sampleReactLog}</SyntaxHighlighter>
        </>
      )}
    </Container>
  );
};

Integration.propTypes = {
  frameworks: PropTypes.instanceOf(Array).isRequired
};

export default Integration;
