import React from "react";
import PropTypes from "prop-types";

import * as StorageServices from "services/storage.services";

// import { connect } from "react-redux";
import { Row, Col } from "react-grid-system";
import { Avatar } from "components";
import {
  PopoverContainer,
  PopoverContent,
  ActionsContainer,
  Name,
  Email
} from "./styledElements";

import "./popover.css";

class UserPopover extends React.PureComponent {
  onLogout = async () => {
    const { onClose, history } = this.props;

    await StorageServices.clearAuthTokens();
    await StorageServices.clearStorage();

    history.push("/");
    onClose();
  };

  onGoAccount = () => {
    const { history, onClose } = this.props;
    history.push("/dashboard/account");
    onClose();
  };

  render() {
    const { name, picture, email } = this.props;

    return (
      <PopoverContainer>
        <PopoverContent>
          <Row>
            <Avatar
              containerStyle={{ marginRight: 0, marginLeft: 15 }}
              src={picture}
            />
            <Col>
              <Name>{name}</Name>
              <Email>{email}</Email>
            </Col>
          </Row>
          <ActionsContainer>
            <Row align="center" justify="between">
              <button
                type="button"
                className="account"
                onClick={this.onGoAccount}
              >
                Account
              </button>
              <button type="button" className="logout" onClick={this.onLogout}>
                Logout
              </button>
            </Row>
          </ActionsContainer>
        </PopoverContent>
      </PopoverContainer>
    );
  }
}

UserPopover.propTypes = {
  name: PropTypes.string,
  picture: PropTypes.string,
  email: PropTypes.string,
  history: PropTypes.instanceOf(Object).isRequired,
  onClose: PropTypes.func.isRequired
};

UserPopover.defaultProps = {
  name: null,
  picture: null,
  email: null
};

export default UserPopover;
