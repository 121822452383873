import styled from "styled-components";

import { Col } from "react-grid-system";

import { CardContainer as Card } from "components";

export const AccountContainer = styled.div`
  padding: 32px 0;
`;

export const CardContainer = styled(Card)`
  margin-bottom: 24px;
`;

export const VerticalDivider = styled.div`
  height: 32px;
`;

export const SidebarContainer = styled(Col)`
  display: flex;
  margin-right: 0 !important;
`;

export const SettingsContainer = styled(Col).attrs({
  xl: 9,
  lg: 9,
  md: 9
})`
  padding-left: 0 !important;
`;
