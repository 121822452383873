import { handleActions, combineActions } from "redux-actions";
import { hasFetchedAll } from "utils/logs";
import {
  fetchLogs,
  fetchLogsSuccess,
  fetchLogsFailed,
  loadLogs,
  fetchMoreLogs,
  fetchMoreLogsSuccess,
  fetchMoreLogsFailed,
  loadMoreLogs,
  filterLogs,
  // filterLogsSuccess,
  // filterLogsFailed,
  setHasMoreData
} from "./actions";

const DEFAULT_STATE = {
  isFetching: false,
  hasMoreData: true,
  fetchMoreOffset: 0,
  data: [],
  filters: {}
};

const MAX_ITEMS_PER_FETCH = 20;

export default handleActions(
  {
    [fetchLogs]: state => ({
      ...state,
      isFetching: true
    }),
    [combineActions(fetchLogsSuccess, fetchLogsFailed)]: state => ({
      ...state,
      isFetching: false
    }),
    [loadLogs]: (state, { payload: { data = [] } }) => {
      const { fetchMoreOffset: oldOffset } = state;
      const hasMoreData = !hasFetchedAll({ data, limit: MAX_ITEMS_PER_FETCH });
      const fetchMoreOffset = hasMoreData ? data.length : oldOffset;

      const logs = data.map(log => {
        if (typeof log.logOptions === "undefined") return { ...log };

        return {
          ...log,
          logOptions: JSON.parse(log.logOptions)
        };
      });

      return {
        ...state,
        data: logs,
        hasMoreData,
        fetchMoreOffset
      };
    },
    [fetchMoreLogs]: state => ({
      ...state
    }),
    [combineActions(fetchMoreLogsSuccess, fetchMoreLogsFailed)]: state => ({
      ...state
    }),
    [loadMoreLogs]: (state, { payload: { data } }) => {
      const { data: currentData, fetchMoreOffset: oldOffset } = state;
      const hasMoreData = !hasFetchedAll({ data, limit: MAX_ITEMS_PER_FETCH });
      const fetchMoreOffset = hasMoreData ? data.length : oldOffset;

      const logs = data.map(log => {
        if (typeof log.logOptions === "undefined") return { ...log };

        return {
          ...log,
          logOptions: JSON.parse(log.logOptions)
        };
      });

      return {
        ...state,
        hasMoreData,
        fetchMoreOffset,
        data: [...currentData, ...logs]
      };
    },
    [filterLogs]: (
      state,
      {
        payload: {
          deviceType,
          deviceVersion,
          os,
          osVersion,
          logLevels,
          searchQuery
        }
      }
    ) => ({
      ...state,
      filters: {
        deviceType,
        deviceVersion,
        os,
        osVersion,
        logLevels,
        searchQuery
      }
    }),
    // [combineActions(filterLogsSuccess, filterLogsFailed)]: state => ({
    //   ...state,
    //   isFetching: false
    // }),
    [setHasMoreData]: (state, { payload: { hasMoreData } }) => ({
      ...state,
      hasMoreData
    })
  },
  DEFAULT_STATE
);
