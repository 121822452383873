import React from "react";

import LOG_LEVELS, { LOG_LEVELS_COLORS } from "constants/logLevels";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const getLogLevelIconProps = logLevel => {
  switch (logLevel) {
    case LOG_LEVELS.DEFAULT: {
      return {
        icon: "info-circle",
        color: LOG_LEVELS_COLORS[LOG_LEVELS.DEFAULT]
      };
    }
    case LOG_LEVELS.WARNING: {
      return {
        icon: "exclamation-triangle",
        color: LOG_LEVELS_COLORS[LOG_LEVELS.WARNING]
      };
    }
    case LOG_LEVELS.ERROR: {
      return {
        icon: "bug",
        color: LOG_LEVELS_COLORS[LOG_LEVELS.ERROR]
      };
    }
    default:
      return {
        icon: "info-circle",
        color: LOG_LEVELS_COLORS[LOG_LEVELS.DEFAULT]
      };
  }
};

export default logLevel => {
  const iconBaseStyle = {
    padding: 7,
    borderRadius: 4
  };

  switch (logLevel) {
    case LOG_LEVELS.DEFAULT: {
      return (
        <FontAwesomeIcon
          icon="info-circle"
          size="lg"
          color={LOG_LEVELS_COLORS[LOG_LEVELS.DEFAULT]}
          style={{
            ...iconBaseStyle,
            backgroundColor: "rgba(1, 137, 182, 0.22)"
          }}
        />
      );
    }
    case LOG_LEVELS.WARNING: {
      return (
        <FontAwesomeIcon
          icon="exclamation-triangle"
          size="lg"
          color={LOG_LEVELS_COLORS[LOG_LEVELS.WARNING]}
          style={{
            ...iconBaseStyle,
            backgroundColor: "rgba(222, 134, 20, 0.22)"
          }}
        />
      );
    }
    case LOG_LEVELS.ERROR: {
      return (
        <FontAwesomeIcon
          icon="bug"
          size="lg"
          color={LOG_LEVELS_COLORS[LOG_LEVELS.ERROR]}
          style={{
            ...iconBaseStyle,
            backgroundColor: "rgba(204, 0, 0, 0.22)"
          }}
        />
      );
    }
    default:
      return (
        <FontAwesomeIcon
          icon="info-circle"
          size="lg"
          color={LOG_LEVELS_COLORS[LOG_LEVELS.DEFAULT]}
          style={{
            ...iconBaseStyle,
            backgroundColor: "rgba(1, 137, 182, 0.22)"
          }}
        />
      );
  }
};
