import styled from "styled-components";
import COLORS from "constants/colors";

export const PopoverContainer = styled.div`
  padding: 12px 30px 30px;
  margin-right: -30px;
`;

export const PopoverContent = styled.div`
  background-color: rgb(255, 255, 255);
  border-radius: 12px;
  box-shadow: 0px 3px 25px 0px rgba(0, 0, 0, 0.2);
  padding: 16px;
`;

export const Name = styled.div`
  font-size: 14px;
  font-weight: bold;
`;

export const Email = styled(Name)`
  font-size: 12px;
  font-weight: normal;
  margin-top: 6px;
`;

export const ActionsContainer = styled.div`
  margin-top: 24px;
  padding: 16px 16px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  font-size: 14px;

  button {
    outline: none;
    font-family: inherit;
    border: none;
    background-color: transparent;
    cursor: pointer;
    opacity: 1;
    transition: opacity 275ms;

    &.account {
      padding: 8px 10px;
      border-radius: 4px;
      font-weight: bold;
      background-color: ${COLORS.brandOrange};
      color: rgb(255, 255, 255);
    }

    &.logout {
      opacity: 0.7;
    }

    &:hover {
      opacity: 0.7;
    }
  }
`;
