import React, { useEffect, useState, useRef, useCallback } from "react";
import { toast } from "react-toastify";
import { Row, Col } from "react-grid-system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { createToken, getTokens, deleteToken } from "services/data.services";

import SUCCESS_MESSAGES from "constants/success";

import { Loader } from "components";
import {
  SettingsCard,
  SettingContainer,
  SettingTitle,
  SettingDescription,
  ApiKeyContainer,
  ClipboardButton,
  Button
} from "../Settings/styledElements";

import {
  CardHeader,
  CardHeaderTitle,
  CardActionContainer,
  UsersList as TokenList,
  RowContainer,
  HeaderRow,
  ActionContainer,
  Button as InviteButton
} from "../Team/styledElements";

import CardTitle from "../../../CardTitle";

const Tokens = () => {
  const [apiKeyCopied, setApiKeyCopied] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [tokens, setTokens] = useState([]);
  const [isRefetching, setRefetching] = useState(true);

  const apiKeyRef = useRef({});

  const onCopyToClipboard = (e, id) => {
    if (apiKeyRef.current[id]) apiKeyRef.current[id].select();
    document.execCommand("copy");
    e.target.focus();

    setApiKeyCopied(copied => ({
      ...copied,
      [id]: true
    }));
  };

  const handleCreateToken = async () => {
    setLoading(true);

    const { error } = await createToken();

    if (error) toast.error(error);
    else toast.success(SUCCESS_MESSAGES.TOKEN_CREATED);

    setRefetching(true);
    setLoading(false);
  };

  const fetchTokens = useCallback(async () => {
    setLoading(true);

    const { response, error } = await getTokens();

    setRefetching(false);
    setLoading(false);

    if (error) {
      toast.error(error);
      return;
    }

    setTokens(response);
  }, []);

  const handleDeleteToken = async id => {
    setLoading(true);

    const { error } = await deleteToken({ id });

    if (error) toast.error(error);
    else toast.success(SUCCESS_MESSAGES.TOKEN_DELETED);

    setRefetching(true);
    setLoading(false);
  };

  useEffect(() => {
    if (isRefetching) {
      fetchTokens();
    }
  }, [isRefetching, fetchTokens]);

  return (
    <SettingsCard id="tokens">
      <CardTitle>API Tokens</CardTitle>
      <SettingContainer>
        <CardHeader nowrap>
          <CardHeaderTitle sm={9} xs={6}>
            <CardTitle>Tokens</CardTitle>
          </CardHeaderTitle>
          <CardActionContainer sm={3} xs={6}>
            <InviteButton onClick={handleCreateToken}>
              <Row nowrap justify="center">
                <Col lg={6} md={6} sm={6} xs={6}>
                  Generate
                </Col>
                <Col lg={2} md={2} sm={2} xs={2}>
                  <FontAwesomeIcon icon="plus" />
                </Col>
              </Row>
            </InviteButton>
          </CardActionContainer>
        </CardHeader>
        <SettingTitle id="managetokens">Manage Tokens</SettingTitle>
        <SettingDescription>Manage and view your API tokens</SettingDescription>
        <HeaderRow nowrap>
          <Col lg={5} md={5} sm={5} xs={5}>
            TOKEN
          </Col>
          <Col lg={3} md={3} sm={3} xs={3}>
            CREATED AT
          </Col>
          <Col lg={3} md={3} sm={3} xs={3}>
            LAST USED
          </Col>
          <Col lg={1} md={1} sm={1} xs={1} />
          {/* <Col lg={1} md={1} sm={2} xs={3} /> */}
        </HeaderRow>
        <TokenList>
          {tokens.map(token => (
            <RowContainer nowrap key={token.id}>
              <Col lg={5} md={5} sm={5} xs={5}>
                <ApiKeyContainer>
                  <textarea
                    readOnly
                    ref={el => {
                      apiKeyRef.current[token.id] = el;
                    }}
                  >
                    {token.token}
                  </textarea>
                  <ClipboardButton
                    onClick={event => {
                      onCopyToClipboard(event, token.id);
                    }}
                  >
                    {apiKeyCopied[token.id] ? (
                      <FontAwesomeIcon icon="clipboard-check" />
                    ) : (
                      <FontAwesomeIcon icon="clipboard" />
                    )}
                  </ClipboardButton>
                </ApiKeyContainer>
              </Col>
              <Col lg={3} md={3} sm={3} xs={3}>
                {new Date(token.created_at * 1000).toLocaleDateString()}
              </Col>
              <Col lg={3} md={3} sm={3} xs={3}>
                {new Date(token.last_used_at * 1000).toLocaleDateString()}
              </Col>
              <Col lg={1} md={1} sm={1} xs={1}>
                <ActionContainer>
                  <Button
                    onClick={() => handleDeleteToken(token.id)}
                    className="danger"
                  >
                    <FontAwesomeIcon icon="trash" />
                  </Button>
                </ActionContainer>
              </Col>
            </RowContainer>
          ))}
          {tokens.length === 0 && (
            <RowContainer>
              <Col md={12}>No tokens found.</Col>
            </RowContainer>
          )}
          <Loader
            loading={isLoading}
            containerStyle={{
              borderRadius: 8
            }}
          />
        </TokenList>
      </SettingContainer>
    </SettingsCard>
  );
};

export default Tokens;
