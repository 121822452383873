import { createActions } from "redux-actions";

const {
  getSubscription,
  getSubscriptionFailed,
  getSubscriptionSuccess,
  getBillingInfo,
  getBillingInfoFailed,
  getBillingInfoSuccess,
  setUser,
  setBillingInfo,
  setBillingInfoFailed,
  setBillingInfoSuccess,
  subscribe,
  subscribeFailed,
  subscribeSuccess,
  setPricing,
  logoutUser,
  cancelSubscription,
  cancelSubscriptionFailed,
  cancelSubscriptionSuccess,
  inviteByEmail,
  inviteByEmailFailed,
  inviteByEmailSuccess,
  getOrgUsers,
  getOrgUsersFailed,
  getOrgUsersSuccess
} = createActions({
  GET_SUBSCRIPTION: () => null,
  GET_SUBSCRIPTION_FAILED: () => null,
  GET_SUBSCRIPTION_SUCCESS: ({ activeSub } = {}) => ({ activeSub }),
  GET_BILLING_INFO: () => null,
  GET_BILLING_INFO_FAILED: () => null,
  GET_BILLING_INFO_SUCCESS: ({ billing, card } = {}) => ({
    billing,
    card
  }),
  SET_USER: ({ user } = {}) => ({
    user
  }),
  SET_BILLING_INFO: data => data,
  SET_BILLING_INFO_FAILED: () => null,
  SET_BILLING_INFO_SUCCESS: ({ billing } = {}) => ({ billing }),
  SUBSCRIBE: data => data,
  SUBSCRIBE_FAILED: () => null,
  SUBSCRIBE_SUCCESS: () => null,
  SET_PRICING: ({ pricing } = {}) => ({ pricing }),
  LOGOUT_USER: () => null,
  CANCEL_SUBSCRIPTION: () => null,
  CANCEL_SUBSCRIPTION_FAILED: () => null,
  CANCEL_SUBSCRIPTION_SUCCESS: () => null,
  // eslint-disable-next-line camelcase
  INVITE_BY_EMAIL: ({ email, name, contact_no } = {}) => ({
    email,
    name,
    contact_no
  }),
  INVITE_BY_EMAIL_FAILED: () => null,
  INVITE_BY_EMAIL_SUCCESS: () => null,
  GET_ORG_USERS: () => null,
  GET_ORG_USERS_FAILED: () => null,
  GET_ORG_USERS_SUCCESS: data => data
});

export {
  getSubscription,
  getSubscriptionFailed,
  getSubscriptionSuccess,
  getBillingInfo,
  getBillingInfoFailed,
  getBillingInfoSuccess,
  setUser,
  setBillingInfo,
  setBillingInfoFailed,
  setBillingInfoSuccess,
  subscribe,
  subscribeFailed,
  subscribeSuccess,
  setPricing,
  logoutUser,
  cancelSubscription,
  cancelSubscriptionFailed,
  cancelSubscriptionSuccess,
  inviteByEmail,
  inviteByEmailFailed,
  inviteByEmailSuccess,
  getOrgUsers,
  getOrgUsersFailed,
  getOrgUsersSuccess
};
