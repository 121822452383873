/* eslint import/prefer-default-export : off */
import styled from "styled-components";
import { Col } from "react-grid-system";

export const HeaderContainer = styled.header`
  padding: 0;
  font-size: 14px;
  height: 75px;
  display: flex;
  align-items: center;

  div:first-child {
    flex: 1;
  }
`;

export const RightContainer = styled(Col)`
  text-align: right;

  svg {
    padding: 10px;
    margin-right: -10px;
    cursor: pointer;
    opacity: 1;
    transition: opacity 275ms;

    &:hover {
      opacity: 0.7;
    }
  }
`;
