import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Hidden, ScreenClassRender } from "react-grid-system";
import {
  NavigationContainer,
  Content,
  LinksContainer,
  TabLink,
  ExternalLink
} from "./styledElements";

class Navigation extends React.PureComponent {
  state = {
    sticky: false
  };

  container = React.createRef();

  componentDidMount = () => {
    const header = document.getElementsByClassName(
      this.container.current.props.className
    )[0];

    if (
      "IntersectionObserver" in window &&
      "IntersectionObserverEntry" in window &&
      "intersectionRatio" in window.IntersectionObserverEntry.prototype
    ) {
      const observer = new IntersectionObserver(entries => {
        header.classList.toggle("sticky", entries[0].boundingClientRect.y < 0);

        this.setState({
          sticky: entries[0].boundingClientRect.y < 0
        });
      });

      observer.observe(document.getElementById("navigation-ref"));
    }
  };

  render() {
    const { sticky } = this.state;

    return (
      <ScreenClassRender
        render={screen => (
          <NavigationContainer ref={this.container} sticky={sticky ? 1 : 0}>
            <Content sticky={sticky ? 1 : 0}>
              <Row nogutter>
                <Col sm={10} xs={10}>
                  <LinksContainer screen={screen}>
                    <TabLink exact to="/dashboard">
                      <FontAwesomeIcon icon="tachometer-alt" />
                      <Hidden xs sm>
                        <span>Dashboard</span>
                      </Hidden>
                    </TabLink>
                    <TabLink exact to="/dashboard/history">
                      <FontAwesomeIcon icon="inbox" />
                      <Hidden xs sm>
                        <span>History</span>
                      </Hidden>
                    </TabLink>
                    <TabLink exact to="/dashboard/account">
                      <FontAwesomeIcon icon="user" />
                      <Hidden xs sm>
                        <span>Account</span>
                      </Hidden>
                    </TabLink>
                  </LinksContainer>
                </Col>
                <Col sm={2} xs={2}>
                  <LinksContainer justify="end" screen={screen}>
                    <ExternalLink href="/docs">
                      <FontAwesomeIcon icon="file-contract" />
                      <Hidden xs sm>
                        <span>Documentation</span>
                      </Hidden>
                    </ExternalLink>
                  </LinksContainer>
                </Col>
              </Row>
            </Content>
          </NavigationContainer>
        )}
      />
    );
  }
}

export default Navigation;
