import styled from "styled-components";

import { Col } from "react-grid-system";

import { CardContainer } from "components";
import COLORS from "constants/colors";

export const StatsContainer = styled.div`
  padding: 32px 0;
`;

export const OverviewCardContainer = styled(Col).attrs({
  xl: 8
})`
  ${props => `
    margin-bottom: ${!["xl"].includes(props.screen) ? "60px" : "0"};
  `}
`;

export const OverviewCard = styled(CardContainer)``;

export const InfoCard = styled(CardContainer)`
  color: ${COLORS.white};
  position: relative;
  overflow: hidden;
  background: ${COLORS.mainFg};
  // padding-bottom: 85px;
  overflow: hidden;

  /* .card-title {
   *   color: rgb(255, 255, 255);
   * }
   */

  .fire-icon {
    font-size: 240px;
    position: absolute;
    bottom: 16px;
    right: 16px;
    z-index: 0;
    opacity: 0.05;
  }
`;

export const StatItem = styled.div`
  position: relative;
  overflow: hidden;
  text-align: center;
  padding: 0 0 24px;
  border-radius: 14px;
  border-top-left-radius: ${props => (props.loading ? "14px" : "0")};
  border-top-right-radius: ${props => (props.loading ? "14px" : "0")};
  box-shadow: 0 10px 35px -10px rgba(0,0,0,0.175);
  // transform: translateY(60px);
  background-color: rgb(255, 255, 255);
  // margin-top: ${props =>
    ["md", "xs", "sm"].includes(props.screen) ? "24px" : "0"};

  .value {
    font-size: 28px;
    margin-top: 16px;
    margin-bottom: 8px;

    &.logs {
      color: #0189b6;
    }

    &.errors {
      color: #cc0000;
    }

    &.warnings {
      color: #de8614;
    }
  }

  .label {
    font-size: 14px;
    opacity: 0.8;
  }
`;

export const InfoItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  font-size: 14px;
  border-bottom: ${props =>
    !props.noborder ? "1px solid rgba(255, 255, 255, 0.15)" : "none"};

  .value {
    font-weight: bold;
  }

  .label {
    font-weight: normal;
  }
`;

export const OrganizationContainer = styled.div`
  margin-top: 24px;
`;

export const RecentTotal = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 30px;
  position: absolute;
  width: calc(100% - 60px);
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.15);

  .value {
    font-weight: bold;
  }

  .label {
    font-weight: normal;
  }
`;
