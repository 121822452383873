import React from "react";
import COLORS from "constants/colors";

import LOG_LEVELS, { LOG_LEVELS_COLORS } from "constants/logLevels";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "components";
import {
  BrandImg,
  HeaderSectionContainer,
  NavbarContainer,
  Logo,
  NavbarRight,
  NavbarList,
  Content,
  DocsLink,
  Headline,
  Subline,
  ButtonContainer,
  Link,
  HashLink,
  NavbarLeft,
  DevicesContainer
} from "./styledElements";

const brandImg = require("assets/branding-dark.png");

const HeaderSection = () => (
  <HeaderSectionContainer>
    <FontAwesomeIcon
      icon="fire"
      color={COLORS.brandOrange}
      style={{
        position: "absolute",
        bottom: 0,
        right: 70,
        fontSize: 700,
        opacity: 0.075
      }}
    />
    <NavbarContainer>
      <NavbarLeft>
        <Logo to="/">
          <BrandImg src={brandImg} />
        </Logo>
        <NavbarList>
          <li>
            <HashLink to="/#product">
              <span>Product</span>
            </HashLink>
          </li>
          <li>
            <HashLink to="/#logging">
              <span>Developers</span>
            </HashLink>
          </li>
          <li>
            <HashLink to="/#pricing">
              <span>Pricing</span>
            </HashLink>
          </li>
        </NavbarList>
      </NavbarLeft>
      <NavbarRight>
        <NavbarList>
          <li>
            <Link to="/account/signup">
              <Button
                theme={{
                  background: COLORS.brandOrange,
                  color: COLORS.white
                }}
              >
                <span>Sign up now!</span>
                <FontAwesomeIcon icon="pen" />
              </Button>
            </Link>
          </li>
          <li>
            <Link to="/account/login">
              <span>Login</span>
            </Link>
          </li>
        </NavbarList>
      </NavbarRight>
    </NavbarContainer>
    <Content>
      <DevicesContainer>
        <FontAwesomeIcon
          color={LOG_LEVELS_COLORS[LOG_LEVELS.DEFAULT]}
          icon="mobile-alt"
        />
        <FontAwesomeIcon
          icon="desktop"
          color={LOG_LEVELS_COLORS[LOG_LEVELS.WARNING]}
        />
        <FontAwesomeIcon
          icon="tablet-alt"
          color={LOG_LEVELS_COLORS[LOG_LEVELS.ERROR]}
        />
      </DevicesContainer>
      <Headline>
        Full stack stability monitoring and logging for your apps
      </Headline>
      <Subline>Easy, simple logging for smart development teams.</Subline>
      <ButtonContainer>
        <Link to="/account/signup">
          <Button
            theme={{
              background: COLORS.brandOrange,
              color: COLORS.white
            }}
          >
            Get started
          </Button>
        </Link>
        <DocsLink to="/docs">
          Read the docs
          <FontAwesomeIcon icon="arrow-right" />
        </DocsLink>
      </ButtonContainer>
    </Content>
  </HeaderSectionContainer>
);

export default HeaderSection;
