import React from "react";

import { Elements } from "react-stripe-elements";
import { Container, Row, Visible } from "react-grid-system";
import {
  AccountContainer,
  VerticalDivider,
  SidebarContainer,
  SettingsContainer
} from "./styledElements";
import { Billing, Tokens, Settings, Sidebar, Team } from "./components";

const Account = () => (
  <AccountContainer>
    <Container>
      <Row>
        <SidebarContainer xl={3} lg={3} md={3}>
          <Sidebar />
          <Visible sm xs>
            <VerticalDivider />
          </Visible>
        </SidebarContainer>
        <SettingsContainer xl={9} lg={9} md={9}>
          <Elements>
            <Billing />
          </Elements>
          <Tokens />
          <Settings />
          <Team />
        </SettingsContainer>
      </Row>
    </Container>
  </AccountContainer>
);

export default Account;
