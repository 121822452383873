import { handleActions, combineActions } from "redux-actions";
import {
  fetchStats,
  fetchStatsSuccess,
  fetchStatsFailed,
  loadStats
} from "./actions";

const DEFAULT_STATE = {
  data: {
    overview: {},
    count: {}
  },
  isFetching: false
};

export default handleActions(
  {
    [fetchStats]: state => ({
      ...state,
      isFetching: true
    }),
    [combineActions(fetchStatsSuccess, fetchStatsFailed)]: state => ({
      ...state,
      isFetching: false
    }),
    [loadStats]: (
      state,
      {
        payload: {
          data: { overview, count }
        }
      }
    ) => ({
      ...state,
      data: {
        overview,
        count
      }
    })
  },
  DEFAULT_STATE
);
