import { set, get, unset, clear } from "./storage";

const storeAuthTokens = ({ idToken } = {}) => {
  try {
    set("idToken", idToken);
    return true;
  } catch (err) {
    return err;
  }
};

const clearAuthTokens = () => {
  try {
    unset("idToken");
    return true;
  } catch (err) {
    return err;
  }
};

const getIdToken = () => {
  try {
    const idToken = get("idToken");
    return idToken;
  } catch (err) {
    return null;
  }
};

const setOnboardingStatus = status => {
  try {
    set("onboardingStatus", status);
    return true;
  } catch (err) {
    return err;
  }
};

const getOnboardingStatus = () => {
  try {
    const status = get("onboardingStatus");
    return status;
  } catch (err) {
    return null;
  }
};

const setBilling = billing => {
  try {
    set("billing", JSON.stringify(billing));
    return true;
  } catch (err) {
    return null;
  }
};

const getBilling = () => {
  try {
    const billing = get("billing");
    return JSON.parse(billing);
  } catch (err) {
    return null;
  }
};

const setSubscription = subscription => {
  try {
    set("subscription", JSON.stringify(subscription));
    return true;
  } catch (err) {
    return null;
  }
};

const getSubscription = () => {
  try {
    const subscription = get("subscription");
    return JSON.parse(subscription);
  } catch (err) {
    return null;
  }
};

const clearStorage = () => {
  try {
    unset("billing");
    unset("subscription");
    return true;
  } catch (err) {
    return err;
  }
};

export {
  set,
  get,
  unset,
  clear,
  storeAuthTokens,
  clearAuthTokens,
  getIdToken,
  setOnboardingStatus,
  getOnboardingStatus,
  setBilling,
  getBilling,
  setSubscription,
  getSubscription,
  clearStorage
};
