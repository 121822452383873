/* eslint import/prefer-default-export : off */
import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: ${props => (props.loading ? "100%" : "0")};
  height: ${props => (props.loading ? "100%" : "0")};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(51, 51, 51, 0.15);
  overflow: hidden;
  font-weight: bold;
  font-size: 12px;

  svg {
    margin-bottom: 8px;
  }

  ${props => props.customstyles}
`;
