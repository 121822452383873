import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";

import COLORS from "constants/colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  align-items: center;
  padding-top: 96px;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  text-align: center;
  background-color: ${COLORS.mainBg};
`;

export const DashboardImage = styled.img`
  position: relative;
  border-radius: 8px;
  max-width: 900px;
  width: 100%;
  margin-top: 54px;
`;

export const ButtonContainer = styled.div`
  margin: 0;
  margin-top: 1.5rem;
  display: flex;
  align-items: center;

  @media screen and (max-width: 400px) {
    flex-direction: column;
  }

  @media screen and (max-width: 800px) {
    justify-content: center;
  }
`;

export const DocsLink = styled(NavLink)`
  text-decoration: none;
  color: ${COLORS.mainFg};
  margin-left: 30px;

  &:hover svg {
    transition: all 0.2s ease-out;
    transform: translateX(5px);
  }
`;

export const ListIcon = styled(FontAwesomeIcon).attrs({
  icon: "align-right",
  size: "9x"
})`
  position: absolute;
  top: 200px;
  right: -20px;
  opacity: 0.075;
`;

export const CircleIcon = styled(FontAwesomeIcon).attrs({
  icon: "circle-notch",
  size: "9x"
})`
  position: absolute;
  top: -50px;
  left: -50px;
  opacity: 0.075;
`;

export const ChartIcon = styled(FontAwesomeIcon).attrs({
  icon: "chart-area",
  size: "9x"
})`
  position: absolute;
  bottom: -20px;
  left: -50px;
  opacity: 0.075;
`;
