import React from "react";
import PropTypes from "prop-types";

// eslint-disable-next-line import/no-cycle
import { steps } from "../../config";
import { Container, Step, Number, StepTitle } from "./styledElements";

const Steps = ({ currentStep }) => (
  <Container>
    {steps.map((step, index) => (
      <Step
        key={step.title}
        inactive={index > currentStep}
        done={index < currentStep}
      >
        <Number done={index < currentStep} active={currentStep === index}>
          {index + 1}
        </Number>
        <StepTitle>{step.title}</StepTitle>
      </Step>
    ))}
  </Container>
);

Steps.propTypes = {
  currentStep: PropTypes.number.isRequired
};

export default Steps;
