import styled from "styled-components";
import LOG_LEVELS, { LOG_LEVELS_BG_COLORS } from "constants/logLevels";
import COLORS from "constants/colors";
import { Col } from "react-grid-system";
import { NavLink } from "react-router-dom";

export const SectionContainer = styled.div`
  padding: 96px 0;
  position: relative;
`;

export const SectionTitle = styled.h4`
  font-size: 14px;
  margin: 24px 0 12px;
`;

export const LogArtContainer = styled(Col).attrs({
  xl: 6,
  lg: 6
})`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  .mobile-icon,
  .desktop-icon,
  .tablet-icon {
    opacity: 0.075;
  }

  .mobile-icon,
  .tablet-icon {
    margin-bottom: -40px;
  }

  .desktop-icon {
    margin: 0 8px;
  }
`;

export const LogLevelsContainer = styled.div`
  position: absolute;
  top: 100px;
  right: 80px;

  svg {
    font-size: 10px;
    margin: 0 2px;
    opacity: 0.5;
  }
`;

export const InfoLog = styled.div`
  position: absolute;
  left: 60px;
  top: 150px;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 10px 35px -10px rgba(0, 0, 0, 0.275);
  background-color: ${LOG_LEVELS_BG_COLORS[LOG_LEVELS.DEFAULT]};
  color: ${COLORS.mainFg};
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    margin-right: 8px;
  }
`;

export const WarningLog = styled(InfoLog)`
  left: 100px;
  top: 40px;
  transform: scale(0.75);
  background-color: ${LOG_LEVELS_BG_COLORS[LOG_LEVELS.WARNING]};
`;

export const ErrorLog = styled(InfoLog)`
  left: 150px;
  top: 260px;
  transform: scale(0.75);
  background-color: ${LOG_LEVELS_BG_COLORS[LOG_LEVELS.ERROR]};
`;

export const DocsLink = styled(NavLink).attrs({
  to: "/docs"
})`
  color: ${COLORS.brandOrange};
  font-size: 12px;
  font-weight: bold;
  margin-top: 36px;
  display: inline-block;
  text-decoration: none;
  transition: opacity 275ms;

  svg {
    margin-right: 8px;
  }

  &:hover {
    opacity: 0.7;
  }
`;

export const IntegrationContainer = styled.div`
  padding: 54px 0;
  background-color: ${COLORS.mainBg};
  box-shadow: inset 4px 4px 15px -8px rgba(0, 0, 0, 0.275);
  text-align: center;

  h4 {
    opacity: 0.5;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 36px;
  }

  svg {
    font-size: 69px;
    margin: 0 12px;
  }
`;

export const IconImg = styled.img`
  height: 69px;
  width: auto;
`;
