import styled from "styled-components";

export const Container = styled.div`
  position: sticky;
  top: 0;
  z-index: 2;
  padding: 1rem;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: ${props => props.background};
  color: ${props => props.color};
  font-size: 0.9rem;

  ${props => props.style}
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const CloseButton = styled.div`
  flex: 0;
  color: ${props => props.color};
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;
