import styled from "styled-components";
import COLORS from "constants/colors";
import { Button } from "components";

import { Row } from "react-grid-system";

export const ApplicationsContainer = styled(Row)`
  margin: 0 -16px;
  color: ${COLORS.mainFg};
`;

export const ApplicationsTitle = styled.div`
  font-size: 18px;
  margin-bottom: 12px;
  color: inherit;
`;

export const ApplicationContainer = styled(Button)`
  padding: 16px;
  margin: 0 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #dddddd;
  color: ${COLORS.mainFg};

  ${props =>
    props.selected &&
    `
    color: white;
    background-color: ${props.background || "#37C2D9"};
    border-color: ${props.background || "#37C2D9"};
  `}

  ${props =>
    props.disabled &&
    `
    background-color: rgba(0, 0, 0, 0.15);
    border-color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  `}

  ${props =>
    !props.disabled &&
    `
  &:hover {
    opacity: 0.7;
  }`}

  svg {
    margin: 0;
  }
`;

export const ApplicationName = styled.div`
  font-size: 0.9rem;
  text-align: center;
  margin-left: 0.5rem;
  color: ${props => (props.selected ? "#FFFFFF" : COLORS.color)};

  ${props => props.disabled && `color: rgba(0, 0, 0, 0.7);`}
`;

export const IconsContainer = styled.div`
  margin-bottom: 12px;
  position: relative;

  svg {
    margin: 0 4px;
  }
`;

export const BrandContainer = styled.div`
  ${props => `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  margin-bottom: 12px;
  background-color: ${props.background || "#37C2D9"};

    ${(props.selected || props.disabled) &&
      `
      background-color: ${COLORS.brandOrange};
    `}

  `}
`;

export const IconImg = styled.img`
  height: 1rem;
  width: auto;
  border-radius: 100%;
`;

// export const TagsContainer = styled.div`
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   margin: 0 -8px;
//   margin-bottom: 24px;
//   margin-top: -12px;
// `;

// export const Tag = styled.div`
//   padding: 0 24px;
//   padding-right: 0;
//   height: 40px;
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   justify-content: space-between;
//   border-radius: 4px;
//   background-color: ${props =>
//     props.selected ? COLORS.primary : COLORS.mainBg};
//   color: ${props => (props.selected ? COLORS.white : COLORS.mainFg)};
//   font-size: 0.9rem;
//   margin: 8px;
//   overflow: hidden;

//   svg {
//     cursor: pointer;
//     padding: 0 12px;
//     height: 100%;
//     background-color: rgba(0, 0, 0, 0.05);
//     transition: opacity 275ms;

//     &:hover {
//       opacity: 0.8;
//     }
//   }
// `;
