import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { Container, Row, Col } from "react-grid-system";
import { Loader } from "components";

import { fetchLogs } from "redux/modules/logs/actions";

import {
  HistoryContainer,
  BreakdownDescription,
  BreakdownTitle,
  LogsContainer,
  Content
} from "./styledElements";
import { DeviceInfo, Filters, LogInfo, LogsList } from "./components";

import CardTitle from "../CardTitle";

class History extends React.Component {
  state = {
    selectedLog: null
  };

  componentDidMount = () => {
    const { onFetchLogs } = this.props;

    onFetchLogs();
  };

  onSelectLog = selectedLog => {
    this.setState({
      selectedLog
    });

    return selectedLog;
  };

  render() {
    const { selectedLog } = this.state;
    const { logs, isFetching } = this.props;

    return (
      <HistoryContainer>
        <Container>
          <LogsContainer>
            <Row nogutter>
              <Col xl={12} lg={12} md={12}>
                <Content bordered>
                  <Row nogutter justify="between">
                    <Col>
                      <CardTitle>Logs History</CardTitle>
                    </Col>
                    <Col>
                      <Filters />
                    </Col>
                  </Row>
                  <LogsList
                    data={[{}, ...logs, {}]}
                    onSelectLog={this.onSelectLog}
                  />
                  <Loader loading={isFetching} text="Fetching logs..." />
                </Content>
              </Col>
              <Col id="info-container">
                <Content>
                  <CardTitle>More Details of Selected Log</CardTitle>
                  {!selectedLog && (
                    <BreakdownDescription>
                      Select first a specific log to display its details.
                    </BreakdownDescription>
                  )}
                  {selectedLog && (
                    <>
                      <BreakdownTitle>LOG DETAILS</BreakdownTitle>
                      <BreakdownDescription>
                        Below are more details about the log selected.
                      </BreakdownDescription>
                      <DeviceInfo log={selectedLog} />
                      <LogInfo log={selectedLog} />
                    </>
                  )}
                  <Loader text="Fetching log details..." />
                </Content>
              </Col>
            </Row>
          </LogsContainer>
        </Container>
      </HistoryContainer>
    );
  }
}

History.propTypes = {
  logs: PropTypes.instanceOf(Array),
  onFetchLogs: PropTypes.func.isRequired,
  isFetching: PropTypes.bool
};

History.defaultProps = {
  logs: [],
  isFetching: false
};

export default connect(
  state => ({
    logs: state.logs.data,
    isFetching: state.logs.isFetching
  }),
  {
    onFetchLogs: fetchLogs
  }
)(History);
