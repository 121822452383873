import styled from "styled-components";

// eslint-disable-next-line import/prefer-default-export
export const BillingFormContainer = styled.div`
  width: 100%;
  height: 100%;

  .StripeElement {
    appearance: none;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    outline: none;
    width: 100%;
    padding: 12px;
    box-sizing: border-box;
    background-color: transparent;
    z-index: 1;
    margin-bottom: 24px;
  }
`;

export const BillingValue = styled.div`
  opacity: 0.7;
  margin-bottom: 12px;
  font-size: 14px;
`;
