/* eslint-disable no-return-assign */
import React from "react";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import decode from "jwt-decode";

import { requestResetPassword } from "services/data.services";
import SUCCESS_MESSAGES from "constants/success";
import { getIdToken } from "services/storage.services";
import {
  SettingsCard,
  SettingContainer,
  SettingTitle,
  SettingDescription,
  Button,
  ApiKeyContainer,
  ClipboardButton
} from "./styledElements";

import CardTitle from "../../../CardTitle";

class Settings extends React.PureComponent {
  state = {
    isRequestingReset: false,
    apiKeyCopied: false
  };

  apiKeyRef = React.createRef();

  emailRef = React.createRef();

  onCopyToClipboard = e => {
    this.apiKeyRef.current.select();
    document.execCommand("copy");
    e.target.focus();

    this.setState(state => ({
      ...state,
      apiKeyCopied: true
    }));
  };

  onChangeEmail = event => {
    event.preventDefault();

    const email = this.emailRef.current.value;
    console.log(email);
  };

  onForgotPassword = async () => {
    const { email } = decode(getIdToken());
    this.setState({ isRequestingReset: true });
    const { error } = await requestResetPassword({ email });

    if (error) toast.error(error);
    else toast.success(SUCCESS_MESSAGES.RESET_PASSWORD_EMAIL_SUCCESS);

    this.setState({ isRequestingReset: false });
  };

  onDeactivateAccount = () => {};

  render() {
    const { isRequestingReset, apiKeyCopied } = this.state;
    return (
      <SettingsCard id="settings">
        <CardTitle>Account Settings</CardTitle>
        <SettingContainer id="usertoken">
          <SettingTitle>User Token</SettingTitle>
          <SettingDescription>
            This is your user token. Do not share your user token with anyone.
          </SettingDescription>
          <ApiKeyContainer>
            <textarea readOnly ref={this.apiKeyRef}>
              {getIdToken()}
            </textarea>
            <ClipboardButton onClick={this.onCopyToClipboard}>
              {apiKeyCopied ? (
                <FontAwesomeIcon icon="clipboard-check" />
              ) : (
                <FontAwesomeIcon icon="clipboard" />
              )}
            </ClipboardButton>
          </ApiKeyContainer>
        </SettingContainer>
        {/* <SettingContainer>
          <SettingTitle>Primary Email</SettingTitle>
          <SettingDescription>
            Make sure your email is valid and active for verification.
          </SettingDescription>
          <form onSubmit={this.onChangeEmail}>
            <input ref={this.emailRef} name="email" type="email" />
            <Button type="submit">Change Email</Button>
          </form>
        </SettingContainer> */}
        <SettingContainer id="changepassword">
          <SettingTitle>Change Password</SettingTitle>
          <SettingDescription>
            We&apos;ll send you an email with a link to reset your password.
          </SettingDescription>
          <Button
            type="button"
            onClick={this.onForgotPassword}
            loading={isRequestingReset}
          >
            Reset Password
          </Button>
        </SettingContainer>
        {/* <SettingContainer>
          <SettingTitle>Deactivate Account</SettingTitle>
          <SettingDescription>
            Please make sure to be certain before proceeding this action.
          </SettingDescription>
          <Button
            className="danger"
            type="button"
            onClick={this.onDeactivateAccount}
          >
            Deactivate Account
          </Button>
        </SettingContainer> */}
      </SettingsCard>
    );
  }
}

export default Settings;
