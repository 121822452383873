import React from "react";
import PropTypes from "prop-types";

import { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container } from "./styledElements";

const Loader = ({ containerStyle, loading, text }) => (
  <Container customstyles={containerStyle} loading={loading ? 1 : 0}>
    <FontAwesomeIcon icon="fan" spin />
    {text !== "" && <div>{text}</div>}
  </Container>
);

Loader.propTypes = {
  loading: PropTypes.bool,
  text: PropTypes.string,
  containerStyle: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.instanceOf(css)
  ])
};

Loader.defaultProps = {
  loading: false,
  text: "Loading...",
  containerStyle: {}
};

export default Loader;
