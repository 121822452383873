import styled from "styled-components";

import { Row, Col } from "react-grid-system";

export const ListContainer = styled.div`
  margin-top: 24px;
  max-width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  & > div:first-of-type,
  & > div:nth-of-type(2) {
    min-width: 1100px;
  }
`;

export const LogContainer = styled(Row).attrs(() => ({
  align: "center"
}))`
  cursor: pointer;
  height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  width: 100%;

  & > div:nth-of-type(3),
  & > div:nth-of-type(4) {
    text-align: right;
  }
`;

export const HeaderContainer = styled(Row)`
  border-top: 1px solid rgb(0, 0, 0, 0.15);
  border-bottom: 1px solid rgb(0, 0, 0, 0.15);
  padding: 16px 0;
  font-size: 12px;
  font-weight: bold;
  width: 100%;
  background-color: rgb(255, 255, 255);

  & > div:nth-of-type(3),
  & > div:nth-of-type(4) {
    text-align: right;
  }
`;

export const LoaderContainer = styled.div`
  padding: 8px 0;
  font-size: 12px;
  opacity: 0.7;
  text-align: center;

  svg {
    margin-bottom: 5px;
  }
`;

export const MessageCol = styled(Col).attrs({
  xl: 7,
  lg: 7,
  md: 7,
  sm: 7,
  xs: 7
})`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
