/* eslint-disable camelcase */
import { takeEvery, put, all, call } from "redux-saga/effects";
import { toast } from "react-toastify";
import TagManager from "react-gtm-module";
import decode from "jwt-decode";

import * as DataServices from "services/data.services";
import { storeAuthTokens } from "services/storage.services";
import { setUser } from "redux/modules/account/actions";
import ERROR_MESSAGES from "constants/errors";
import { createAccountSuccess, createAccountFailed } from "./actions";

export function* onCreateAccountAsync({ payload }) {
  const { email, name, company, password } = payload;

  /*
   * Trigger a GTM event during a signup attempt.
   * Later on, trigger an event for failures and
   * successes.
   */

  TagManager.dataLayer({
    dataLayer: {
      event: `registerAttempt_${name}`
    },
    dataLayerName: "reactAppDataLayer"
  });

  try {
    const response = yield call(DataServices.createAccount, {
      email,
      name,
      company,
      password
    });

    if (response.error) {
      toast.error(response.error);
      yield put(createAccountFailed());

      TagManager.dataLayer({
        dataLayer: {
          event: `registerFailed_${name}`
        },
        dataLayerName: "reactAppDataLayer"
      });
    } else {
      TagManager.dataLayer({
        dataLayer: {
          event: `newUser_${name}`
        },
        dataLayerName: "reactAppDataLayer"
      });

      // Log the user in
      const [error, res] = yield call(DataServices.login, {
        email,
        password
      });

      if (error) {
        toast.error(ERROR_MESSAGES.UNKNOWN);
        return;
      }

      const { idToken } = res;
      storeAuthTokens({ idToken });

      const user = decode(idToken);

      if (user) {
        yield put(
          setUser({
            user: {
              id: user.sub,
              organization:
                user["https://kalayo.io/claims"] &&
                user["https://kalayo.io/claims"].organization,
              profile: {
                name: user.name,
                picture: user.picture,
                email: user.email
              }
            }
          })
        );
      }

      toast.success("You have successfully registered.");
      yield put(createAccountSuccess());
    }
  } catch (e) {
    console.log(e);
    toast.error(ERROR_MESSAGES.UNKNOWN);
    yield put(createAccountFailed());

    TagManager.dataLayer({
      dataLayer: {
        event: `registerFailed_${name}`
      },
      dataLayerName: "reactAppDataLayer"
    });
  }
}

function* watchCreateAccountAsync() {
  yield takeEvery("CREATE_ACCOUNT", onCreateAccountAsync);
}

export default function* rootSaga() {
  yield all([watchCreateAccountAsync()]);
}
