import styled from "styled-components";
import { Row, Col } from "react-grid-system";
import { Button, Link } from "components";
import COLORS from "../../colors";

export const Container = styled(Row)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0 !important;
`;

export const Content = styled(Col)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 36px !important;
  justify-content: space-between;
  height: 76px;
  background-color: #ffffff;
  box-shadow: 0 3px 10px 5px rgba(0, 0, 0, 0.05);
`;

export const SkipLink = styled(Link)`
  color: gray;
  font-size: 0.85rem;
  text-transform: uppercase;
  cursor: pointer;
`;

export const BackButton = styled(Button)`
  color: gray;
  font-size: 0.85rem;
  text-transform: uppercase;
  transition: opacity 275ms;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  svg {
    margin: 2px 6px 0 0;
  }

  &:hover {
    opacity: 0.7;
  }
`;

export const ContinueButton = styled(BackButton)`
  background-color: ${COLORS.next};
  color: white;
  margin-left: 24px;

  svg {
    margin-left: 6px;
    margin-right: 0;
  }
`;
