import styled from "styled-components";
import COLORS from "constants/colors";

import { Row } from "react-grid-system";

import { NavbarContainer } from "../LandingPage/components/HeaderSection/styledElements";

export const LoginContainer = styled.div`
  background-color: ${COLORS.mainBg};
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  padding: 2.5% 5%;
`;

export const Navbar = styled(NavbarContainer)`
  position: absolute;
  width: 100%;
  margin: 0 auto;
`;

export const FormContainer = styled(Row).attrs({
  align: "center",
  justify: "center"
})`
  padding: 36px 0;
  height: calc(100vh - 72px);
`;
