/* eslint import/prefer-default-export : off */
import styled from "styled-components";
import { Row } from "react-grid-system";
import { Button } from "components";
import COLORS from "constants/colors";

export const Link = styled.a`
  color: ${COLORS.primary};
  text-decoration: none;
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  margin: 12px 0;
  display: inline-block;

  svg {
    margin: 0 8px;
  }
`;

export const InstructionsContainer = styled.div`
  margin-top: 12px;

  div {
    margin: 12px 0;
  }
`;

export const LinkContainer = styled(Row)`
  margin: 0 !important;
`;

export const DashboardButton = styled(Button)`
  width: auto;
  background: ${COLORS.brandOrange};
  color: white;
  margin-right: 24px;
  font-size: 0.85rem;
  text-transform: uppercase;
  transition: opacity 275ms;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
