import { serializeObjectQuery, formatLogLevels } from "utils/general/api";
import { createAuthApi } from "../createApi";

export const fetchAllLogs = async () =>
  createAuthApi("/v1/logs")({
    method: "GET"
  });

export const fetchLogs = async ({
  offset = 0,
  limit = 20,
  deviceType,
  deviceVersion,
  os,
  osVersion,
  logLevels,
  searchQuery
} = {}) => {
  const query = {
    offset,
    limit,
    deviceType,
    deviceVersion,
    os,
    osVersion,
    logLevels: formatLogLevels(logLevels),
    msgFilter: searchQuery
  };

  return createAuthApi(`/v1/logs-history${serializeObjectQuery(query)}`)({
    method: "GET"
  });
};

export const fetchMoreLogs = async ({
  offset = 0,
  limit = 20,
  deviceType,
  deviceVersion,
  os,
  osVersion,
  logLevels,
  searchQuery
} = {}) => {
  const query = {
    offset,
    limit,
    deviceType,
    deviceVersion,
    os,
    osVersion,
    logLevels: formatLogLevels(logLevels),
    msgFilter: searchQuery
  };

  return createAuthApi(`/v1/logs-history${serializeObjectQuery(query)}`)({
    method: "GET"
  });
};

export const fetchLog = async id =>
  createAuthApi(`/log/${id}`)({
    method: "GET"
  });
