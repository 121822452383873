import React from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Label } from "./styledElements";

const Select = React.forwardRef(
  ({ selectRef, options, label, onChange, value }) => (
    <div>
      <Label>{label}</Label>
      <Container>
        <FontAwesomeIcon icon="caret-down" />
        <select onChange={onChange} ref={selectRef} value={value}>
          {options.map(option => (
            <option value={option.value}>{option.label}</option>
          ))}
        </select>
      </Container>
    </div>
  )
);

Select.propTypes = {
  selectRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(React.Element) })
  ]),
  options: PropTypes.instanceOf(Array),
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string
};

Select.defaultProps = {
  selectRef: {},
  options: [],
  label: null,
  onChange: null,
  value: null
};

export default Select;
