import { createActions } from "redux-actions";

const {
  fetchStats,
  fetchStatsSuccess,
  fetchStatsFailed,
  loadStats
} = createActions({
  FETCH_STATS: () => null,
  FETCH_STATS_SUCCESS: () => null,
  FETCH_STATS_FAILED: () => null,
  LOAD_STATS: data => ({
    data
  })
});

export { fetchStats, fetchStatsSuccess, fetchStatsFailed, loadStats };
