import React from "react";
import MarkdownRenderer from "react-markdown";

import { Brand } from "components";
import { Container, BrandContainer } from "./styledElements";

const text = `
# Terms of Service

The following terms and conditions govern all use of the Kalayo website 
and all content, services, and products available from Kalayo 
(taken together, the Service).

The Service is owned and operated by Bathala Associates Organization, Inc. 
(“AMAGI”).

The Service is offered subject to your acceptance without modification of 
all of the terms and conditions contained herein and all other operating 
rules, policies (including, without limitation, AMAGI’s Privacy Policy) and 
Data Processing Addendum and procedures that may be published from time to 
time on our website located at https://kalayo.io/ (the “Site”) by AMAGI, 
(collectively, the “Agreement”).

Please read this Agreement carefully before accessing or using the Service. 
By accessing or using any part of the Site or the Service, you agree to 
become bound by the terms and conditions of this agreement. If you do not 
agree to all the terms and conditions of this Agreement, then you may not 
access the Service. If these terms and conditions are considered an offer by 
AMAGI, acceptance is expressly limited to these terms. If you are accessing 
and using the Service on behalf of a company (such as your employer) or other 
legal entity, you represent and warrant that you have the authority to bind 
that company or other legal entity to this Agreement. In that case, “you” and 
“your” will refer to that company or other legal entity.

1. Your Kalayo Account
- You must be 13 years or older to use this Service.
- You are responsible for maintaining the security of your Account and password.
- You must immediately notify AMAGI of any unauthorized uses of your Account 
or any other breaches of security. AMAGI will not be liable for any acts or 
omissions by You, including any damages of any kind incurred as a result of 
such acts or omissions.
- You are fully responsible for all activities that occur under the Account and 
any other actions taken in connection with the Account.
- One person or legal entity may not maintain more than one Free Account.

2. Payment and Renewal
- Paid Plans are available on the Service. By selecting a Paid Plan you agree 
to pay AMAGI the monthly or annual subscription fees (“Subscription”), as well 
as any additional usage charges (“Usage”) indicated for that service.
- Payments will be automatically charged at the start of each billing period, 
unless otherwise agreed in writing.
- All paid plans must provide valid credit card details, unless alternative 
payment methods have been agreed in writing.
- All fees are exclusive of all taxes, levies, or duties imposed by taxing 
authorities, and you shall be responsible for payment of all such taxes, levies, 
or duties, excluding only Philippines (national or state) taxes.
- Downgrading your Service may cause the loss of content, features, or capacity 
of your Account. AMAGI does not accept any liability for such loss.
- Unless you notify AMAGI before the end of the applicable subscription period 
that you want to cancel a Paid Plan, your Paid Plan subscription will 
automatically renew and you authorize us to collect the then-applicable 
subscription fee using any credit card or other payment mechanism we have on 
record for you.
- Paid Plans can be canceled at any time in the “Plans” section of your Account’s 
dashboard. You will still be responsible for the payment of any Usage fees or 
charges incurred prior to the cancellation of service, as well as any 
Subscription fees owed.

3. Usage, Budgets and Rate-Limits
- Plans do not currently limit the number of users who can use Kalayo.
- A user is defined as any person who wishes to use the Kalayo dashboard 
or receive email notifications from Kalayo.
- Each user must maintain their own Kalayo user account, with their 
own access credentials.
- Kalayo will automatically measure and report your Usage for each billing period.
- It is expressly forbidden to tamper with, disable, or modify any Kalayo Usage 
tracking mechanisms.

4. Intellectual Property
- This Agreement does not transfer from AMAGI to you any Kalayo or third party 
intellectual property, and all right, title and interest in and to such 
property will remain solely with AMAGI.
- Kalayo, the Kalayo logo, and all other trademarks, service marks, graphics and 
logos used in connection with the Service are trademarks or registered trademarks 
of AMAGI or AMAGI’s licensors.
- Other trademarks, service marks, graphics and logos used in connection with 
the Service may be the trademarks of other third parties. Your use of the 
Service grants you no right or license to reproduce or otherwise use any AMAGI 
or third-party trademarks.
- We claim no intellectual property rights over the material you provide to the 
Service. Your profile and materials uploaded remain yours.
- By using Kalayo, you agree to allow us to use your name and/or logo in marketing 
materials about Kalayo. You can opt-out of this at any time by emailing us at 
kalayo@amagi.io.

5. Changes
- AMAGI reserves the right, at its sole discretion, to modify or replace any 
part of this Agreement or the Service.
- It is your responsibility to check this Agreement periodically for changes. 
- Your continued use of or access to the Service following the posting of any 
changes to this Agreement constitutes acceptance of those changes.
- AMAGI may also, in the future, offer new services and/or features through 
the Service (including, the release of new tools and resources). Such new 
features and/or services shall be subject to the terms and conditions of this 
Agreement.
- AMAGI reserves the right at any time and from time to time to modify or 
discontinue, temporarily or permanently, the Service (or any part thereof) 
with or without notice.
- Prices of all Services, including but not limited to monthly subscription 
fees to the Service, are subject to change upon 30 days notice from us. Such 
notice may be provided at any time by posting the changes to the Site or by 
contacting you by email.

6. Termination
- AMAGI may terminate your access to all or any part of the Service at any 
time, with or without cause, with or without notice, effective immediately. 
AMAGI will make a reasonable attempt via email to warn the Account owner prior 
to termination.
- Termination of Service may result in the deactivation or deletion of your 
Account or your access to your Account, and the forfeiture and relinquishment 
of all Content in your Account.
- If you wish to terminate this Agreement or your Kalayo Account (if you have 
one), you may simply discontinue using the Service. All provisions of this 
Agreement which by their nature should survive termination shall survive 
termination, including, without limitation, ownership provisions, warranty 
disclaimers, indemnity and limitations of liability.
- You can delete your Account at any time by clicking “Delete Account” on 
your “Account Settings” page in your dashboard. You will still be responsible 
for the payment of any Usage fees or charges incurred prior to the cancellation 
of service, as well as any Subscription fees owed.
- AMAGI shall not be liable to you, or to any third party, for any 
modification, price change, suspension or discontinuance of the Service.

7. Disclaimer of Warranties
- The Service is provided “as is”. AMAGI and its suppliers and licensors 
hereby disclaim all warranties of any kind, express or implied, including, 
without limitation, the warranties of merchantability, fitness for a particular 
purpose and non-infringement.
- Neither AMAGI nor its suppliers and licensors, makes any warranty that the 
Service will be error free or that access thereto will be continuous or 
uninterrupted.
- You understand that you download from, or otherwise obtain content or 
services through, the Service at your own discretion and risk.

8. Limitation of Liability
- In no event will AMAGI, or its suppliers or licensors, be liable with respect 
to any subject matter of this Agreement under any contract, negligence, strict 
liability or other legal or equitable theory for: (i) any special, incidental or 
consequential damages; (ii) the cost of procurement for substitute products or 
services; (iii) for interruption of use or loss or corruption of data; or (iv) 
for any amounts that exceed the fees paid by you to AMAGI under this Agreement 
during the twelve (12) month period prior to the cause of action. AMAGI shall 
have no liability for any failure or delay due to matters beyond their reasonable 
control. The foregoing shall not apply to the extent prohibited by applicable law.

9. General Representation and Warranty
- You represent and warrant that (i) your use of the Service will be in strict 
accordance with the Kalayo Privacy Policy, with this Agreement and with all 
applicable laws and regulations (including without limitation any local laws or 
regulations in your country, state, city, or other governmental area, regarding 
online conduct and acceptable content, and including all applicable laws 
regarding the transmission of technical data exported from the Philippines or 
the country in which you reside) and (ii) your use of the Service will not 
infringe or misappropriate the intellectual property rights of any third party.

10. Indemnification
- You agree to indemnify and hold harmless AMAGI, its contractors, and its 
licensors, and their respective directors, officers, employees and agents 
from and against any and all claims and expenses, including attorneys’ fees, 
arising out of your use of the Service, including but not limited to your 
violation of this Agreement.

All sales are final.
For any questions or concerns, contact kalayo@amagi.io.

---

# Data Privacy Policy

The only private information we ask for is your name, email address, or
location in the notification sign-up or job post purchase. This helps us
to send you updates about Kalayo.io.

We store the same data you see in the interfaces including names, email 
addresses, and other data required to maintain the intended operation of these 
services for you. AMAGI will store your data for as long as legally allowed or 
unless requested to be deleted by the primary User of an organization.

All personally identifiable data is encrypted in transit and at rest
within our infrastructure.

We connect information about your activities on different AMAGI products and 
devices to provide a more tailored and consistent experience on all AMAGI 
products.

We use the information we have to develop, test and improve our products, 
including by conducting surveys and research, and testing and troubleshooting 
new products and features.

We use the information we have to verify accounts, identities, and activity, 
combat harmful conduct, detect and prevent spam and other bad experiences, 
maintain the integrity of our products, and promote safety and security on 
and off of AMAGI products.

We do not sell your data to outside parties.

We use the information we have to send you marketing communications, communicate 
with you about our products, and let you know about our policies and terms. 
We also use your information to respond to you when you contact us.

We use the information we have (including from public data sets and research 
partners we collaborate with) to conduct and support research and innovation on 
topics of general social welfare, technological advancement, public interest, 
health and well-being. 

If you have any feedback, questions, or want to see/delete your data, feel
free to contact us at kalayo@amagi.io. If you are a Filipino citizen or company,
your rights with regard to your personal data are available at 
https://privacy.gov.ph/know-your-rights/.
`;

const Privacy = () => (
  <Container>
    <BrandContainer>
      <Brand />
    </BrandContainer>
    <MarkdownRenderer source={text} />
  </Container>
);

export default Privacy;
