/* eslint import/prefer-default-export: off */
import styled from "styled-components";

export const AvatarContainer = styled.img.attrs(() => ({
  alt: "User avatar"
}))`
  width: 35px;
  height: 35px;
  margin: 0 10px;
  border-radius: 45px;
  border: 1px solid inherit;
  ${props => props.customstyles};
`;
