import styled from "styled-components";
import COLORS from "constants/colors";
import LOG_LEVELS from "constants/logLevels";

import { getLogLevelBg } from "utils/logs";

import { CardContainer } from "../../styledElements";

export const BillingCard = styled(CardContainer)`
  margin-bottom: 24px;
`;

export const NoticeContainer = styled.div`
  padding: 24px;
  margin: 24px 0;
  border-radius: 2px;
  border: 1px solid ${COLORS.red};
  line-height: 24px;
  font-weight: bold;
  font-size: 14px;
  background-color: ${getLogLevelBg(LOG_LEVELS.ERROR)};

  .notice {
    color: ${COLORS.red};
  }
`;

export const SubscriptionContainer = styled.div`
  position: relative;
  padding: 36px;
  margin: 36px 86px;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.05);

  .subscription {
    font-size: 12px;
    opacity: 0.7;
  }

  .price {
    font-size: 28px;
    font-weight: bold;
    margin: 8px 0 16px;

    sub {
      font-weight: normal;
      font-size: 12px;
      opacity: 0.7;
    }
  }

  .primary {
    color: white;
  }

  .comparison {
    color: ${COLORS.brandOrange};
    font-size: 12px;
    font-weight: bold;
    text-decoration: none;
  }
`;

export const NextBilling = styled.div`
  font-size: 14px;
  padding: 0 86px;
  padding-bottom: 36px;
  margin-bottom: 36px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);

  .next {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .date {
      font-weight: bold;
    }
  }

  .description {
    margin-top: 12px;
    font-size: 12px;
    line-height: 16px;
    max-width: 300px;
    opacity: 0.7;
  }
`;

export const SelectPlanContainer = styled.div`
  margin-top: 24px;
`;

export const CardDetailsContainer = styled.div`
  margin: 24px 0;
  max-width: 340px;
`;
