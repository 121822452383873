import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import {
  accountReducer,
  logsReducer,
  statsReducer,
  signupReducer
} from "./modules";

export default combineReducers({
  account: accountReducer,
  logs: logsReducer,
  stats: statsReducer,
  signup: signupReducer,
  form: formReducer
});
