import styled from "styled-components";
import COLORS from "constants/colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  padding: 96px 0;
  box-sizing: border-box;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  position: relative;
  text-align: center;

  * {
    box-sizing: border-box;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  justify-content: center;
  align-items: center;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 1rem;
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const PricingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 12px;
  padding: 36px;
  border-radius: 8px;
  min-width: 300px;
  min-height: 460px;
  background: ${COLORS.white};
  box-shadow: 0 10px 35px -10px rgba(0, 0, 0, 0.175);
`;

export const PricingTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: ${COLORS.mainFg};
`;

export const PricingPrice = styled.div`
  color: ${COLORS.brandOrange};
  font-size: 42px;
  font-weight: bold;
  margin: 36px 0 18px;
  text-align: center;

  sub {
    font-size: 12px;
  }
`;

export const FeaturesContainer = styled.div`
  margin: 36px 0;
  width: 100%;
  text-align: left;

  svg {
    margin-right: 8px;
  }
`;

export const PricingFeature = styled.div`
  font-size: 14px;
  line-height: 24px;
  opacity: 0.5;
  color: ${COLORS.mainFg};
`;

export const ToggleContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

export const ToggleInput = styled.input`
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 10px;
  border: 0 #fff;
  overflow: hidden;

  &:checked + label {
    color: ${COLORS.white};
    background-color: ${COLORS.brandOrange};
    box-shadow: none;
  }
`;

export const ToggleLabel = styled.label`
  background-color: ${COLORS.white};
  color: ${COLORS.mainFg};
  font-weight: bold;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  padding: 8px 16px;
  margin-right: -1px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: all 0.1s ease-in-out;

  &:hover {
    cursor: pointer;
  }

  &:first-of-type {
    border-radius: 30px 0 0 30px;
  }

  &:last-of-type {
    border-radius: 0 30px 30px 0;
  }
`;

export const IconContainer = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 80px;
  background-color: ${props =>
    props.featured ? COLORS.brandOrange : COLORS.mainBg};
  color: ${props => (props.featured ? COLORS.white : COLORS.brandOrange)};
`;
