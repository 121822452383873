import React from "react";

import {
  HeaderSection,
  SecondSection,
  PricingSection,
  Footer,
  LoggingSection
} from "./components";
import { LandingPageContainer } from "./styledElements";

const LandingPage = () => (
  <LandingPageContainer>
    <HeaderSection />
    <SecondSection />
    <LoggingSection />
    <PricingSection />
    <Footer />
  </LandingPageContainer>
);

export default LandingPage;
