import { takeEvery, put, all, call, select } from "redux-saga/effects";
import { toast } from "react-toastify";

import * as DataServices from "services/data.services";
import {
  setBilling,
  setSubscription,
  clearStorage
} from "services/storage.services";
import ERRORS from "constants/errors";
import { getOrganization } from "./selectors";

import {
  getSubscription,
  getSubscriptionFailed,
  getSubscriptionSuccess,
  getBillingInfo,
  getBillingInfoFailed,
  getBillingInfoSuccess,
  setBillingInfo,
  setBillingInfoFailed,
  setBillingInfoSuccess,
  subscribe,
  subscribeFailed,
  subscribeSuccess,
  cancelSubscriptionFailed,
  cancelSubscriptionSuccess,
  inviteByEmailFailed,
  inviteByEmailSuccess,
  getOrgUsers,
  getOrgUsersFailed,
  getOrgUsersSuccess
} from "./actions";

export function* onGetSubscriptionAsync() {
  try {
    const { error, response } = yield call(DataServices.getSubscription);

    if (error) {
      yield put(getSubscriptionFailed());
    } else {
      yield put(getSubscriptionSuccess({ activeSub: response[0] }));

      setSubscription(response);
    }
  } catch (e) {
    yield put(getSubscriptionFailed());
  }
}

export function* watchOnGetSubscriptionAsync() {
  yield takeEvery(getSubscription().type, onGetSubscriptionAsync);
}

export function* onGetBillingInfoAsync() {
  try {
    const { error, response } = yield call(DataServices.getBillingInfo);

    if (error) {
      yield put(getBillingInfoFailed());
    } else {
      const { billing, card } = response;

      yield put(
        getBillingInfoSuccess({
          billing,
          card
        })
      );

      setBilling(billing);
    }
  } catch (e) {
    yield put(getBillingInfoFailed());
  }
}

export function* watchOnGetBillingInfoAsync() {
  yield takeEvery("GET_BILLING_INFO", onGetBillingInfoAsync);
}

export function* onSetBillingInfoAsync({ payload } = {}) {
  const {
    contactName,
    email,
    entityName,
    businessAddress,
    paymentMethod
  } = payload;

  try {
    const response = yield call(DataServices.setBillingInfo, {
      contactName,
      email,
      entityName,
      businessAddress,
      paymentMethod: paymentMethod ? paymentMethod.id : undefined
    });

    if (response.error) {
      toast.error(response.error);
      yield put(setBillingInfoFailed());
    } else {
      toast.success("You have successfully updated your billing details!");
      yield put(
        setBillingInfoSuccess({
          billing: {
            contactName,
            email,
            entityName,
            businessAddress
          },
          card: paymentMethod ? paymentMethod.card : null
        })
      );

      // const pricing = yield select(getPricing);
      // const { id } = yield select(getOrganization);

      // // Subscribe the user to selected plan
      // yield put(
      //   subscribe({
      //     pricing,
      //     promoCode,
      //     id
      //   })
      // );

      // Store billing information in localStorage
      setBilling({
        contactName,
        email,
        entityName,
        businessAddress
      });
    }
  } catch (e) {
    toast.error(ERRORS.UNKNOWN);

    yield put(setBillingInfoFailed());
  }
}

export function* watchOnSetBillingInfoAsync() {
  yield takeEvery(setBillingInfo().type, onSetBillingInfoAsync);
}

export function* onSubscribeAsync({ payload } = {}) {
  const { pricing, promoCode, billing } = payload;

  try {
    const { id } = yield select(getOrganization);

    if (billing) {
      yield call(DataServices.setBillingInfo, {
        ...billing,
        paymentMethod: billing.paymentMethod ? billing.paymentMethod.id : null
      });
    }

    const response = yield call(DataServices.subscribe, {
      pricing: pricing || "monthly",
      promoCode,
      id
    });

    if (response.error) {
      toast.error(response.error);

      yield put(subscribeFailed());
    } else {
      toast.success(`You have successfully subscribed to Kalayo!`);
      yield put(subscribeSuccess());
      yield put(getSubscription());
      yield put(getBillingInfo());
    }
  } catch (e) {
    toast.error(ERRORS.UNKNOWN);

    yield put(subscribeFailed());
  }
}

export function* watchOnSubscribeAsync() {
  yield takeEvery(subscribe().type, onSubscribeAsync);
}

export function* onCancelSubscriptionAsync() {
  try {
    const response = yield call(DataServices.deleteSubscription);

    if (response.error) {
      toast.error(response.error);

      yield put(cancelSubscriptionFailed());
    } else {
      toast.success(`You have canceled your subscription.`);

      // Clear billing and subscription info
      clearStorage();

      yield put(cancelSubscriptionSuccess());
    }
  } catch (e) {
    toast.error(ERRORS.UNKNOWN);

    yield put(cancelSubscriptionFailed());
  }
}

export function* watchOnCancelSubscriptionAsync() {
  yield takeEvery("CANCEL_SUBSCRIPTION", onCancelSubscriptionAsync);
}

export function* onInviteByEmailAsync({ payload } = {}) {
  // eslint-disable-next-line camelcase
  const { email, name, contact_no } = payload;

  try {
    const response = yield call(DataServices.inviteByEmail, {
      email,
      name,
      contact_no
    });

    if (response.error) {
      toast.error(response.error);

      yield put(inviteByEmailFailed());
    } else {
      toast.success(`Your invite has been sent to ${email}.`);
      yield put(inviteByEmailSuccess());
    }
  } catch (e) {
    toast.error(ERRORS.UNKNOWN);

    yield put(inviteByEmailFailed());
  }
}

export function* watchOnInviteByEmailAsync() {
  yield takeEvery("INVITE_BY_EMAIL", onInviteByEmailAsync);
}

export function* onGetOrgUsersAsync() {
  try {
    const { error, response } = yield call(DataServices.getOrgUsers);

    if (error) {
      yield put(getOrgUsersFailed());
    } else {
      yield put(getOrgUsersSuccess({ users: response }));
    }
  } catch (e) {
    yield put(getOrgUsersFailed());
  }
}

export function* watchOnGetOrgUsersAsync() {
  yield takeEvery(getOrgUsers().type, onGetOrgUsersAsync);
}

export default function* rootSaga() {
  yield all([
    watchOnGetSubscriptionAsync(),
    watchOnGetBillingInfoAsync(),
    watchOnSetBillingInfoAsync(),
    watchOnSubscribeAsync(),
    watchOnCancelSubscriptionAsync(),
    watchOnInviteByEmailAsync(),
    watchOnGetOrgUsersAsync()
  ]);
}
