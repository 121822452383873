import React from "react";
import ReactDOM from "react-dom";

import TagManager from "react-gtm-module";
import Modal from "react-modal";

import "react-toastify/dist/ReactToastify.css";
import "./index.css";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

const {
  REACT_APP_GTM_ID,
  REACT_APP_GTM_AUTH,
  REACT_APP_GTM_PREVIEW
} = process.env;

const tagManagerArgs = {
  gtmId: REACT_APP_GTM_ID,
  auth: REACT_APP_GTM_AUTH,
  preview: REACT_APP_GTM_PREVIEW,
  dataLayerName: "reactAppDataLayer"
};

TagManager.initialize(tagManagerArgs);

Modal.setAppElement("#root");

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
