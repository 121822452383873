import React from "react";
import PropTypes from "prop-types";

import * as StorageServices from "services/storage.services";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ScreenClassRender } from "react-grid-system";
import { Avatar, Button } from "components";
import { ProfileCard, Name, Email } from "./styledElements";

class Profile extends React.PureComponent {
  onLogout = async () => {
    const { history } = this.props;

    await StorageServices.clearAuthTokens();
    await StorageServices.clearStorage();

    history.push("/");
  };

  render() {
    const { name, email, picture } = this.props;

    return (
      <ScreenClassRender
        render={screen => (
          <ProfileCard>
            <Avatar
              containerStyle={{
                width: ["md"].includes(screen) ? 90 : 180,
                height: ["md"].includes(screen) ? 90 : 180,
                borderRadius: ["md"].includes(screen) ? 90 : 180,
                border: "6px solid #f0f0f0"
              }}
              src={picture}
            />
            <Name screen={screen}>{name}</Name>
            <Email>{email}</Email>
            <Button type="button" onClick={this.onLogout}>
              Sign Out
            </Button>
          </ProfileCard>
        )}
      />
    );
  }
}

Profile.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  name: PropTypes.string,
  email: PropTypes.string,
  picture: PropTypes.string
};

Profile.defaultProps = {
  name: null,
  email: null,
  picture: null
};

export default connect(state => ({
  name: state.account.profile.name,
  email: state.account.profile.email,
  picture: state.account.profile.picture
}))(withRouter(Profile));
