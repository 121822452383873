import ERROR_MESSAGES from "constants/errors";

import { createAuthApi } from "../createApi";

export const createToken = async () => {
  const [error, response] = await createAuthApi("/v1/tokens")({
    method: "POST",
    headers: { "Content-Type": "application/json" }
  });

  if (error) return { error: error.message };

  const { status, ok } = response;

  if (!ok) {
    if (status === 400) return { error: ERROR_MESSAGES.CORRUPTED_PAYLOAD };
    if (status === 401) return { error: ERROR_MESSAGES.UNAUTHORIZED };
    if (status === 403) return { error: ERROR_MESSAGES.FORBIDDEN };
    if (status === 500)
      return {
        error: ERROR_MESSAGES.SERVER_ERROR
      };

    return {
      error: ERROR_MESSAGES.UNKNOWN
    };
  }

  const body = await response.json();

  return { response: body };
};

export const getTokens = async () => {
  const [error, response] = await createAuthApi("/v1/tokens")({
    method: "GET",
    headers: { "Content-Type": "application/json" }
  });

  if (error) return { error: error.message };

  const { status, ok } = response;

  if (!ok) {
    if (status === 400) return { error: ERROR_MESSAGES.CORRUPTED_PAYLOAD };
    if (status === 401) return { error: ERROR_MESSAGES.UNAUTHORIZED };
    if (status === 403) return { error: ERROR_MESSAGES.FORBIDDEN };
    if (status === 500)
      return {
        error: ERROR_MESSAGES.SERVER_ERROR
      };

    return {
      error: ERROR_MESSAGES.UNKNOWN
    };
  }

  const body = await response.json();

  return { response: body };
};

export const deleteToken = async ({ id }) => {
  const [error, response] = await createAuthApi(`/v1/tokens/${id}`)({
    method: "DELETE"
  });

  if (error) return { error: error.message };

  const { status, ok } = response;

  if (!ok) {
    if (status === 400) return { error: ERROR_MESSAGES.CORRUPTED_PAYLOAD };
    if (status === 401) return { error: ERROR_MESSAGES.UNAUTHORIZED };
    if (status === 403) return { error: ERROR_MESSAGES.FORBIDDEN };
    if (status === 500)
      return {
        error: ERROR_MESSAGES.SERVER_ERROR
      };

    return {
      error: ERROR_MESSAGES.UNKNOWN
    };
  }

  return {};
};
