import styled from "styled-components";

import { Row, Col } from "react-grid-system";

import COLORS from "constants/colors";
import { Button as ButtonBase } from "../Settings/styledElements";

export const CardHeader = styled(Row)`
  margin-bottom: 24px;
`;

export const CardHeaderTitle = styled(Col)`
  display: flex;
  align-items: center;

  h2 {
    margin: 0;
  }
`;

export const CardActionContainer = styled(Col)`
  display: flex;
  justify-content: flex-end;
`;

export const UsersList = styled.div`
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const HeaderRow = styled(Row).attrs({
  align: "center"
})`
  background-color: #f2f2f2;
  padding: 10px !important;
  font-size: 12px;
  font-weight: 600;
  top: 0;
  color: rgb(51, 51, 51, 0.75);
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
`;

export const RowContainer = styled(Row).attrs({
  align: "center"
})`
  padding: 10px !important;
  color: rgb(51, 51, 51);
  font-size: 0.9rem;
`;

export const ActionContainer = styled.div`
  button {
    padding: 10px;
    font-size: 0.7rem;
    min-width: unset;

    svg {
      margin: 0 !important;
      padding: 0 !important;
    }
  }
`;

export const Button = styled(ButtonBase)`
  box-shadow: none;
  border: none;
  background: ${COLORS.brandOrange};
  color: ${COLORS.white};
`;
