// Validation functions
export const required = value => !value && "Please fill up this field.";
export const minPasswordLength = value =>
  value.length < 6 && "Password must have at least 6 characters.";
export const matchingPasswords = (password, repeatPassword) =>
  password !== repeatPassword && "Passwords must match.";
export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "Invalid email address."
    : undefined;
export const phoneNumber = value =>
  value && !/^\+\d{1,4}\d{10,10}$/i.test(value)
    ? "Invalid phone number. Must follow the format +639121234567"
    : undefined;
