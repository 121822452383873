export const serializeObjectQuery = obj => {
  const keys = Object.keys(obj);

  const string = keys.reduce((acc, curr) => {
    let query = `${acc}`;
    if (obj[curr] === "" || typeof obj[curr] === "undefined") return query;

    if (query === "") {
      query += "?";
    }

    if (query.length > 1) {
      query += "&";
    }

    query = `${query}${curr}=${encodeURIComponent(obj[curr])}`;

    return query;
  }, "");

  return string;
};

export const formatLogLevels = (logLevels = {}) => {
  /*
   * Filter the object down to only the toggled
   * filters (must be equal to true) and join
   * the keys into a comma-separated string
   * to pass to the API
   */
  return Object.keys(logLevels)
    .filter(level => logLevels[level] === true)
    .join(",");
};
