/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { Route } from "react-router-dom";
import { setOnboardingStatus } from "services/storage.services";

import { NotificationBanner, NavHashLink } from "components";
import COLORS from "constants/colors";
import { DashboardContainer, NavigationRef } from "./styledElements";
import { Account, Header, History, Navigation, Stats } from "./components";

export const Dashboard = ({ history, activeSub }) => {
  useEffect(() => {
    setOnboardingStatus("DONE");
  }, []);

  return (
    <DashboardContainer>
      {!activeSub && (
        <NotificationBanner color={COLORS.red}>
          <span>
            Your subscription has ended. Please{" "}
            <NavHashLink
              to="/dashboard/account#billing"
              linkColor="white"
              visitedColor="white"
              hasUnderline
            >
              update your billing details
            </NavHashLink>{" "}
            now.
          </span>
        </NotificationBanner>
      )}
      <Header history={history} />
      <Navigation />
      <NavigationRef />
      <Route exact path="/dashboard" component={Stats} />
      <Route exact path="/dashboard/history" component={History} />
      <Route exact path="/dashboard/account" component={Account} />
    </DashboardContainer>
  );
};

Dashboard.propTypes = {
  activeSub: PropTypes.instanceOf(Object),
  history: PropTypes.instanceOf(Object).isRequired
};

Dashboard.defaultProps = {
  activeSub: undefined
};

export default connect(state => ({
  activeSub: state.account.activeSub
}))(Dashboard);
