import styled from "styled-components";
import COLORS from "constants/colors";
import { Button as ButtonBase } from "components";
import { CardContainer } from "../../styledElements";

export const SettingsCard = styled(CardContainer)``;

export const SettingContainer = styled.div`
  margin-bottom: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);

  &:last-of-type {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
  }

  input[type="text"],
  input[type="url"],
  input[type="tel"],
  input[type="number"],
  input[type="color"],
  input[type="email"],
  select {
    display: block;
    outline: none;
    border-radius: 4px;
    border: 1px solid #dddddd;
    padding: 12px;
    margin-bottom: 12px;
    width: calc(100% - 24px);
    max-width: 340px;
  }
`;

export const Button = styled(ButtonBase)`
  border: none;
  outline: none;
  color: ${COLORS.mainFg};
  width: auto;
  background-color: #dddddd;
  box-shadow: none;

  &.danger {
    color: rgba(204, 0, 0);
  }

  &.primary {
    background-color: ${COLORS.primary};
  }

  &:hover {
    box-shadow: none;
  }
`;

export const SettingTitle = styled.div`
  font-weight: bold;
  margin-bottom: 8px;
`;

export const SettingDescription = styled.div`
  opacity: 0.7;
  margin-bottom: 12px;
  font-size: 14px;
`;

export const ApiKeyContainer = styled.div`
  display: flex;
  height: 2rem;

  textarea {
    background: rgb(240, 240, 240);
    font-family: Menlo, Monaco, Consolas, "Courier New", monospace !important;
    font-size: 0.8rem;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    margin: 0;
    padding: 10px;
    display: block;
    overflow: hidden !important;
    text-overflow: ellipsis;
    resize: none;
    box-shadow: none;
    width: 100%;
    border: none;
    white-space: nowrap;
  }
`;

export const ClipboardButton = styled(Button)`
  min-width: unset;
  padding: 5px 10px;
  border-radius: 0px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;

  svg {
    padding: 0;
    margin: 0;
  }

  &:hover {
    transform: unset;
  }
`;
