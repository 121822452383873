import styled from "styled-components";

export const DashboardContainer = styled.div`
  background-color: #f2f2f2;
  /* color: #001f42; */
  min-height: 100vh;
`;

export const NavigationRef = styled.div.attrs({
  id: "navigation-ref"
})`
  position: absolute;
  width: 1px;
  height: 1px;
  top: 75px;
  left: 0;
`;
