/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
import { Col } from "react-grid-system";
import PropTypes from "prop-types";
import COLORS from "constants/colors";
import SearchSelect from "./SearchSelect";

import { Input, Label, Info } from "./styledElements";

const DEFAULT_COLOR = "rgba(0, 0, 0, 0.4)";

const FormItem = props => {
  const [color, setColor] = useState(DEFAULT_COLOR);
  const {
    name,
    label,
    layout,
    input,
    type,
    meta: { active, touched, error, warning },
    choices,
    ...fieldProps
  } = props;

  const selectProps = {
    ...(choices && { choices }),
    ...{ input }
  };

  const inputProps = {
    ...(type && { type }),
    ...(name && { name }),
    ...(label && { label }),
    ...fieldProps
  };

  const handleMouseEnter = () => {
    // Set the input's color only if it's not active
    setColor(COLORS.brandOrange);
  };

  const handleMouseLeave = () => {
    // Reset the input's color only if it's not active
    setColor(DEFAULT_COLOR);
  };

  let fieldComponent;

  let currentColor = DEFAULT_COLOR;

  if (active) currentColor = COLORS.brandOrange;
  else if (touched && (error || warning))
    currentColor = error ? "red" : "orange";
  else currentColor = color;

  if (type === "select")
    fieldComponent = <SearchSelect {...selectProps} {...inputProps} />;
  else
    fieldComponent = (
      <Input
        color={currentColor}
        name={name}
        type={type}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        {...inputProps}
        {...input}
      />
    );

  return (
    <Col {...layout}>
      <Label color={currentColor} htmlFor={name}>
        {label}
      </Label>
      {fieldComponent}
      <Info type="error">
        {touched && (error || warning)}
        &nbsp;
      </Info>
    </Col>
  );
};

FormItem.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  input: PropTypes.element.isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
  layout: PropTypes.instanceOf(Object),
  type: PropTypes.string,
  disabled: PropTypes.bool,
  choices: PropTypes.instanceOf(Array)
};

FormItem.defaultProps = {
  type: "text",
  disabled: false,
  choices: [],
  layout: {}
};

export default FormItem;
