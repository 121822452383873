import LOG_LEVELS from "constants/logLevels";

export default logLevel => {
  switch (logLevel) {
    case LOG_LEVELS.DEFAULT:
      return "rgba(1, 137, 182, 0.22)";
    case LOG_LEVELS.WARNING:
      return "rgba(222, 134, 20, 0.22)";
    case LOG_LEVELS.ERROR:
      return "rgba(204, 0, 0, 0.22)";
    default:
      return "rgba(1, 137, 182, 0.22)";
  }
};
