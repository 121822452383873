import React from "react";

import { SidebarContainer, NavItem, SubLink } from "./styledElements";

const Sidebar = () => {
  const scrollWithOffset = el => {
    // Add the position of the element within
    // the viewport to the distance from the
    // top of the document to get the
    // element's position
    const elementPosition = el.getBoundingClientRect().top + window.scrollY;

    window.scroll({
      // Subtract 100 to account for the
      // navigation menu
      top: elementPosition - 100,
      left: 0,
      behavior: "smooth"
    });
  };

  return (
    <SidebarContainer>
      <NavItem
        scroll={el => scrollWithOffset(el)}
        to="/dashboard/account#billing"
      >
        Subscription
      </NavItem>
      <SubLink
        scroll={el => scrollWithOffset(el)}
        to="/dashboard/account#billingdetails"
      >
        Billing Details
      </SubLink>
      <SubLink
        scroll={el => scrollWithOffset(el)}
        to="/dashboard/account#cancel"
      >
        Cancel Subscription
      </SubLink>
      <NavItem
        scroll={el => scrollWithOffset(el)}
        to="/dashboard/account#tokens"
      >
        Tokens
      </NavItem>
      <SubLink
        scroll={el => scrollWithOffset(el)}
        to="/dashboard/account#managetokens"
      >
        Manage Tokens
      </SubLink>
      <NavItem
        scroll={el => scrollWithOffset(el)}
        to="/dashboard/account#settings"
      >
        Account
      </NavItem>
      <SubLink
        scroll={el => scrollWithOffset(el)}
        to="/dashboard/account#usertoken"
      >
        User Token
      </SubLink>
      <SubLink
        scroll={el => scrollWithOffset(el)}
        to="/dashboard/account#changepassword"
      >
        Change Password
      </SubLink>
      <NavItem scroll={el => scrollWithOffset(el)} to="/dashboard/account#team">
        Team
      </NavItem>
      <SubLink
        scroll={el => scrollWithOffset(el)}
        to="/dashboard/account#manageusers"
      >
        Manage Users
      </SubLink>
    </SidebarContainer>
  );
};

export default Sidebar;
