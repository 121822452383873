import React from "react";
import { PropTypes } from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Container, CloseButton, Content } from "./styledElements";

const NotificationBanner = ({
  Icon,
  color,
  textColor,
  canClose,
  style,
  children
}) => (
  <Container background={color} color={textColor} style={style}>
    <Content>
      {Icon && <Icon />}
      {children}
    </Content>
    {canClose && (
      <CloseButton color={textColor}>
        <FontAwesomeIcon icon="times" />
      </CloseButton>
    )}
  </Container>
);

NotificationBanner.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  Icon: PropTypes.node,
  color: PropTypes.string,
  canClose: PropTypes.bool,
  textColor: PropTypes.string,
  style: PropTypes.string
};

NotificationBanner.defaultProps = {
  Icon: null,
  color: "red",
  canClose: false,
  textColor: "white",
  style: null
};

export default NotificationBanner;
