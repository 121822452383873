import styled from "styled-components";
import COLORS from "../../colors";

export const Container = styled.div`
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const Step = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 24px;

  &:last-of-type {
    margin-right: 0;
  }

  &:first-of-type {
    margin-left: 0;
  }

  ${props =>
    (props.inactive || props.done) &&
    `
    .title,
    .description {
      color: ${COLORS.color}
      opacity: 0.2;
    }
  `}
`;

export const Number = styled.div`
  width: 50px;
  min-width: 50px;
  height: 50px;
  margin-right: 24px;
  border-radius: 50px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.3);
  font-weight: bold;

  ${props =>
    props.active &&
    `
    background-color: ${COLORS.activeStep};
    border: none;
    color: white;
  `}

  ${props =>
    props.done &&
    `
    background-color: white;
    color: rgba(0, 0, 0, 0.3);
    border: none;
  `}
`;

export const StepTitle = styled.div.attrs({
  className: "title"
})`
  font-size: 1rem;
  font-weight: normal;
  color: ${COLORS.color};
`;
