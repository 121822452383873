/* eslint import/prefer-default-export : off */
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 350px;
  border-radius: 4px;
  margin-bottom: 24px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  position: relative;
  overflow: hidden;

  select {
    appearance: none;
    border: none;
    outline: none;
    width: 100%;
    height: 41px;
    padding: 0 12px;
    background-color: transparent;
    z-index: 1;
  }

  svg {
    position: absolute;
    right: 12px;
    z-index: 0;
  }
`;

export const Label = styled.div`
  font-size: 12px;
  opacity: 0.5;
  font-weight: bold;
  margin-bottom: 8px;
  text-transform: uppercase;
`;
