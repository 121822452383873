/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";

import { SelectField } from "./styledElements";

const SearchSelect = ({
  input: { value, onChange },
  choices,
  placeholder,
  ...selectProps
}) => (
  <SelectField
    placeholder={placeholder}
    {...selectProps}
    onChange={onChange}
    value={value}
  >
    {choices.map(choice => (
      <option value={choice.value}>{choice.label}</option>
    ))}
  </SelectField>
);

SearchSelect.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  form: PropTypes.instanceOf(Object).isRequired,
  layout: PropTypes.instanceOf(Object),
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.instanceOf(Object),
  choices: PropTypes.instanceOf(Array).isRequired,
  placeholder: PropTypes.string
};

SearchSelect.defaultProps = {
  layout: { lg: 12, md: 12, sm: 12, xs: 12 },
  options: {},
  placeholder: ""
};

export default SearchSelect;
