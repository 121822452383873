import styled from "styled-components";

export const ProfileCard = styled.div`
  text-align: center;

  button {
    font-size: 1rem;
    margin-top: 32px;
    cursor: pointer;
    color: rgba(204, 0, 0);
    background-color: #dddddd;

    &:hover {
      opacity: 0.7;
    }
  }
`;

export const Name = styled.div`
  margin-top: 32px;
  margin-bottom: 12px;
  font-weight: bold;
  font-size: ${props => (["md"].includes(props.screen) ? "20px" : "30px")};
`;

export const Email = styled.div`
  font-size: 14px;
  opacity: 0.7;
`;
