import styled from "styled-components";

export const Container = styled.div`
  box-sizing: border-box;
  min-height: 100vh;
  background: white;
  padding-left: 10%;
  padding-right: 10%;

  h1,
  h2,
  h3 {
    font-weight: 400;
  }
`;

export const BrandContainer = styled.div`
  display: flex;
  height: 106px;
  align-items: center;
`;
