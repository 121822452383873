import styled from "styled-components";
import COLORS from "constants/colors";

import { NavLink } from "react-router-dom";
import { HashLink as HLink } from "react-router-hash-link";

export const HeaderSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  padding: 2.5% 5%;
  box-sizing: border-box;
  align-items: space-between;
  min-height: 100vh;
  width: 100%;
`;

export const NavbarContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: space-between;
  z-index: 1;
`;

export const BrandImg = styled.img`
  position: relative;
  max-width: 100px;
  width: 100%;
`;

export const Logo = styled(NavLink)`
  display: flex;
  font-family: "Montserrat", sans-serif !important;
  align-items: center;
  color: ${COLORS.brandOrange};
  font-weight: bold;
  font-size: 1.7rem;
  text-transform: uppercase;
  letter-spacing: 0.5rem;
  text-decoration: none;
  padding-bottom: 0.2rem;
`;

export const HashLink = styled(HLink).attrs({
  smooth: true
})`
  text-decoration: none;
  color: ${COLORS.mainFg};

  &:hover {
    color: ${COLORS.brandBlue};
  }

  &:visited {
    color: ${COLORS.mainFg};
  }
`;

export const NavbarLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const NavbarRight = styled(NavbarLeft)`
  justify-content: flex-end;
`;

export const NavbarList = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style-type: none;

  li {
    text-transform: uppercase;
    font-size: 0.9rem;
    font-weight: bold;
    margin-left: 3rem;
    letter-spacing: 0.15rem;
  }
`;

export const Content = styled.div`
  height: calc(100vh - 106px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
`;

export const Headline = styled.h1`
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 3.4rem;
  line-height: 3.5rem;
  margin-bottom: 1.5rem;
  max-width: 750px;
  width: 100%;
`;

export const Subline = styled.div`
  font-size: 1.3rem;
  line-height: 2rem;
  margin-bottom: 3.5rem;
  max-width: 600px;
  opacity: 0.5;
`;

export const ButtonContainer = styled.div`
  margin: 0;
  display: flex;
  align-items: center;
`;

export const Link = styled(NavLink)`
  position: relative;
  text-decoration: none;
  color: ${COLORS.mainFg};

  &:hover {
    color: ${COLORS.brandBlue};
    transition: all 0.15s ease-in;

    svg {
      transition: all 0.2s ease-out;
      transform: translateX(5px);
    }
  }

  svg {
    margin-left: 10px;
  }
`;

export const DocsLink = styled(Link)`
  text-decoration: none;
  color: ${COLORS.mainFg};
  margin: 10px;
  margin-left: 30px;
  font-size: 1.2rem;
`;

export const DevicesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;

  svg {
    margin: 0 4px;
    font-size: 12px;
    opacity: 0.75;
  }
`;
