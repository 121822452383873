import React from "react";
import PropTypes from "prop-types";

import Modal from "react-modal";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import COLORS from "constants/colors";
import { inviteByEmail } from "redux/modules/account/actions";
import * as validate from "utils/validate";
import { Input, Button } from "components";
import { InviteFormContainer, Title, CloseButton } from "./styledElements";

const InviteFormModal = ({
  isOpen,
  isLoading,
  onAfterOpen,
  onRequestClose,
  onInviteByEmail,
  handleSubmit,
  valid
}) => {
  const submit = async values => {
    // eslint-disable-next-line camelcase
    const { email, name, contact_no } = values;
    await onInviteByEmail({ email, name, contact_no });
  };

  return (
    <Modal
      isOpen={isOpen}
      onAfterOpen={onAfterOpen}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick
      style={{
        overlay: {
          zIndex: 5,
          background: "rgba(0,0,0,.4)"
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          minWidth: "300px",
          transform: "translate(-50%, -50%)",
          border: "none",
          borderRadius: "8px",
          background: "white",
          boxShadow: "0 10px 35px -10px rgba(0, 0, 0, 0.175)",
          padding: "48px"
        }
      }}
    >
      <InviteFormContainer>
        <CloseButton onClick={onRequestClose}>
          <FontAwesomeIcon icon="times" />
        </CloseButton>
        <Title>Invite a member</Title>
        <form onSubmit={handleSubmit(submit)} className="inviteUsers">
          <Field
            name="email"
            type="email"
            label="Email"
            component={Input}
            validate={[validate.required, validate.email]}
            layout={{ md: 12 }}
          />
          <Field
            name="name"
            type="text"
            label="Name"
            component={Input}
            validate={[validate.required]}
            layout={{ md: 12 }}
          />
          {/* <Field
            name="contact_no"
            type="text"
            label="Contact No."
            component={Input}
            validate={[validate.required, validate.phoneNumber]}
            layout={{ md: 12 }}
          /> */}
          {/* <Row>
            <Col>
              <Field
                name="role"
                component={Input}
                type="radio"
                value="admin"
                label="Admin"
              />
            </Col>
            <Col>
              <Field
                name="role"
                component={Input}
                type="radio"
                value="user"
                label="User"
              />
            </Col>
          </Row> */}
          <Button
            type="submit"
            disabled={!valid || isLoading}
            loading={isLoading}
            theme={{
              background: COLORS.brandOrange,
              color: COLORS.white
            }}
          >
            Invite
          </Button>
        </form>
      </InviteFormContainer>
    </Modal>
  );
};

InviteFormModal.propTypes = {
  valid: PropTypes.bool,
  isOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  onAfterOpen: PropTypes.func,
  onRequestClose: PropTypes.func,
  onInviteByEmail: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

InviteFormModal.defaultProps = {
  valid: false,
  isOpen: false,
  isLoading: false,
  onAfterOpen: null,
  onRequestClose: null
};

const selector = formValueSelector("inviteUsers");

export default reduxForm({
  form: "inviteUsers",
  onSubmit: () => {}
})(
  connect(
    state => ({
      isLoading: state.account.isInvitingByEmail,
      email: selector(state, "email"),
      name: selector(state, "name"),
      contact_no: selector(state, "contact_no")
    }),
    {
      onInviteByEmail: inviteByEmail
    }
  )(InviteFormModal)
);
