import React, { useState, useEffect } from "react";
import image from "assets/kalayo.png";

import { Provider } from "react-redux";
import { StripeProvider } from "react-stripe-elements";
import { setConfiguration } from "react-grid-system";

import { ToastContainer } from "react-toastify";

import { Helmet } from "react-helmet";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowRight, faFan } from "@fortawesome/free-solid-svg-icons";
import { loadIcons } from "./utils/general/icons";

import { store } from "./redux";

import Routes from "./routes";

import "./assets/fonts/Enhanced/Enhanced.css";

setConfiguration({
  containerWidths: [540, 750, 960, 1180]
});

loadIcons();

library.add(faArrowRight, faFan);

function App() {
  const [stripe, setStripe] = useState(null);

  useEffect(() => {
    if (window.Stripe) {
      setStripe(window.Stripe(process.env.REACT_APP_STRIPE_PK_KEY));
    } else if (document.querySelector("#stripe-js")) {
      document.querySelector("#stripe-js").addEventListener("load", () => {
        setStripe(window.Stripe(process.env.REACT_APP_STRIPE_PK_KEY));
      });
    }
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Kalayo | Full Stack Monitoring and Logging</title>
        <meta
          name="description"
          content="Kalayo is an error monitoring and reporting software designed for creating alerts for bugs, errors, and more in React web apps, React Native mobile apps, JavaScript, and Go. Try the free trial today!"
        />
        <link rel="canonical" href="https://kalayo.io" />
        {/* OpenGraph tags */}
        <meta name="og:url" content="https://kalayo.io" />
        <meta
          name="og:title"
          content="Kalayo | Full Stack Monitoring and Logging"
        />
        <meta
          name="og:description"
          content="Kalayo is an error monitoring and reporting software. Try the free trial today!"
        />
        <meta name="og:image" content={image} />
        <meta name="og:type" content="website" />
        <meta name="fb:app_id" content="kalayologger" />
        {/* Twitter Card tags */}
        <meta
          name="twitter:title"
          content="Kalayo | Full Stack Monitoring and Logging"
        />
        <meta
          name="twitter:description"
          content="Kalayo is an error monitoring and reporting software. Try the free trial today!"
        />
        <meta name="twitter:image" content={image} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@amagitech" />
      </Helmet>
      <ToastContainer
        className="toast-container"
        toastClassName="toast"
        progressClassName="toast-progress"
        autoClose={2500}
        hideProgressBar
      />
      <Provider store={store}>
        <StripeProvider stripe={stripe}>
          <Routes />
        </StripeProvider>
      </Provider>
    </>
  );
}

export default App;
