import React from "react";
import PropTypes from "prop-types";

import { css } from "styled-components";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "components";
import COLORS from "constants/colors";

import {
  Container,
  ContentContainer,
  Header,
  Content,
  PricingContainer,
  PricingTitle,
  PricingPrice,
  FeaturesContainer,
  PricingFeature,
  ToggleContainer,
  ToggleInput,
  ToggleLabel,
  IconContainer
} from "./styledElements";
import { Headline, Subline } from "../../styledElements";

class PricingSection extends React.Component {
  state = {
    selectedPlan: "annual"
  };

  onChangePlan = e => {
    this.setState({
      selectedPlan: e.target.value
    });
  };

  goToSignUp = () => {
    const { history } = this.props;

    history.push("/account/signup");
  };

  goToDocs = () => {
    const { history } = this.props;

    history.push("/docs");
  };

  render() {
    const { selectedPlan } = this.state;

    return (
      <Container id="pricing">
        <FontAwesomeIcon
          icon="egg"
          color={COLORS.brandOrange}
          style={{
            position: "absolute",
            bottom: -90,
            left: -90,
            fontSize: 700,
            opacity: 0.075
          }}
        />
        <ContentContainer>
          <Header>
            <Headline>Choose Your Plan</Headline>
            <Subline>
              No hidden fees, no setup charges, and no added monthly costs.
            </Subline>
            <ToggleContainer onChange={this.onChangePlan}>
              <ToggleInput
                type="radio"
                id="annual"
                name="pricing-plan"
                value="annual"
                defaultChecked
              />
              <ToggleLabel htmlFor="annual">Annual</ToggleLabel>
              <ToggleInput
                type="radio"
                id="monthly"
                name="pricing-plan"
                value="monthly"
              />
              <ToggleLabel htmlFor="monthly">Monthly</ToggleLabel>
            </ToggleContainer>
          </Header>
          <Content>
            <PricingContainer>
              <div>
                <IconContainer>
                  <FontAwesomeIcon icon="code" size="2x" />
                </IconContainer>
                <PricingPrice>$0</PricingPrice>
                <PricingTitle>Self-Hosted</PricingTitle>
              </div>
              <FeaturesContainer>
                <PricingFeature>
                  <FontAwesomeIcon icon="chevron-right" />
                  <span>Access to Kalayo client logger</span>
                </PricingFeature>
                <PricingFeature>
                  <FontAwesomeIcon icon="chevron-right" />
                  <span>Flexibility to self-host</span>
                </PricingFeature>
                <PricingFeature>
                  <FontAwesomeIcon icon="chevron-right" />
                  <span>Automated upgrades</span>
                </PricingFeature>
              </FeaturesContainer>
              <Button
                onClick={this.goToDocs}
                theme={{
                  color: COLORS.mainFg,
                  background: COLORS.white
                }}
                containerStyle={css`
                  box-shadow: none;
                  border: 1px solid ${COLORS.mainFg};
                `}
              >
                View the Docs
              </Button>
            </PricingContainer>
            <PricingContainer>
              <div>
                <IconContainer featured>
                  <FontAwesomeIcon icon="dice-d6" size="2x" />
                </IconContainer>
                <PricingPrice>
                  <span>{selectedPlan === "annual" ? "$14" : "$19"}</span>
                  <sub>/ month</sub>
                </PricingPrice>
                <PricingTitle>Business</PricingTitle>
              </div>
              <FeaturesContainer>
                <PricingFeature>
                  <FontAwesomeIcon icon="chevron-right" />
                  <span>Unlimited users</span>
                </PricingFeature>
                <PricingFeature>
                  <FontAwesomeIcon icon="chevron-right" />
                  <span>Unlimited app logging</span>
                </PricingFeature>
                <PricingFeature>
                  <FontAwesomeIcon icon="chevron-right" />
                  <span>Dynamic logging dashboard</span>
                </PricingFeature>
                <PricingFeature>
                  <FontAwesomeIcon icon="chevron-right" />
                  <span>Real-time analytics</span>
                </PricingFeature>
                <PricingFeature>
                  <FontAwesomeIcon icon="chevron-right" />
                  <span>Encrypted communications</span>
                </PricingFeature>
                <PricingFeature>
                  <FontAwesomeIcon icon="chevron-right" />
                  <span>Automated upgrades</span>
                </PricingFeature>
                <PricingFeature>
                  <FontAwesomeIcon icon="chevron-right" />
                  <span>Automated backups</span>
                </PricingFeature>
                <PricingFeature>
                  <FontAwesomeIcon icon="chevron-right" />
                  <span>Prioritized feature requests</span>
                </PricingFeature>
              </FeaturesContainer>
              <Button
                onClick={this.goToSignUp}
                theme={{
                  color: COLORS.white,
                  background: COLORS.brandOrange
                }}
              >
                Get Started
              </Button>
            </PricingContainer>
            <PricingContainer>
              <div>
                <IconContainer>
                  <FontAwesomeIcon icon="dice-d20" size="2x" />
                </IconContainer>
                <PricingPrice>Let&apos;s talk!</PricingPrice>
                <PricingTitle>Enterprise</PricingTitle>
              </div>
              <FeaturesContainer>
                <PricingFeature>
                  <FontAwesomeIcon icon="chevron-right" />
                  <span>On-premise license available</span>
                </PricingFeature>
                <PricingFeature>
                  <FontAwesomeIcon icon="chevron-right" />
                  <span>Unlimited users</span>
                </PricingFeature>
                <PricingFeature>
                  <FontAwesomeIcon icon="chevron-right" />
                  <span>Unlimited app logging</span>
                </PricingFeature>
                <PricingFeature>
                  <FontAwesomeIcon icon="chevron-right" />
                  <span>Dynamic logging dashboard</span>
                </PricingFeature>
                <PricingFeature>
                  <FontAwesomeIcon icon="chevron-right" />
                  <span>Real-time analytics</span>
                </PricingFeature>
                <PricingFeature>
                  <FontAwesomeIcon icon="chevron-right" />
                  <span>Automated upgrades</span>
                </PricingFeature>
                <PricingFeature>
                  <FontAwesomeIcon icon="chevron-right" />
                  <span>Automated backups</span>
                </PricingFeature>
                <PricingFeature>
                  <FontAwesomeIcon icon="chevron-right" />
                  <span>Prioritized feature requests</span>
                </PricingFeature>
                <PricingFeature>
                  <FontAwesomeIcon icon="chevron-right" />
                  <span>HIPAA/PCI/PHI compliance</span>
                </PricingFeature>
                <PricingFeature>
                  <FontAwesomeIcon icon="chevron-right" />
                  <span>Custom OAuth2.0 authorization</span>
                </PricingFeature>
              </FeaturesContainer>
              <Button
                onClick={this.goToSignUp}
                theme={{
                  color: COLORS.mainFg,
                  background: COLORS.white
                }}
                containerStyle={css`
                  box-shadow: none;
                  border: 1px solid ${COLORS.mainFg};
                `}
              >
                Contact Us
              </Button>
            </PricingContainer>
          </Content>
        </ContentContainer>
      </Container>
    );
  }
}

PricingSection.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired
};

export default withRouter(PricingSection);
