/* eslint import/prefer-default-export : off */
import styled from "styled-components";
import { NavLink as RouterNavLink } from "react-router-dom";

import COLORS from "constants/colors";

export const BrandContainer = styled.div`
  display: flex;
  font-family: "Montserrat", sans-serif !important;
  align-items: center;
  color: ${COLORS.brandOrange};
  font-weight: bold;
  font-size: 1.7rem;
  text-transform: uppercase;
  letter-spacing: 0.5rem;
  text-decoration: none;
  padding-bottom: 0.2rem;

  ${props => props.customstyles}
`;

export const NavLink = styled(RouterNavLink)`
  text-decoration: none;
`;
