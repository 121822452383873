import styled from "styled-components";
import COLORS from "constants/colors";
import { Row } from "react-grid-system";

export const FormContainer = styled.div`
  width: 100%;
  max-width: 450px;
  padding: 46px 36px;
`;

export const Title = styled.div`
  font-size: 42px;
  color: ${COLORS.brandOrange};
  font-weight: bold;
  margin-bottom: 24px;
  text-align: center;
`;

export const Description = styled.div`
  font-size: 12px;
  color: ${COLORS.mainFg};
  margin-bottom: 46px;
  text-align: center;
  line-height: 24px;
  font-weight: bold;

  .faded {
    opacity: 0.4;
  }
`;

export const ForgotPassword = styled.div`
  text-align: center;

  .highlight {
    margin-left: 4px;
    color: ${COLORS.brandOrange};
  }
`;

export const Subline = styled.div`
  font-weight: bold;
  font-size: 12px;
  margin: 15px 15px 0;
  text-align: center;
  color: ${COLORS.mainFg};

  span {
    margin-right: 4px;
  }
`;

export const ActionsContainer = styled(Row).attrs({
  align: "center",
  justify: "center"
})`
  margin: 24px 15px 0 !important;
`;
