/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";

// eslint-disable-next-line import/no-cycle
import { Label } from "components";
import { Container, Info } from "./styledElements";

const Input = ({
  label,
  input,
  type,
  meta: { touched, error, warning },
  ...props
}) => (
  <>
    <Container type={type}>
      <Label>{label}</Label>
      <input type={type} {...props} {...input} />
      <Info type="error">
        {touched && (error || warning)}
        &nbsp;
      </Info>
    </Container>
  </>
);

Input.propTypes = {
  input: PropTypes.instanceOf(Object),
  label: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.bool,
    warning: PropTypes.bool
  })
};

Input.defaultProps = {
  input: {},
  label: null,
  type: "text",
  meta: {
    touched: false,
    error: false,
    warning: false
  }
};

export default Input;
