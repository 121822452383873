import { put, takeEvery, all, call } from "redux-saga/effects";

import * as DataServices from "services/data.services";

import {
  fetchStats,
  fetchStatsSuccess,
  fetchStatsFailed,
  loadStats
} from "./actions";

function* onFetchStatsAsync() {
  try {
    const [error, response] = yield call(DataServices.fetchStats);

    if (error || !response.ok) {
      yield put(fetchStatsFailed());
    } else {
      // eslint-disable-next-line camelcase
      const { overview, by_device } = yield response.json();

      const data = {
        overview,
        count: by_device
      };

      yield all([put(fetchStatsSuccess()), put(loadStats(data))]);
    }
  } catch (e) {
    yield put(fetchStatsFailed());
  }
}

function* watchFetchStasAsync() {
  yield takeEvery(fetchStats().type, onFetchStatsAsync);
}

export default function* rootSaga() {
  yield all([watchFetchStasAsync()]);
}

export { watchFetchStasAsync, onFetchStatsAsync };
