import React from "react";

import PropTypes from "prop-types";
import { Container, Row, Col } from "react-grid-system";
import { FRAMEWORKS } from "constants/onboarding";
import { steps, config } from "./config";
import {
  OnboardingContainer,
  ContentCard,
  HeaderContainer,
  Content
} from "./styledElements";
import { Footer, Steps } from "./components";

class Onboarding extends React.Component {
  state = {
    currentStep: 0,
    frameworks: [FRAMEWORKS[0].value]
  };

  setFrameworks = frameworks => {
    this.setState({
      frameworks
    });
  };

  back = () => {
    const { currentStep } = this.state;

    this.setState({
      currentStep: currentStep > 0 ? currentStep - 1 : currentStep
    });
  };

  next = () => {
    const { currentStep } = this.state;

    this.setState(
      {
        currentStep:
          currentStep < steps.length - 1 ? currentStep + 1 : currentStep
      },
      () => {
        if (currentStep >= steps.length - 1) {
          this.goToDashboard();
        }
      }
    );
  };

  goToDashboard = () => {
    const { history } = this.props;
    history.push("/dashboard");
  };

  renderContent = () => {
    const { currentStep, applications, frameworks } = this.state;
    const { Component } = steps[currentStep];

    const props = {
      setApplications: this.setApplications,
      setFrameworks: this.setFrameworks,
      applications,
      frameworks,
      goToDashboard: this.goToDashboard
    };

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Component {...props} />;
  };

  render() {
    const { currentStep } = this.state;
    const { Brand } = config;

    return (
      <OnboardingContainer>
        <Container>
          <HeaderContainer>
            <Brand />
            <Steps currentStep={currentStep} />
          </HeaderContainer>
          <Row>
            <Col xl={12}>
              <ContentCard>
                <Content>{this.renderContent()}</Content>
                <Footer
                  currentStep={currentStep}
                  onPrevious={this.back}
                  onNext={this.next}
                  goToDashboard={this.goToDashboard}
                />
              </ContentCard>
            </Col>
          </Row>
        </Container>
      </OnboardingContainer>
    );
  }
}

Onboarding.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired
};

export default Onboarding;
