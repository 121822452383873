import styled from "styled-components";

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${props => (props.type === "radio" ? "center" : "flex-start")};
  justify-content: space-between;
  width: 100%;
  max-width: 350px;
  border-radius: 4px;
  margin-bottom: 12px;

  input {
    border: none;
    outline: none;
    width: 100%;
    background-color: transparent;
    z-index: 1;
  }
`;

export const Info = styled.div`
  font-size: 0.7rem;
  line-height: 0.7rem;
  color: ${props => {
    if (props.type === "error") return "red";
    if (props.type === "warning") return "orange";
    return "rgba(0, 0, 0, 0.4)";
  }};
`;
