import styled from "styled-components";
import COLORS from "constants/colors";

export const FiltersContainer = styled.div`
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: ${props =>
    ["xs"].includes(props.screen) ? "column" : "row"};

  button {
    border: none;
    outline: none;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    opacity: 1;
    transition: opacity 275ms;
    margin: 0 5px;
    background-color: #dddddd;

    &:hover {
      opacity: 0.7;
    }
  }

  svg {
    padding: 8px;
    font-size: 16px;
    border-radius: 4px;
    margin: 0 5px;
    cursor: pointer;
    transition: color 275ms, background-color 275ms;
  }
`;

export const SearchContainer = styled.div`
  ${props => `
  margin-left: ${["xs"].includes(props.screen) ? "0" : "48px"};
  margin-top: ${["xs"].includes(props.screen) ? "12px" : "0"};
  background-color: #b7b7b738;
  padding-left: 0;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;

  input {
    border: none;
    padding: 0 10px;
    outline: none;
    line-height: 24px;
    background-color: transparent;
    width:  ${["xs"].includes(props.screen) ? "auto" : "150px"};
    transition: width 275ms;

    &:focus {
      width: 180px;
    }
  }

  svg {
    background-color: ${COLORS.brandOrange};
    color: rgb(255, 255, 255);
    padding: 8px;
    font-size: 16px;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin: 0;
  }`}
`;
