import React from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row } from "react-grid-system";
import { ButtonWrapper } from "./styledElements";

const Button = ({ children, theme, loading, loadingText, ...rest }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <ButtonWrapper {...rest} theme={theme}>
    {!loading ? (
      children
    ) : (
      <Row align="center" justify="center">
        <FontAwesomeIcon className="spinner" icon="fan" spin />
        <span>{loadingText}</span>
      </Row>
    )}
  </ButtonWrapper>
);

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.instanceOf(Array).isRequired
  ]).isRequired,
  theme: PropTypes.instanceOf(Object),
  loading: PropTypes.bool,
  loadingText: PropTypes.string
};

Button.defaultProps = {
  theme: undefined,
  loading: false,
  loadingText: "Loading..."
};

export default Button;
