import React from "react";
import PropTypes from "prop-types";
import VirtualList from "react-tiny-virtual-list";

import { Waypoint } from "react-waypoint";
import { Col } from "react-grid-system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { getLogLevelIcon } from "utils/logs";
import { setHasMoreData, fetchMoreLogs } from "redux/modules/logs/actions";
import {
  HeaderContainer,
  LoaderContainer,
  LogContainer,
  ListContainer,
  MessageCol
} from "./styledElements";

class LogsList extends React.PureComponent {
  onSelectLog = log => async () => {
    const { onSelectLog } = this.props;
    const target = document.getElementById("info-container");

    await onSelectLog(log);

    target.scrollIntoView({
      behavior: "smooth",
      block: "nearest"
    });
  };

  onLoadMore = () => {
    const { hasMoreData, onSetHasMoreData, onFetchMoreLogs, data } = this.props;

    if (hasMoreData) {
      onFetchMoreLogs({ offset: data.length });
    } else {
      onSetHasMoreData(false);
    }
  };

  resetHasMore = () => {
    const { onSetHasMoreData } = this.props;

    onSetHasMoreData(true);
  };

  renderItem = ({ index, style }) => {
    const { data, isFetching } = this.props;

    if (index === 0) {
      return (
        <div key={index} style={style}>
          {this.renderHeader()}
        </div>
      );
    }

    if (index === data.length - 1 && !isFetching) {
      return (
        <div key={index} style={style}>
          {this.renderInfiniteLoader()}
        </div>
      );
    }

    if (isFetching) return null;

    const {
      message,
      logOptions: { logLevel, os, osVersion, phoneModel }
    } = data[index];

    return (
      /* eslint-disable-next-line */
      <div key={index} style={style} onClick={this.onSelectLog(data[index])}>
        <LogContainer>
          <Col xl={1} lg={1} md={1} sm={1} xs={1}>
            {getLogLevelIcon(logLevel)}
          </Col>
          <MessageCol>{message}</MessageCol>
          <Col xl={2} lg={2} md={2} sm={2} xs={2}>
            {`${os} / ${osVersion}`}
          </Col>
          <Col xl={2} lg={2} md={2} sm={2} xs={2}>
            {phoneModel}
          </Col>
        </LogContainer>
      </div>
    );
  };

  renderHeader = () => (
    <HeaderContainer>
      <Col xl={1} lg={1} md={1} sm={1} xs={1}>
        Level
      </Col>
      <Col xl={7} lg={7} md={7} sm={7} xs={7}>
        Message
      </Col>
      <Col xl={2} lg={2} md={2} sm={2} xs={2}>
        OS / Version
      </Col>
      <Col xl={2} lg={2} md={2} sm={2} xs={2}>
        Device Model
      </Col>
    </HeaderContainer>
  );

  renderInfiniteLoader = () => {
    const { hasMoreData } = this.props;

    return (
      <Waypoint
        onEnter={this.onLoadMore}
        onLeave={this.resetHasMore}
        bottomOffset={-10}
      >
        <LoaderContainer>
          {hasMoreData && (
            <>
              <FontAwesomeIcon icon="fan" spin />
              <div>Loading more logs...</div>
            </>
          )}
          {!hasMoreData && (
            <>
              <FontAwesomeIcon icon="dot-circle" />
              <div>You have reached the end.</div>
            </>
          )}
        </LoaderContainer>
      </Waypoint>
    );
  };

  render() {
    const { data } = this.props;

    return (
      <ListContainer>
        <VirtualList
          stickyIndices={[0]}
          width="100%"
          height={400}
          itemCount={data.length}
          itemSize={50}
          renderItem={this.renderItem}
        />
      </ListContainer>
    );
  }
}

LogsList.propTypes = {
  data: PropTypes.instanceOf(Array),
  onSelectLog: PropTypes.func.isRequired,
  onSetHasMoreData: PropTypes.func.isRequired,
  onFetchMoreLogs: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  hasMoreData: PropTypes.bool
};

LogsList.defaultProps = {
  data: [],
  isFetching: false,
  hasMoreData: false
};

export default connect(
  state => ({
    isFetching: state.logs.isFetching,
    hasMoreData: state.logs.hasMoreData
  }),
  {
    onSetHasMoreData: setHasMoreData,
    onFetchMoreLogs: fetchMoreLogs
  }
)(LogsList);
