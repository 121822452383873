import React from "react";
import PropTypes from "prop-types";
import LOG_LEVELS, { LOG_LEVELS_COLORS } from "constants/logLevels";
import { FRAMEWORKS } from "constants/onboarding";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Title, Description } from "../../styledElements";
import {
  ApplicationsContainer,
  ApplicationContainer,
  ApplicationName,
  IconsContainer,
  ApplicationsTitle,
  IconImg
} from "./styledElements";

const CreateProject = ({ frameworks, setFrameworks }) => {
  const removeFramework = framework => () => {
    setFrameworks(frameworks.filter(entry => entry !== framework));
  };

  const addFramework = framework => () => {
    setFrameworks([...frameworks, framework]);
  };

  return (
    <Container>
      <IconsContainer>
        <FontAwesomeIcon
          icon="mobile-alt"
          size="xs"
          color={LOG_LEVELS_COLORS[LOG_LEVELS.DEFAULT]}
        />
        <FontAwesomeIcon
          icon="desktop"
          size="xs"
          color={LOG_LEVELS_COLORS[LOG_LEVELS.WARNING]}
        />
        <FontAwesomeIcon
          icon="tablet-alt"
          size="xs"
          color={LOG_LEVELS_COLORS[LOG_LEVELS.ERROR]}
        />
      </IconsContainer>
      <Title>Create a project</Title>
      <Description>
        To get started, tell us a little bit about your application.
      </Description>
      <ApplicationsTitle>Choose a technology</ApplicationsTitle>
      {/* <TagsContainer>
        {FRAMEWORKS.map(framework => (
          <Tag
            key={framework.value}
            selected={frameworks.includes(framework.value)}
          >
            <span>{framework.label}</span>
            <FontAwesomeIcon
              icon={frameworks.includes(framework.value) ? "times" : "check"}
              onClick={
                frameworks.includes(framework.value)
                  ? removeFramework(framework.value)
                  : addFramework(framework.value)
              }
            />
          </Tag>
        ))}
      </TagsContainer> */}
      <ApplicationsContainer>
        {FRAMEWORKS.map(framework => (
          <ApplicationContainer
            disabled={framework.disabled}
            key={framework.value}
            background={framework.background}
            selected={frameworks.includes(framework.value)}
            onClick={
              !framework.disabled &&
              (frameworks.includes(framework.value)
                ? removeFramework(framework.value)
                : addFramework(framework.value))
            }
          >
            {typeof framework.icon === "object" ? (
              <FontAwesomeIcon icon={framework.icon} />
            ) : (
              <IconImg src={framework.icon} />
            )}
            <ApplicationName
              disabled={framework.disabled}
              selected={frameworks.includes(framework.value)}
            >
              {framework.label}
            </ApplicationName>
          </ApplicationContainer>
        ))}
      </ApplicationsContainer>
    </Container>
  );
};

CreateProject.propTypes = {
  frameworks: PropTypes.instanceOf(Array).isRequired,
  setFrameworks: PropTypes.func.isRequired
};

export default CreateProject;
