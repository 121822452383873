import React from "react";

import {
  Container,
  DashboardImage,
  ListIcon,
  CircleIcon,
  ChartIcon
} from "./styledElements";
import { Headline, Subline } from "../../styledElements";

const dashboardImg = require("assets/dashboard.png");

const SecondSection = () => (
  <Container id="product">
    <CircleIcon />
    <ListIcon />
    <ChartIcon />
    <div>
      <Headline>An intuitive, easy-to-use dashboard</Headline>
      <Subline>
        Many monitoring tools can be a chore to use and maintain. That&rsquo;s
        why our dashboard is designed to be simple and intuitive from the start.
        Easily search, filter, and review each logged record.
      </Subline>
    </div>
    <DashboardImage src={dashboardImg} />
  </Container>
);

export default SecondSection;
