export const STAT_ITEMS = [
  {
    id: "logs-value",
    key: "logs",
    color: "#0189b6",
    label: "Logs"
  },
  {
    id: "errors-value",
    key: "errors",
    color: "#cc0000",
    label: "Errors"
  },
  {
    id: "warnings-value",
    key: "warnings",
    color: "#de8614",
    label: "Warnings"
  },
  {
    id: "devices-value",
    key: "devices",
    color: "#de8614",
    label: "Devices"
  }
];

export const COUNT_ITEMS = [
  {
    id: "android",
    key: "android",
    label: "Android"
  },
  {
    id: "iOS",
    key: "iOS",
    label: "iOS"
  },
  {
    id: "web",
    key: "web",
    label: "Web"
  }
];
