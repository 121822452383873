import styled from "styled-components";

import { NavLink } from "react-router-dom";

import COLORS from "constants/colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px;
  padding: 0px;
  width: 100%;
`;

export const Logo = styled(NavLink)`
  display: inline;
  margin: 0 !important;
  padding: 0px !important;
  flex: 0;
  font-family: "Rubik", sans-serif;
  font-size: 2rem;
  text-align: center;
  text-decoration: none;
  color: ${COLORS.brandOrange};

  &:hover {
    color: ${COLORS.brandOrange};
  }
`;
