import { NavLink as RouterNavLink } from "react-router-dom";
import { NavHashLink as RouterNavHashLink } from "react-router-hash-link";
import styled from "styled-components";

import COLORS from "constants/colors";

export const NavLink = styled(RouterNavLink)`
  color: ${props => props.linkColor};
  text-decoration: ${props => (props.hasUnderline ? "underline" : "none")};

  &:link {
    color: ${props => props.linkColor};
  }

  &:active {
    color: ${props => props.activeColor};
  }

  &:visited {
    color: ${props => props.visitedColor};
  }
`;

NavLink.defaultProps = {
  linkColor: COLORS.brandOrange,
  activeColor: COLORS.primary_light,
  visitedColor: COLORS.brandOrange,
  hasUnderline: false
};

export const NavHashLink = styled(RouterNavHashLink)`
  color: ${props => props.linkColor};
  text-decoration: ${props => (props.hasUnderline ? "underline" : "none")};

  &:link {
    color: ${props => props.linkColor};
  }

  &:active {
    color: ${props => props.activeColor};
  }

  &:visited {
    color: ${props => props.visitedColor};
  }
`;

NavHashLink.defaultProps = {
  linkColor: COLORS.brandOrange,
  activeColor: COLORS.primary_light,
  visitedColor: COLORS.brandOrange,
  hasUnderline: false
};

export const Link = styled.a`
  color: ${props => props.linkColor};
  text-decoration: ${props => (props.hasUnderline ? "underline" : "none")};

  &:link {
    color: ${props => props.linkColor};
  }

  &:active {
    color: ${props => props.activeColor};
  }

  &:visited {
    color: ${props => props.visitedColor};
  }
`;

Link.defaultProps = {
  linkColor: COLORS.brandOrange,
  activeColor: COLORS.primary_light,
  visitedColor: COLORS.brandOrange,
  hasUnderline: false
};
