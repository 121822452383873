/* eslint import/prefer-default-export: off */
import styled from "styled-components";
import COLORS from "../../colors";

export const SectionTitle = styled.div`
  font-size: 20px;
  color: ${COLORS.color};
  margin: 24px 0 8px;
  font-weight: bold;
`;

export const CautionText = styled.span`
  color: ${COLORS.activeStep};
`;
