import React from "react";
import PropTypes from "prop-types";

import { css } from "react-grid-system";
import { BrandContainer, NavLink } from "./styledElements";

const Brand = ({ containerStyle, isLink }) =>
  isLink ? (
    <NavLink to="/">
      <BrandContainer customstyles={containerStyle}>kalayo</BrandContainer>
    </NavLink>
  ) : (
    <BrandContainer customstyles={containerStyle}>kalayo</BrandContainer>
  );

Brand.propTypes = {
  containerStyle: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.instanceOf(css)
  ]),
  isLink: PropTypes.bool
};

Brand.defaultProps = {
  containerStyle: {},
  isLink: true
};

export default Brand;
