const LOG_LEVELS = {
  DEFAULT: "default",
  WARNING: "warning",
  ERROR: "error"
};

const LOG_LEVELS_BG_COLORS = {
  [LOG_LEVELS.DEFAULT]: "rgba(1, 137, 182, 0.22)",
  [LOG_LEVELS.WARNING]: "rgba(222, 134, 20, 0.22)",
  [LOG_LEVELS.ERROR]: "rgba(204, 0, 0, 0.22)"
};

const LOG_LEVELS_COLORS = {
  [LOG_LEVELS.DEFAULT]: "#0189b6",
  [LOG_LEVELS.WARNING]: "#de8614",
  [LOG_LEVELS.ERROR]: "#cc0000"
};

export { LOG_LEVELS_BG_COLORS, LOG_LEVELS_COLORS };
export default LOG_LEVELS;
