import { Brand } from "components";
// eslint-disable-next-line import/no-cycle
import { CreateProject, Integration, Testing } from "./components";

export const config = {
  title: "Kalayo",
  Brand,
  homepage: "/"
};

export const steps = [
  // {
  //   title: "Create an app",
  //   Component: CreateProject
  // },
  {
    title: "Create a project",
    Component: CreateProject
  },
  {
    title: "Integration",
    Component: Integration
  },
  {
    title: "Start testing",
    Component: Testing
  }
];
