import React from "react";
import PropTypes from "prop-types";

import {
  InfoContainer,
  InfoTitle,
  InfoDescription,
  StackTraceContainer
} from "./styledElements";

class LogInfo extends React.PureComponent {
  state = {
    containerWidth: 0
  };

  containerRef = React.createRef();

  componentDidMount = () => {
    this.setState({
      containerWidth: this.containerRef.current.clientWidth
    });
  };

  render() {
    const { containerWidth } = this.state;
    const { log } = this.props;

    return (
      <InfoContainer ref={this.containerRef}>
        <InfoTitle>Log Message</InfoTitle>
        <InfoDescription>{log.message}</InfoDescription>
        <InfoTitle>Log Stacktrace</InfoTitle>
        <StackTraceContainer containerWidth={containerWidth}>
          {log.stackTrace}
        </StackTraceContainer>
      </InfoContainer>
    );
  }
}

LogInfo.propTypes = {
  log: PropTypes.instanceOf(Object)
};

LogInfo.defaultProps = {
  log: {}
};

export default LogInfo;
