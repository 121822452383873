export const set = (key, value) => {
  try {
    localStorage.setItem(key, value);
    return true;
  } catch (err) {
    return err;
  }
};

export const get = key => {
  try {
    const value = localStorage.getItem(key);
    return value;
  } catch (err) {
    return err;
  }
};

export const unset = key => {
  try {
    localStorage.removeItem(key);
    return true;
  } catch (err) {
    return err;
  }
};

export const clear = () => {
  try {
    localStorage.clear();
    return true;
  } catch (err) {
    return err;
  }
};
