import { handleActions, combineActions } from "redux-actions";
import {
  createAccount,
  createAccountSuccess,
  createAccountFailed
} from "./actions";

const DEFAULT_STATE = {
  isSigningUp: false
};

export default handleActions(
  {
    [createAccount]: state => ({
      ...state,
      isSigningUp: true
    }),
    [combineActions(createAccountSuccess, createAccountFailed)]: state => ({
      ...state,
      isSigningUp: false
    })
  },
  DEFAULT_STATE
);
