import styled from "styled-components";
import colors from "constants/colors";

// eslint-disable-next-line import/prefer-default-export
export const ButtonWrapper = styled.button`
  font-size: 16px;
  min-width: 150px;
  padding: 16px 24px;
  border: none;
  border-radius: 8px;
  color: ${props => props.theme.color};
  background: ${props => props.theme.background};
  position: relative;
  box-shadow: 0px 2px 10px 5px rgba(0, 0, 0, 0.075);
  transition: all 0.1s ease-in;
  cursor: pointer;

  ${props =>
    !props.disabled &&
    `&:hover {
      transform: translateY(-2px);
      box-shadow: 0px 3px 10px 5px rgba(0, 0, 0, 0.1);
    }
    
    &:active {
      transform: translateY(3px);
      box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.4);
    }`}

  svg {
    margin-left: 10px;
  }

  ${props =>
    props.disabled &&
    `
  opacity: 0.3;
  cursor: not-allowed;
  `}

  .spinner {
    margin-right: 4px;
  }

  ${props => props.containerStyle};
`;

ButtonWrapper.defaultProps = {
  theme: {
    color: colors.mainBg,
    background: colors.mainFg
  }
};
