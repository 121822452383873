import styled from "styled-components";
import { Row } from "react-grid-system";
import { getLogLevelBg } from "utils/logs";

export const InfoContainer = styled.div`
  padding: 24px;
  background-color: ${props => getLogLevelBg(props.logLevel)};
  color: #43486a;
  border-radius: 4px;
`;

export const Detail = styled(Row).attrs(() => ({
  align: "center"
}))`
  margin: 0 !important;
  margin-bottom: 12px !important;

  .value {
    font-weight: bold;
    margin-left: 10px;
  }

  &:last-of-type {
    margin-bottom: 0 !important;
  }
`;

export const MetaDataContainer = styled.div`
  ${props => `
  margin-top: ${["xs", "sm"].includes(props.screen) ? "36px" : "0"};
  border-left: ${
    ["xs", "sm"].includes(props.screen)
      ? "none"
      : "  1px solid rgba(0, 0, 0, 0.05)"
  };
  padding: ${["xs", "sm"].includes(props.screen) ? "0" : "0 24px"};


  pre {
    font-size: 14px;
    max-width: 100%;
    overflow-x: auto;
  }`}
`;
