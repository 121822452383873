/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import { toast } from "react-toastify";

import { clearAuthTokens } from "services/storage.services";

import ERROR_MESSAGES from "constants/errors";
import {
  createSignupApi,
  createAuthSignupApi,
  createApi,
  createAuthApi
} from "../createApi";
import { AuthLogin, AuthGetUserInfo } from "../auth";

export const createAccount = async ({ email, name, company, password }) => {
  const [error, response] = await createSignupApi("/v1/organizations")({
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      email,
      name,
      companyName: company,
      password,
      product: ["KALAYO"]
    })
  })
    .then(res => [null, res])
    .catch(err => [err]);

  if (error) return { error: error.message };

  const { status, ok } = response;
  const body = await response.json();

  if (!ok) {
    if (body && body.message) {
      return { error: body.message };
    }
    if (status === 400) {
      return { error: ERROR_MESSAGES.CORRUPTED_PAYLOAD };
    }
    if (status === 401) {
      return { error: ERROR_MESSAGES.UNAUTHORIZED };
    }
    if (status === 403) {
      return { error: ERROR_MESSAGES.FORBIDDEN };
    }
    if (status === 500) {
      return {
        error: ERROR_MESSAGES.SERVER_ERROR
      };
    }
    return {
      error: ERROR_MESSAGES.UNKNOWN
    };
  }

  return { response: body };
};

export const subscribe = async ({ pricing, promoCode, id }) => {
  const [error, response] = await createAuthSignupApi("/v1/plan")({
    method: "POST",
    body: JSON.stringify({
      org_id: Number(id),
      pricing,
      promo_code: promoCode
    })
  })
    .then(res => [null, res])
    .catch(err => [err]);

  if (error) return { error: error.message };

  const { status, ok } = response;

  const body = await response.json();

  if (!ok) {
    if (body && body.message) {
      return { error: body.message };
    }
    if (status === 400) {
      return { error: ERROR_MESSAGES.CORRUPTED_PAYLOAD };
    }
    if (status === 401) {
      return { error: ERROR_MESSAGES.UNAUTHORIZED };
    }
    if (status === 403) {
      return { error: ERROR_MESSAGES.FORBIDDEN };
    }
    if (status === 500) {
      return {
        error: ERROR_MESSAGES.SERVER_ERROR
      };
    }
    return {
      error: ERROR_MESSAGES.UNKNOWN
    };
  }

  return { response: body.message };
};

export const changeSubscription = async ({
  subscription,
  upgrade = true
} = {}) => {
  const endpoint = upgrade ? "/v1/upgrade-plan" : "/v1/downgrade-plan";

  const [error, response] = await createAuthSignupApi(endpoint)({
    method: "PUT",
    body: JSON.stringify({
      subscription
    })
  })
    .then(res => [null, res])
    .catch(err => [err]);

  if (error) return { error: error.message };

  const { status, ok } = response;
  const body = await response.json();

  if (!ok) {
    if (body && body.message) {
      return { error: body.message };
    }
    if (status === 400) {
      return { error: ERROR_MESSAGES.CORRUPTED_PAYLOAD };
    }
    if (status === 401) {
      return { error: ERROR_MESSAGES.UNAUTHORIZED };
    }
    if (status === 403) {
      return { error: ERROR_MESSAGES.FORBIDDEN };
    }
    if (status === 500) {
      return {
        error: ERROR_MESSAGES.SERVER_ERROR
      };
    }
    return {
      error: ERROR_MESSAGES.UNKNOWN
    };
  }

  return { response: body };
};

export const getSubscription = async () => {
  const [error, response] = await createAuthSignupApi(`/v1/subscriptions`)({
    method: "GET"
  })
    .then(res => [null, res])
    .catch(err => [err]);

  if (error) return { error: error.message };

  const { status, ok } = response;

  if (!ok) {
    if (status === 400) {
      return { error: ERROR_MESSAGES.SERVER_ERROR };
    }
    return {
      error: ERROR_MESSAGES.UNKNOWN
    };
  }

  const body = await response.json();
  return { response: body };
};

export const deleteSubscription = async () => {
  const [error, response] = await createAuthSignupApi(`/v1/plan`)({
    method: "DELETE"
  })
    .then(res => [null, res])
    .catch(err => [err]);

  if (error) return { error: error.message };

  const { status, ok } = response;

  const contentType = response.headers.get("content-type");
  let body;
  if (contentType && contentType.indexOf("application/json") !== -1)
    body = await response.json();
  else body = await response.text();

  if (!ok) {
    if (body && body.message) {
      return { error: body.message };
    }
    if (status === 400) {
      return { error: ERROR_MESSAGES.CORRUPTED_PAYLOAD };
    }
    if (status === 401) {
      return { error: ERROR_MESSAGES.UNAUTHORIZED };
    }
    if (status === 403) {
      return { error: ERROR_MESSAGES.FORBIDDEN };
    }
    if (status === 500) {
      return {
        error: ERROR_MESSAGES.SERVER_ERROR
      };
    }
    return {
      error: ERROR_MESSAGES.UNKNOWN
    };
  }

  return { response: body };
};

export const login = async ({ email = "", password = "" } = {}) =>
  new Promise(resolve => {
    AuthLogin(email, password, (error, result) => {
      if (!error) {
        resolve([null, result]);
      } else {
        resolve([error]);
      }
    });
  });

export const getUserInfo = async ({ accessToken } = {}) =>
  new Promise(resolve => {
    AuthGetUserInfo(accessToken, (userError, user) => {
      if (!userError) {
        if (process.env.NODE_ENV !== "development") {
          const { email_verified: verified } = user;

          if (!verified) {
            // Logout the user if unverified
            clearAuthTokens();
            resolve([{ message: "Email address is not verified." }]);
          }

          resolve([null, { user }]);
        } else {
          resolve([null, { user }]);
        }
      }

      resolve([{ message: ERROR_MESSAGES.UNKNOWN }]);
    });
  });

export const changeEmail = async () =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve([null, { ok: true }]);
    }, 2000);
  });

export const requestResetPassword = async ({ email }) => {
  const [error, response] = await createApi("/v1/request-change")({
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      email
    })
  });

  if (error) return { error: ERROR_MESSAGES.UNKNOWN };

  // Expect a plain string as a response
  const body = await response.json();
  const { status, ok } = response;

  // Return error based on status code
  if (!ok) {
    if (status === 400) {
      return { error: ERROR_MESSAGES.CORRUPTED_PAYLOAD };
    }
    if (status === 401) {
      return { error: ERROR_MESSAGES.UNAUTHORIZED };
    }
    if (status === 403) {
      return { error: ERROR_MESSAGES.FORBIDDEN };
    }
    if (status === 404) {
      return { error: ERROR_MESSAGES.FORGOT_PASSWORD_404 };
    }
    if (status === 500) {
      return { error: ERROR_MESSAGES.SERVER_ERROR };
    }
    return {
      error: ERROR_MESSAGES.UNKNOWN
    };
  }

  return { response: body };
};

export const resetPassword = async ({ email, password, token }) => {
  const [error, response] = await createApi(
    `/v1/forgot-password/${encodeURI(email)}`
  )({
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      password
    })
  });

  if (error) return { error: error.message };

  // Expect a plain string as a response
  const body = await response.json();
  const { status, ok } = response;

  // Return error based on status code
  if (!ok) {
    if (status === 400) {
      return { error: ERROR_MESSAGES.CORRUPTED_PAYLOAD };
    }
    if (status === 401) {
      return { error: ERROR_MESSAGES.UNAUTHORIZED };
    }
    if (status === 403) {
      return { error: ERROR_MESSAGES.FORBIDDEN };
    }
    if (status === 500) {
      return { error: ERROR_MESSAGES.SERVER_ERROR };
    }
    return {
      error: ERROR_MESSAGES.UNKNOWN
    };
  }

  return { response: body };
};

export const deactivateAccount = async () =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve([null, { ok: true }]);
    }, 2000);
  });

export const setBillingInfo = async ({
  contactName = "",
  email = "",
  entityName = "",
  entityType = "",
  businessType = "",
  businessAddress = "",
  paymentMethod = ""
}) => {
  const [error, response] = await createAuthSignupApi("/v1/billing-info")({
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      contactName,
      email,
      entityName,
      entityType,
      businessType,
      businessAddress,
      paymentMethod
    })
  })
    .then(res => [null, res])
    .catch(err => [err]);

  if (error) return { error: error.message };

  // Expect a plain string as a response
  const body = await response.text();
  const { status, ok } = response;

  // Return error based on status code
  if (!ok) {
    if (status === 400) {
      return { error: ERROR_MESSAGES.CORRUPTED_PAYLOAD };
    }
    if (status === 401) {
      return { error: ERROR_MESSAGES.UNAUTHORIZED };
    }
    if (status === 403) {
      return { error: ERROR_MESSAGES.FORBIDDEN };
    }
    if (status === 500) {
      return { error: ERROR_MESSAGES.SERVER_ERROR };
    }
    return {
      error: ERROR_MESSAGES.UNKNOWN
    };
  }

  return { response: body };
};

export const getBillingInfo = async () => {
  const [error, response] = await createAuthSignupApi("/v1/billing-info")({
    method: "GET"
  })
    .then(res => [null, res])
    .catch(err => [err]);

  if (error) return { error: error.message };

  // Expect a JSON object response and read it
  const body = await response.json();
  const { status, ok } = response;

  // Return error based on status code
  if (!ok) {
    if (status === 400) {
      return { error: ERROR_MESSAGES.CORRUPTED_PAYLOAD };
    }
    if (status === 401) {
      return { error: ERROR_MESSAGES.UNAUTHORIZED };
    }
    if (status === 403) {
      return { error: ERROR_MESSAGES.FORBIDDEN };
    }
    if (status === 500) {
      return { error: ERROR_MESSAGES.SERVER_ERROR };
    }
    return {
      error: ERROR_MESSAGES.UNKNOWN
    };
  }

  return { response: body };
};

export const inviteByEmail = async ({ email, name, contact_no }) => {
  const [error, response] = await createAuthApi("/v1/users")({
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      contact_no,
      email,
      name
    })
  });

  if (error) return { error: error.message };

  const body = await response.json();
  const { status, ok } = response;

  if (!ok) {
    if (status === 400) {
      return { error: ERROR_MESSAGES.CORRUPTED_PAYLOAD };
    }
    if (status === 401) {
      return { error: ERROR_MESSAGES.UNAUTHORIZED };
    }
    if (status === 403) {
      return { error: ERROR_MESSAGES.FORBIDDEN };
    }
    if (status === 409) {
      return { error: ERROR_MESSAGES.INVITE_USER_409 };
    }
    if (status === 500) {
      return {
        error: ERROR_MESSAGES.SERVER_ERROR
      };
    }
    return {
      error: ERROR_MESSAGES.UNKNOWN
    };
  }

  return { response: body };
};

export const getOrgUsers = async () => {
  const [error, response] = await createAuthApi("/v1/users")({
    method: "GET",
    headers: { "Content-Type": "application/json" }
  });

  if (error) return { error: error.message };

  const body = await response.json();
  const { status, ok } = response;

  if (!ok) {
    if (body && body.message) {
      return { error: body.message };
    }
    if (status === 400) {
      return { error: ERROR_MESSAGES.CORRUPTED_PAYLOAD };
    }
    if (status === 401) {
      return { error: ERROR_MESSAGES.UNAUTHORIZED };
    }
    if (status === 403) {
      return { error: ERROR_MESSAGES.FORBIDDEN };
    }
    if (status === 500) {
      return {
        error: ERROR_MESSAGES.SERVER_ERROR
      };
    }
    return {
      error: ERROR_MESSAGES.UNKNOWN
    };
  }

  return { response: body };
};
