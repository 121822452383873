import React, { useState } from "react";
import PropTypes from "prop-types";
import Popover from "react-tiny-popover";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Row, Col } from "react-grid-system";
import { connect } from "react-redux";
import { Avatar, Brand } from "components";
import { HeaderContainer, RightContainer } from "./styledElements";
import { UserPopover } from "./components";

const Header = ({ history, name, picture, email }) => {
  const [userPopoverVisible, toggleUserPopover] = useState(false);

  return (
    <HeaderContainer>
      <Container>
        <Row justify="between" align="center">
          <Col>
            <Brand />
          </Col>
          <RightContainer>
            <Row justify="end" align="center" nogutter>
              <Avatar src={picture} />
              <Popover
                isOpen={userPopoverVisible}
                padding={0}
                position="bottom"
                align="end"
                onClickOutside={() => toggleUserPopover(false)}
                content={
                  /* eslint-disable-next-line */
                  <UserPopover
                    name={name}
                    picture={picture}
                    email={email}
                    onClose={() => toggleUserPopover(false)}
                    history={history}
                  />
                }
              >
                <Row
                  justify="end"
                  align="center"
                  nogutter
                  onClick={() => toggleUserPopover(!userPopoverVisible)}
                >
                  <span>{`Hi, ${name}!`}</span>
                  <FontAwesomeIcon icon="caret-down" />
                </Row>
              </Popover>
            </Row>
          </RightContainer>
        </Row>
      </Container>
    </HeaderContainer>
  );
};

Header.propTypes = {
  name: PropTypes.string,
  history: PropTypes.instanceOf(Object).isRequired,
  picture: PropTypes.string,
  email: PropTypes.string
};

Header.defaultProps = {
  name: null,
  picture: null,
  email: null
};

export default connect(state => ({
  name: state.account.profile.name,
  picture: state.account.profile.picture,
  email: state.account.profile.email
}))(Header);
