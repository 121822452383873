import React from "react";
import PropTypes from "prop-types";

import { Row, Col, ScreenClassRender } from "react-grid-system";

import { getLogLevelIcon } from "utils/logs";

import { Detail, InfoContainer, MetaDataContainer } from "./styledElements";

const DeviceInfo = ({ log }) => {
  const { logOptions } = log;
  const { logLevel, phoneModel, os, osVersion } = logOptions;

  return (
    <ScreenClassRender
      render={screen => (
        <InfoContainer logLevel={logLevel}>
          <Row>
            <Col xl={6} lg={6} md={6}>
              <Detail>
                <span>{getLogLevelIcon(logLevel)}</span>
              </Detail>
              <Detail>
                <span>Device Model:</span>
                <span className="value">{phoneModel}</span>
              </Detail>
              <Detail>
                <span>Operating System:</span>
                <span className="value">{os}</span>
              </Detail>
              <Detail>
                <span>OS Version:</span>
                <span className="value">{osVersion}</span>
              </Detail>
            </Col>
            <Col xl={6} lg={6} md={6}>
              <MetaDataContainer screen={screen}>
                <div>
                  <b>Log Options Metadata</b>
                </div>
                <pre>{JSON.stringify(logOptions, undefined, 2)}</pre>
              </MetaDataContainer>
            </Col>
          </Row>
        </InfoContainer>
      )}
    />
  );
};

DeviceInfo.propTypes = {
  log: PropTypes.instanceOf(Object)
};

DeviceInfo.defaultProps = {
  log: {
    logOptions: {}
  }
};

export default DeviceInfo;
