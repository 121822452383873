/* eslint-disable react/jsx-one-expression-per-line */
import React from "react";

import {
  Container,
  FooterLinks,
  TeamLink,
  Link,
  Left,
  Center,
  Right
} from "./styledElements";

const Footer = () => (
  <Container>
    <Left>
      <FooterLinks>
        <li>
          <Link to="/privacy">Privacy &amp; Terms</Link>
        </li>
      </FooterLinks>
    </Left>
    <Center>
      <FooterLinks>
        <li>© Kalayo 2019</li>
      </FooterLinks>
    </Center>
    <Right>
      <FooterLinks>
        <li>
          <TeamLink href="https://amagi.io">AMAGI</TeamLink>
        </li>
      </FooterLinks>
    </Right>
  </Container>
);

export default Footer;
