import styled from "styled-components";

import { NavHashLink as NavLink } from "react-router-hash-link";

import COLORS from "constants/colors";

export const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  top: 0;
`;

export const NavItem = styled(NavLink).attrs({
  smooth: true
})`
  width: 100%;
  text-decoration: none;
  padding: 10px 20px;
  font-size: 0.9rem;
  color: ${COLORS.mainFg};

  &:hover {
    color: ${COLORS.brandOrange};
    transition: 0.2s all ease-in;
  }
`;

export const SubLink = styled(NavItem)`
  margin-left: 10px;
  opacity: 0.8;
`;
