import React from "react";
import PropTypes from "prop-types";

import { Row } from "react-grid-system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// eslint-disable-next-line import/no-cycle
import { steps } from "../../config";
import {
  SkipLink,
  Container,
  Content,
  BackButton,
  ContinueButton
} from "./styledElements";

const Footer = ({ currentStep, onPrevious, onNext, goToDashboard }) => (
  <Container>
    <Content>
      <SkipLink onClick={goToDashboard}>Skip to Dashboard</SkipLink>
      <Row>
        {currentStep > 0 && (
          <BackButton onClick={onPrevious}>
            <FontAwesomeIcon icon="chevron-left" />
            <span>Back</span>
          </BackButton>
        )}
        <ContinueButton onClick={onNext}>
          {(currentStep < steps.length - 1 && (
            <span>
              Next
              <FontAwesomeIcon icon="chevron-right" />
            </span>
          )) || <span>Finish</span>}
        </ContinueButton>
      </Row>
    </Content>
  </Container>
);

Footer.propTypes = {
  currentStep: PropTypes.number.isRequired,
  onPrevious: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  goToDashboard: PropTypes.func.isRequired
};

export default Footer;
