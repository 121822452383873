/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
import { createActions } from "redux-actions";

const {
  createAccount,
  createAccountSuccess,
  createAccountFailed
} = createActions({
  CREATE_ACCOUNT: data => data,
  CREATE_ACCOUNT_SUCCESS: null,
  CREATE_ACCOUNT_FAILED: null
});

export { createAccount, createAccountSuccess, createAccountFailed };
