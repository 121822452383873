import React from "react";

import { ResetPasswordForm } from "./components";
import { LoginContainer, FormContainer, Navbar } from "../Login/styledElements";

import { Logo } from "../LandingPage/components/HeaderSection/styledElements";

const ResetPassword = () => (
  <LoginContainer>
    <Navbar>
      <Logo to="/">kalayo</Logo>
    </Navbar>
    <FormContainer>
      <ResetPasswordForm />
    </FormContainer>
  </LoginContainer>
);

export default ResetPassword;
