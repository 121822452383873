import styled from "styled-components";
import { Row } from "react-grid-system";
import COLORS from "./colors";

export const CardContainer = styled.div`
  padding: 30px;
  background-color: ${COLORS.cardBackground};
  border-radius: 8px;
  box-shadow: 0px 3px 25px 0px rgba(0, 0, 0, 0.075);
  position: relative;
`;

export const OnboardingContainer = styled.div`
  background-color: ${COLORS.background};
`;

export const HeaderContainer = styled(Row).attrs({
  align: "center",
  justify: "space-between"
})`
  margin: 0 !important;
  height: 90px;
  font-weight: bold;
  font-size: 20px;
  color: ${COLORS.color};
`;

export const ContentCard = styled(CardContainer)`
  height: 100%;
  position: relative;
  overflow: hidden;
`;

export const Content = styled.div`
  max-height: calc(70vh - 76px);
  height: calc(70vh - 76px);
  overflow-y: auto;
`;

export const StepsContainer = styled.div`
  position: relative;
  height: 50px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px 36px;
  flex-grow: 1;
  font-size: 14px;

  pre {
    word-wrap: break-word;
    white-space: break-spaces;
  }

  // pre,
  // code {
  //   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  //     monospace !important;
  //   background-color: #f8f8f8;
  //   padding: 5px 10px;
  //   border-radius: 4px;
  //   margin: 8px 0;
  //   font-size: 12px;
  //   line-height: 16px;
  // }
`;

export const Title = styled.div`
  font-size: 2.2rem;
  font-weight: normal;
  margin-bottom: 12px;
  color: ${COLORS.color};
`;

export const Description = styled.div`
  color: ${COLORS.color};
  opacity: 0.5;
  margin-bottom: 36px;
  font-size: 16px;
  max-width: 450px;
  line-height: 24px;
`;
