import React from "react";
import PropTypes from "prop-types";
import COLORS from "constants/colors";

import { toast } from "react-toastify";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { resetPassword } from "services/data.services";

import SUCCESS_MESSAGES from "constants/success";
import * as validator from "utils/validate";

import { Button, FormComponent, NavLink } from "components";
import {
  LoginFormContainer,
  Subline,
  ActionsContainer,
  // ForgotPassword,
  Title,
  Description
} from "../../Login/components/styledElements";

const validate = values => {
  const errors = {};
  const { email, password, repeatPassword } = values;

  errors.email = validator.required(email) || validator.email(email);
  errors.password =
    validator.required(password) || validator.minPasswordLength(password);
  errors.repeatPassword = validator.matchingPasswords(password, repeatPassword);

  return errors;
};

class ResetPasswordForm extends React.PureComponent {
  state = {
    isLoading: false
  };

  toggleLoading = () => {
    const { isLoading } = this.state;

    this.setState({
      isLoading: !isLoading
    });
  };

  submitForm = async values => {
    const { email, password } = values;
    const { match, history } = this.props;
    const {
      params: { token }
    } = match;

    console.log(match);
    this.toggleLoading();

    const { error } = await resetPassword({ email, password, token });

    if (error) {
      this.toggleLoading();
      toast.error(error);

      return;
    }

    this.toggleLoading();
    toast.success(SUCCESS_MESSAGES.RESET_PASSWORD_SUCCESS);
    history.push("/account/login");
  };

  render() {
    const { isLoading } = this.state;
    const { valid, handleSubmit } = this.props;

    return (
      <>
        <LoginFormContainer>
          <Title>Change your password</Title>
          <Description>
            <div className="faded">Change your password below.</div>
          </Description>
          <form
            onSubmit={handleSubmit(this.submitForm)}
            className="resetPassword"
          >
            <Field
              name="email"
              type="email"
              label="Email"
              component={FormComponent}
              layout={{ sm: 12 }}
            />
            <Field
              name="password"
              type="password"
              label="New Password"
              component={FormComponent}
              layout={{ sm: 12 }}
            />
            <Field
              name="repeatPassword"
              type="password"
              label="Confirm New Password"
              component={FormComponent}
              layout={{ sm: 12 }}
            />
            <ActionsContainer>
              <Button
                type="submit"
                disabled={!valid || isLoading}
                loading={isLoading}
                theme={{
                  background: COLORS.brandOrange,
                  color: COLORS.white
                }}
              >
                Change Password
              </Button>
            </ActionsContainer>
            <Subline>
              <span>Don&apos;t have an account yet?</span>
              <NavLink to="/account/signup">Sign up now.</NavLink>
            </Subline>
          </form>
        </LoginFormContainer>
      </>
    );
  }
}

ResetPasswordForm.propTypes = {
  valid: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired
};

ResetPasswordForm.defaultProps = {
  valid: false
};

const selector = formValueSelector("resetPassword");

export default reduxForm({
  form: "resetPassword",
  validate,
  onSubmit: () => {}
})(
  connect(state => ({
    email: selector(state, "email"),
    password: selector(state, "password"),
    repeatPassword: selector(state, "repeatPassword")
  }))(withRouter(ResetPasswordForm))
);
