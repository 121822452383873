import React from "react";

import { LoginForm } from "./components";
import { LoginContainer, FormContainer, Navbar } from "./styledElements";

import { Logo } from "../LandingPage/components/HeaderSection/styledElements";

const Login = () => (
  <LoginContainer>
    <Navbar>
      <Logo to="/">kalayo</Logo>
    </Navbar>
    <FormContainer>
      <LoginForm />
    </FormContainer>
  </LoginContainer>
);

export default Login;
