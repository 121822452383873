import React from "react";
import PropTypes from "prop-types";

import { css } from "react-grid-system";
import { AvatarContainer } from "./styledElements";

const Avatar = ({ src, containerStyle }) => (
  <AvatarContainer customstyles={containerStyle} src={src} />
);

Avatar.propTypes = {
  src: PropTypes.string,
  containerStyle: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.instanceOf(css)
  ])
};

Avatar.defaultProps = {
  src: null,
  containerStyle: {}
};

export default Avatar;
