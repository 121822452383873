import { takeEvery, put, all, call, select } from "redux-saga/effects";
import * as DataServices from "services/data.services";

import {
  fetchLogs,
  fetchLogsSuccess,
  fetchLogsFailed,
  loadLogs,
  fetchMoreLogs,
  fetchMoreLogsSuccess,
  fetchMoreLogsFailed,
  loadMoreLogs
  // filterLogs,
  // filterLogsSuccess,
  // filterLogsFailed
} from "./actions";

/* @TODO remove on release */
// eslint-disable-next-line no-unused-vars
import TEST_DATA from "./testData";

const getFilters = state => state.logs.filters;

function* onFetchLogsAsync() {
  try {
    const filters = yield select(getFilters);
    const [error, response] = yield call(DataServices.fetchLogs, {
      ...filters
    });

    if (error || !response.ok) {
      yield put(fetchLogsFailed());
    } else {
      const data = yield response.json();
      yield all([put(fetchLogsSuccess()), put(loadLogs(data))]);
    }
  } catch {
    yield put(fetchLogsFailed());
  }
}

function* watchFetchLogsAsync() {
  yield takeEvery(fetchLogs().type, onFetchLogsAsync);
}

function* onFetchMoreLogsAsync({ payload } = {}) {
  try {
    const filters = yield select(getFilters);
    const { offset = 0, limit = 20 } = payload;
    const [error, response] = yield call(DataServices.fetchMoreLogs, {
      offset,
      limit,
      ...filters
    });

    if (error || !response.ok) {
      yield put(fetchMoreLogsFailed());
    } else {
      const data = yield response.json();
      yield all([put(fetchMoreLogsSuccess()), put(loadMoreLogs(data))]);
    }
  } catch (e) {
    yield put(fetchMoreLogsFailed());
  }
}

function* watchFetchMoreLogsAsync() {
  yield takeEvery(fetchMoreLogs().type, onFetchMoreLogsAsync);
}

export default function* rootSaga() {
  yield all([watchFetchLogsAsync(), watchFetchMoreLogsAsync()]);
}

export {
  watchFetchLogsAsync,
  onFetchLogsAsync,
  watchFetchMoreLogsAsync,
  onFetchMoreLogsAsync
};
