import React from "react";
import { RedocStandalone } from "redoc";
import { fetchSwagger } from "services/data.services";
import { Loader } from "components";
import COLORS from "constants/colors";
import { DocumentationContainer } from "./styledElements";

class Documentation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      spec: null,
      isLoading: true
    };
  }

  componentDidMount() {
    const fetchData = async () => {
      const [err, res] = await fetchSwagger();

      if (err || !res.ok) {
        this.setState({
          spec: null,
          isLoading: false
        });
        return;
      }
      const body = await res.json();
      const {
        paths,
        securityDefinitions: { ApiKeyAuth }
      } = body;

      // Filter out internal routes from the response's body
      const filteredPaths = Object.keys(paths).filter(
        path =>
          !path.startsWith("/users") &&
          !path.startsWith("/initialize") &&
          !path.startsWith("/swagger.json") &&
          !path.startsWith("/change-pass") &&
          !path.startsWith("/request-change") &&
          !path.startsWith("/forgot-password") &&
          !path.startsWith("/tokens") &&
          !path.startsWith("/webhooks")
      );
      const filteredSpec = {
        ...body,
        paths: filteredPaths.reduce(
          (acc, key) => ({
            ...acc,
            [key]: paths[key]
          }),
          {}
        ),
        securityDefinitions: {
          ApiKeyAuth
        }
      };

      this.setState({
        spec: filteredSpec,
        isLoading: false
      });
    };

    fetchData();
  }

  render() {
    const { isLoading, spec } = this.state;
    const theme = {
      typography: {
        fontFamily: "Rubik, sans-serif",
        code: {
          fontFamily: "Courier, monospace",
          fontSize: "13px",
          color: "rgb(59, 59, 59)",
          backgroundColor: "rgba(59, 59, 59, .3)"
        }
      },
      colors: {
        primary: {
          main: COLORS.brandOrange
        }
      },
      rightPanel: {
        backgroundColor: "rgb(59, 59, 59)"
      }
    };

    return (
      <DocumentationContainer>
        {isLoading ? (
          <Loader loading={isLoading} />
        ) : (
          <RedocStandalone spec={spec} options={{ theme }} />
        )}
      </DocumentationContainer>
    );
  }
}

export default Documentation;
