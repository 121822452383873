import styled from "styled-components";

export const Input = styled.input`
  height: 54px;
  width: 100%;
  padding-top: 32px;
  padding-bottom: 12px;
  box-sizing: border-box;
  outline: none;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  background-color: transparent;
`;

Input.defaultProps = {
  color: "rgba(0, 0, 0, 0.4)"
};

export const SelectField = styled.select`
  width: 100%;
`;

export const Label = styled.label`
  margin-top: 5px;
  z-index: 5;
  position: absolute;
  font-size: 0.75rem;
  font-weight: bold;
  text-transform: uppercase;
  color: ${props => props.color};
  transition: all 0.2s linear;
`;

Label.defaultProps = {
  color: "rgba(0, 0, 0, 0.4)"
};

export const Info = styled.div`
  font-size: 0.7rem;
  line-height: 0.7rem;
  margin-top: 8px;
  margin-bottom: 8px;
  color: ${props => {
    if (props.type === "error") return "red";
    if (props.type === "warning") return "orange";
    return "rgba(0, 0, 0, 0.4)";
  }};
`;
