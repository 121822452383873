import React from "react";

import { ForgotPasswordForm } from "./components";
import { LoginContainer, FormContainer, Navbar } from "../Login/styledElements";

import { Logo } from "../LandingPage/components/HeaderSection/styledElements";

const ForgotPassword = () => (
  <LoginContainer>
    <Navbar>
      <Logo to="/">kalayo</Logo>
    </Navbar>
    <FormContainer>
      <ForgotPasswordForm />
    </FormContainer>
  </LoginContainer>
);

export default ForgotPassword;
