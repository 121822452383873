import React from "react";

import LOG_LEVELS, { LOG_LEVELS_COLORS } from "constants/logLevels";
import { FRAMEWORKS } from "constants/onboarding";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Row, Col } from "react-grid-system";
import { Headline, Subline } from "../../styledElements";
import {
  SectionContainer,
  SectionTitle,
  LogArtContainer,
  LogLevelsContainer,
  InfoLog,
  WarningLog,
  ErrorLog,
  IntegrationContainer,
  DocsLink,
  IconImg
} from "./styledElements";

const LoggingSection = () => (
  <>
    <SectionContainer id="logging">
      <Container>
        <Row>
          <Col xl={6} lg={6}>
            <Headline>Catch it and Log it!</Headline>
            <Subline>
              It&apos;s always better to catch bugs, warnings, and any useful
              information that may cause your app to hang or crash as quickly as
              possible. Our loggers are easy to integrate into your apps without
              interfering with user experience.
            </Subline>
            <SectionTitle>Kalayo Catches It</SectionTitle>
            <Subline>
              Kalayo provides convenient functions imported by developers to add
              to any handler and pass in relevant data. Classify your recorded
              data, take in related environment and device traces, and include
              optional information to be sent to your dashboard from our simple
              logger functions.
            </Subline>
            <DocsLink to="/">
              <FontAwesomeIcon icon="chevron-right" />
              <span>TAKE ME TO THE DOCS</span>
            </DocsLink>
          </Col>
          <LogArtContainer>
            <LogLevelsContainer>
              <FontAwesomeIcon
                icon="info-circle"
                color={LOG_LEVELS_COLORS[LOG_LEVELS.DEFAULT]}
              />
              <FontAwesomeIcon
                icon="exclamation-triangle"
                color={LOG_LEVELS_COLORS[LOG_LEVELS.WARNING]}
              />
              <FontAwesomeIcon
                icon="bug"
                color={LOG_LEVELS_COLORS[LOG_LEVELS.ERROR]}
              />
            </LogLevelsContainer>
            <FontAwesomeIcon
              icon="mobile-alt"
              size="3x"
              className="mobile-icon"
            />
            <FontAwesomeIcon
              icon="desktop"
              size="8x"
              className="desktop-icon"
            />
            <FontAwesomeIcon
              icon="tablet-alt"
              size="4x"
              className="tablet-icon"
            />
            <InfoLog>
              <FontAwesomeIcon
                icon="info-circle"
                color={LOG_LEVELS_COLORS[LOG_LEVELS.DEFAULT]}
              />
              <span>Yes! These are the droids we were looking for.</span>
            </InfoLog>
            <WarningLog>
              <FontAwesomeIcon
                icon="exclamation-triangle"
                color={LOG_LEVELS_COLORS[LOG_LEVELS.WARNING]}
              />
              <span>Warning! An invalid proptype has been passed.</span>
            </WarningLog>
            <ErrorLog>
              <FontAwesomeIcon
                icon="bug"
                color={LOG_LEVELS_COLORS[LOG_LEVELS.ERROR]}
              />
              <span>Shoot! App has crashed upon viewing transaction.</span>
            </ErrorLog>
          </LogArtContainer>
        </Row>
      </Container>
    </SectionContainer>
    <IntegrationContainer>
      <Container>
        <h4>INTEGRATE WITH DIFFERENT LANGUAGES AND FRAMEWORKS</h4>
        <Row justify="around">
          {FRAMEWORKS.map(framework =>
            typeof framework.icon === "object" ? (
              <FontAwesomeIcon
                key={framework.value}
                icon={framework.icon}
                color={framework.background}
              />
            ) : (
              <IconImg src={framework.icon} />
            )
          )}
        </Row>
      </Container>
    </IntegrationContainer>
  </>
);

export default LoggingSection;
