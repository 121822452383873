import styled from "styled-components";

import CardTitle from "../../../CardTitle";
import { SettingContainer } from "../Settings/styledElements";

export const Title = styled(CardTitle)`
  margin-top: 0 !important;
  margin-bottom: 24px;
  text-align: center;
`;

// eslint-disable-next-line import/prefer-default-export
export const InviteFormContainer = styled(SettingContainer)`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  padding: 0 !important;

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const CloseButton = styled.div`
  position: absolute;
  z-index: 5;
  top: -36px;
  right: -36px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;
