/* eslint-disable react/jsx-one-expression-per-line */
import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { Row, Col } from "react-grid-system";

// import * as DataServices from "services/data.services";
import {
  getSubscription,
  cancelSubscription
} from "redux/modules/account/actions";

import { Loader } from "components";
import {
  BillingCard,
  NoticeContainer,
  SubscriptionContainer,
  NextBilling,
  // SelectPlanContainer,
  CardDetailsContainer
} from "./styledElements";
import {
  SettingContainer,
  SettingTitle,
  SettingDescription,
  Button
} from "../Settings/styledElements";
// import BillingInfoForm from "../BillingInfoForm";
import SubscriptionForm from "../SubscriptionForm";

import CardTitle from "../../../CardTitle";

class Billing extends React.Component {
  changeSubscriptionRef = React.createRef();

  state = {
    showSubscribeForm: false
  };

  componentDidMount() {
    const { onGetSubscription } = this.props;

    onGetSubscription();
  }

  // onChangeSubscription = async () => {
  //   this.setState({
  //     isChangingSubscription: true
  //   });

  //   const { error } = await DataServices.deleteSubscription();
  //   if (error) {
  //     return;
  //   }

  //   const { error: updateError } = await DataServices.changeSubscription();

  //   if (updateError) {
  //     return;
  //   }

  //   this.setState({
  //     isChangingSubscription: false
  //   });
  // };

  toggleChoosePlanForm = () => {
    this.setState(state => ({
      showSubscribeForm: !state.showSubscribeForm
    }));
  };

  handleCancelSubscription = () => {
    const { onCancelSubscription } = this.props;

    onCancelSubscription();
  };

  render() {
    const { showSubscribeForm } = this.state;

    const {
      activeSub,
      isSubscribing,
      isCancellingBilling,
      isFetchingSub
    } = this.props;

    return (
      <BillingCard id="billing">
        <CardTitle>Subscription</CardTitle>
        {!activeSub && (
          <NoticeContainer>
            <span className="notice">NOTICE: </span>
            <span>
              Your trial period has ended. Choose a plan below and subscribe
              now.
            </span>
          </NoticeContainer>
        )}
        <SubscriptionContainer>
          <Loader
            loading={isFetchingSub}
            containerStyle={{
              borderRadius: 8
            }}
          />
          <Row>
            <Col>
              <div className="price">
                <span>
                  {activeSub && activeSub.pricing
                    ? activeSub.pricing.charAt(0).toUpperCase() +
                      activeSub.pricing.slice(1)
                    : "Free"}
                </span>
              </div>
              <a
                className="comparison"
                target="_blank"
                rel="noopener noreferrer"
                href="/#pricing"
              >
                See subscription features comparison
              </a>
            </Col>
            <Col
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Button
                className="primary"
                type="button"
                onClick={this.toggleChoosePlanForm}
              >
                Choose a plan
              </Button>
            </Col>
          </Row>
        </SubscriptionContainer>
        {activeSub && (
          <NextBilling>
            <div className="next">
              <span>Expires on</span>
              <span className="date">
                {new Date(
                  Number(activeSub.curr_period_end) * 1000
                ).toDateString()}
              </span>
            </div>
          </NextBilling>
        )}
        <CardDetailsContainer>
          <SettingContainer>
            <Loader
              loading={isSubscribing}
              containerStyle={{
                borderRadius: 8
              }}
            />
            {showSubscribeForm && (
              <SubscriptionForm toggleShowForm={this.toggleChoosePlanForm} />
            )}
          </SettingContainer>
        </CardDetailsContainer>
        {/* <SettingContainer id="billingdetails">
          <SettingTitle>Billing Details</SettingTitle>
          <SettingDescription>
            Provide your card details that you want to use for your subscription
            payment.
          </SettingDescription>
          <CardDetailsContainer>
            <BillingInfoForm readOnly />
          </CardDetailsContainer>
        </SettingContainer> */}
        {/* <SettingContainer>
          <SettingTitle>Change Subscription</SettingTitle>
          <SettingDescription>
            Upgrade your subscription to make sure you will have everything you
            need.
          </SettingDescription>
          <SelectPlanContainer>
            <Select
              selectRef={this.changeSubscriptionRef}
              label="Select Subscription"
              options={[
                { value: "monthly", label: "Monthly" },
                { value: "annual", label: "Annual" }
              ]}
            />
          </SelectPlanContainer>
          <Button
            loading={isChangingSubscription}
            disabled={isChangingSubscription}
            type="button"
            onClick={this.onChangeSubscription}
          >
            Upgrade Subscription
          </Button>
        </SettingContainer> */}
        <SettingContainer id="cancel">
          <SettingTitle>Cancel Subscription</SettingTitle>
          <SettingDescription>
            Deleting your subscription will revoke your api tokens and your
            integration will stop posting logs to your dashboard.
          </SettingDescription>
          <Button
            disabled={isCancellingBilling || !activeSub}
            loading={isCancellingBilling}
            className="danger"
            type="button"
            onClick={this.handleCancelSubscription}
          >
            Cancel Subscription
          </Button>
        </SettingContainer>
      </BillingCard>
    );
  }
}

Billing.propTypes = {
  // billing: PropTypes.instanceOf(Object),
  // card: PropTypes.instanceOf(Object),
  activeSub: PropTypes.instanceOf(Object),
  isSubscribing: PropTypes.bool,
  isCancellingBilling: PropTypes.bool,
  onCancelSubscription: PropTypes.func.isRequired,
  onGetSubscription: PropTypes.func.isRequired,
  isFetchingSub: PropTypes.bool
};

Billing.defaultProps = {
  // billing: {},
  // card: {},
  activeSub: undefined,
  isSubscribing: false,
  isCancellingBilling: false,
  isFetchingSub: false
};

export default connect(
  state => ({
    billing: state.account.billing,
    isSubscribing: state.account.isSubscribing,
    isCancellingBilling: state.account.isCancellingBilling,
    isFetchingSub: state.account.isFetchingOrgSub,
    card: state.account.card,
    activeSub: state.account.activeSub
  }),
  {
    onGetSubscription: getSubscription,
    onCancelSubscription: cancelSubscription
  }
)(Billing);
