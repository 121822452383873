const jsLogo = require("assets/JSLogo.svg");
const goLogo = require("assets/GoLogo.svg");

export const APPLICATIONS = [
  {
    label: "Browser",
    value: "BROWSER"
  },
  {
    label: "Native Applications",
    value: "NATIVE_APPLICATIONS"
  }
];

export const APPLICATIONS_VALUES = APPLICATIONS.reduce((prev, next) => {
  /* eslint-disable-next-line */
  prev[next.value] = next.value;
  return prev;
}, {});

export const FRAMEWORKS = [
  {
    icon: ["fab", "react"],
    label: "React",
    value: "REACT",
    background: "#37C2D9"
  },
  {
    icon: ["fab", "react"],
    label: "React Native",
    value: "REACT_NATIVE",
    background: "#1f66f4"
  },
  {
    icon: jsLogo,
    label: "JavaScript",
    value: "JS",
    background: "#FEA301"
  },
  {
    icon: goLogo,
    label: "Golang",
    value: "GOLANG",
    background: "#00ACD7",
    disabled: true
  }
];

export const FRAMEWORKS_VALUES = FRAMEWORKS.reduce((prev, next) => {
  /* eslint-disable-next-line */
  prev[next.value] = next.value;
  return prev;
}, {});
