import styled from "styled-components";

import { BreakdownTitle, BreakdownDescription } from "../../styledElements";

export const InfoContainer = styled.div`
  margin-top: 24px;
  width: 100%;
`;

export const InfoTitle = BreakdownTitle;

export const InfoDescription = BreakdownDescription;

export const StackTraceContainer = styled.pre`
  overflow-x: auto;
  max-width: ${props => props.containerWidth}px;
`;
